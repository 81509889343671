<script setup>
import Header from "./Header.vue";
import {onMounted,ref,computed } from 'vue'
import moment from 'moment'
import Fetch from '../classes/Fetch'
import Cookie from '../classes/Cookie'
import HeaderMobile from "./Header_mobile.vue";
// import flatpickr from "flatpickr";
  import flatPickr from 'vue-flatpickr-component';
    // import 'flatpickr/dist/flatpickr.css';
    // theme is optional
    // try more themes at - https://flatpickr.js.org/themes/
    import 'flatpickr/dist/themes/material_blue.css';
    // localization is optional
    import {French} from 'flatpickr/dist/l10n/fr.js';

    const date = ref(['2022-10-28 ',' 2022-11-11']);

    // Read more at https://flatpickr.js.org/options/
    const config = ref({
        wrap: true, // set wrap to true only when using 'input-group'
        altFormat: 'M j, Y',
        mode:'range',
        // altInput: true,
        // static: true,
        dateFormat: 'Y-m-d',
        locale: French, // locale for this instance only          
    });
var token=Cookie.getCookie("token"),page="rapport",infoUser=ref({});
onMounted(() => {
    // localStorage.kisko="12345";
    if (!token) {
        setTimeout(() => {
            $("#SessionExpire").modal("show");
        }, 500);
        return
    }
    
    Fetch.post("/",{token:token}).then((data)=>{ 
        var userObj=data.data.user;
        var owner=data.data.owner;
        userObj.name.owner=owner;
        userObj.name.entreprise=userObj.en;
        infoUser.value=userObj.name;
    });
     
    // fin onMonted ========================================================================================================================
})
function dateChanged(val) {
    console.log("val========= ",val);
}
function calendarClosed(val) {
    val[1]=val[1]??val[0];
    console.log("val========= ",val[0]);
}
</script>
<template>
    <div class="history-container">
        <HeaderMobile :infoUser="infoUser" :page="page"></HeaderMobile>
        <section id="MainContent" class="traditional m_main_section" style="margin-bottom:0px !important;">
            <input id="ReportFromDate" name="ReportFromDate" type="hidden" value="08/20/2022 00:00">
            <input id="ReportToDate" name="ReportToDate" type="hidden" value="08/20/2022 23:59">
            <input id="ReportTypeID" name="ReportTypeID" type="hidden" value="19">
            <div class="row">
                <div class="col-xs-12">
                    <div class="panel panel-default panel-flat m-report-heading">
                        <div class="panel-heading">
                            <div class="row">
                                <div class="col-xs-9">
                                    <h4 class="f-16 fw-500 m0 location-ellipsis w-85per"><i class="icon-book-open f-14 mr-sm"></i> Trip/Route Report</h4>
                                </div>
                                <div class="col-xs-3">
                                    <div class="pull-right">
                                        <a href="#/m/Reports/Index" class="btn btn-default btn-xs"><i class="zmdi zmdi-long-arrow-return zmdi-hc-flip-vertical zmdi-hc-lg"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="bt panel-body pb pt">
                            <div class="row">
                                <div class="col-xs-5">
                                    <div class="input-group bg-transparent group-material">
                                        <flat-pickr v-model="date" :config="config" @on-change="dateChanged" @on-close="calendarClosed" class="form-control" placeholder="Select date" name="date"/>
                                        <!-- <input type="text" id="searchReportDateRangePicker" class="bg-transparent form-control control-material flatpickr-input" readonly="readonly"> -->
                                        <span class="input-group-addon"><em class="icon-calendar"></em></span>
                                    </div>
                                </div>
                                <div class="col-xs-5">
                                    <div class="form-group mb-0">
                                        <div class="select2-material bg-transparent">
                                            <select class="form-control select2 select2-hidden-accessible" id="ReportVehicleID" data-placeholder="Select Vehicle" tabindex="-1" aria-hidden="true"><option value=""></option><option value="00000000-0000-0000-0000-000000000000">All</option><option value="08a64499-5d9b-4708-83b9-fab7786e707c">BAB 4048210</option><option value="60ae5c55-8962-4858-a4e5-a99943ec0256">862462037787354</option><option value="724d2ae6-8f5c-4c05-995f-5784dc76b700">BAB 35078/02</option><option value="7aeefed8-54f8-44cb-8241-dc3d5b10a1f0">BAB 90357/10</option><option value="afd639ea-72af-4817-94ec-96ccca095f09">863069057967938</option><option value="d83e56c1-d3c9-4792-a26c-b1fbc5ebd2c2">BAB 76099/05</option></select><span class="select2 select2-container select2-container--bootstrap" dir="ltr" style="width: 144px;"><span class="selection"><span class="select2-selection select2-selection--single" role="combobox" aria-autocomplete="list" aria-haspopup="true" aria-expanded="false" tabindex="0" aria-labelledby="select2-ReportVehicleID-container"><span class="select2-selection__rendered" id="select2-ReportVehicleID-container" title="BAB 4048210"><span class="select2-selection__clear">×</span>BAB 4048210</span><span class="select2-selection__arrow" role="presentation"><b role="presentation"></b></span></span></span><span class="dropdown-wrapper" aria-hidden="true"></span></span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xs-2">
                                    <button type="button" id="btnReportSearchFilter" class="btn btn-blue-date btn-default btn-sm"><i class="fa fa-search"></i></button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="panel panel-default panel-flat m-report-body bgLightgray">
                        <div class="panel-body">
                            <div id="ReportDetailDiv">
            
            
                    <div class="card-panel report-card-panel">
                        <div class="card-panel-body pb-sm pt">
                            <h4 class="f-15 fw-500 mb0 mt0 text-primary">BAB 4048210 <span class="f-12 pull-right text-muted">08/20/2022</span></h4>
                            <p class="f-12 fw-500 mb0 mt-sm text-muted"></p>
                        </div>
                        <div class="card-panel-body bt pt-sm pb-sm">
                            <div class="overflow-hidden position-relative mlocation-circle-after pb-lg">
                                <div class="pull-left w-5per">
                                    <span class="mloction-circle  light bg-danger-dark"></span>
                                </div>
                                <div class="w-95per  pull-left">
                                    <div class="w-80per pull-left">
                                        <p class="fw-500 f-13 location-ellipsis mb0 w-90per">
                                            <a href="javascript:; " onclick="LocationOnMap('21791 79968, 9A Street, Al Quoz Industrial 4 Dubai, United Arab Emirates', '25.1251697540283', '55.232551574707')">21791 79968, 9A Street, Al Quoz Industrial 4 Dubai, United Arab Emirates</a>
                                        </p>
                                        <p class="f-12 fw-500 mb0 text-muted">08/20/2022 06:21 AM</p>
                                    </div>
                                    <div class="w-20per pull-left"><p class="fw-500 mb0 f-12">1 hrs, 27 min</p></div>
                                </div>
                            </div>
                            <div class="overflow-hidden">
                                <div class="pull-left w-5per">
                                    <span class="mloction-circle light bg-green-dark"></span>
                                </div>
                                <div class="w-95per pull-left">
                                    <div class="w-80per pull-left">
                                        <p class="fw-500 f-13 location-ellipsis mb-sm w-90per">
                                            <a href="javascript:; " onclick="LocationOnMap('18846 80154, 23 Street, Umm Al Sheif Dubai, United Arab Emirates', '25.1264934539795', '55.2032012939453')">18846 80154, 23 Street, Umm Al Sheif Dubai, United Arab Emirates</a>
                                        </p>
                                        <p class="f-12 fw-500 mb0 text-muted">08/20/2022 07:49 AM</p>
                                    </div>
                                    <div class="w-20per pull-left"><p class="fw-500 mb0 f-12">45.1090000000006 Kms</p></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-panel report-card-panel">
                        <div class="card-panel-body pb-sm pt">
                            <h4 class="f-15 fw-500 mb0 mt0 text-primary">BAB 4048210 <span class="f-12 pull-right text-muted">08/20/2022</span></h4>
                            <p class="f-12 fw-500 mb0 mt-sm text-muted"></p>
                        </div>
                        <div class="card-panel-body bt pt-sm pb-sm">
                            <div class="overflow-hidden position-relative mlocation-circle-after pb-lg">
                                <div class="pull-left w-5per">
                                    <span class="mloction-circle  light bg-danger-dark"></span>
                                </div>
                                <div class="w-95per  pull-left">
                                    <div class="w-80per pull-left">
                                        <p class="fw-500 f-13 location-ellipsis mb0 w-90per">
                                            <a href="javascript:; " onclick="LocationOnMap('18846 80154, 23 Street, Umm Al Sheif Dubai, United Arab Emirates', '25.1265316009521', '55.2031021118164')">18846 80154, 23 Street, Umm Al Sheif Dubai, United Arab Emirates</a>
                                        </p>
                                        <p class="f-12 fw-500 mb0 text-muted">08/20/2022 11:35 AM</p>
                                    </div>
                                    <div class="w-20per pull-left"><p class="fw-500 mb0 f-12">6 min</p></div>
                                </div>
                            </div>
                            <div class="overflow-hidden">
                                <div class="pull-left w-5per">
                                    <span class="mloction-circle light bg-green-dark"></span>
                                </div>
                                <div class="w-95per pull-left">
                                    <div class="w-80per pull-left">
                                        <p class="fw-500 f-13 location-ellipsis mb-sm w-90per">
                                            <a href="javascript:; " onclick="LocationOnMap('18846 80154, 23 Street, Umm Al Sheif Dubai, United Arab Emirates', '25.1265144348145', '55.2031631469727')">18846 80154, 23 Street, Umm Al Sheif Dubai, United Arab Emirates</a>
                                        </p>
                                        <p class="f-12 fw-500 mb0 text-muted">08/20/2022 11:42 AM</p>
                                    </div>
                                    <div class="w-20per pull-left"><p class="fw-500 mb0 f-12">0.0230000000000246 Kms</p></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-panel report-card-panel">
                        <div class="card-panel-body pb-sm pt">
                            <h4 class="f-15 fw-500 mb0 mt0 text-primary">BAB 4048210 <span class="f-12 pull-right text-muted">08/20/2022</span></h4>
                            <p class="f-12 fw-500 mb0 mt-sm text-muted"></p>
                        </div>
                        <div class="card-panel-body bt pt-sm pb-sm">
                            <div class="overflow-hidden position-relative mlocation-circle-after pb-lg">
                                <div class="pull-left w-5per">
                                    <span class="mloction-circle  light bg-danger-dark"></span>
                                </div>
                                <div class="w-95per  pull-left">
                                    <div class="w-80per pull-left">
                                        <p class="fw-500 f-13 location-ellipsis mb0 w-90per">
                                            <a href="javascript:; " onclick="LocationOnMap('18846 80154, 23 Street, Umm Al Sheif Dubai, United Arab Emirates', '25.1265182495117', '55.2030982971191')">18846 80154, 23 Street, Umm Al Sheif Dubai, United Arab Emirates</a>
                                        </p>
                                        <p class="f-12 fw-500 mb0 text-muted">08/20/2022 11:44 AM</p>
                                    </div>
                                    <div class="w-20per pull-left"><p class="fw-500 mb0 f-12">42 min</p></div>
                                </div>
                            </div>
                            <div class="overflow-hidden">
                                <div class="pull-left w-5per">
                                    <span class="mloction-circle light bg-green-dark"></span>
                                </div>
                                <div class="w-95per pull-left">
                                    <div class="w-80per pull-left">
                                        <p class="fw-500 f-13 location-ellipsis mb-sm w-90per">
                                            <a href="javascript:; " onclick="LocationOnMap('18846 80154, 23 Street, Umm Al Sheif Dubai, United Arab Emirates', '25.1264591217041', '55.2031364440918')">18846 80154, 23 Street, Umm Al Sheif Dubai, United Arab Emirates</a>
                                        </p>
                                        <p class="f-12 fw-500 mb0 text-muted">08/20/2022 12:26 PM</p>
                                    </div>
                                    <div class="w-20per pull-left"><p class="fw-500 mb0 f-12">28.8149999999999 Kms</p></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-panel report-card-panel">
                        <div class="card-panel-body pb-sm pt">
                            <h4 class="f-15 fw-500 mb0 mt0 text-primary">BAB 4048210 <span class="f-12 pull-right text-muted">08/20/2022</span></h4>
                            <p class="f-12 fw-500 mb0 mt-sm text-muted"></p>
                        </div>
                        <div class="card-panel-body bt pt-sm pb-sm">
                            <div class="overflow-hidden position-relative mlocation-circle-after pb-lg">
                                <div class="pull-left w-5per">
                                    <span class="mloction-circle  light bg-danger-dark"></span>
                                </div>
                                <div class="w-95per  pull-left">
                                    <div class="w-80per pull-left">
                                        <p class="fw-500 f-13 location-ellipsis mb0 w-90per">
                                            <a href="javascript:; " onclick="LocationOnMap('18846 80154, 23 Street, Umm Al Sheif Dubai, United Arab Emirates', '25.1264686584473', '55.2031097412109')">18846 80154, 23 Street, Umm Al Sheif Dubai, United Arab Emirates</a>
                                        </p>
                                        <p class="f-12 fw-500 mb0 text-muted">08/20/2022 12:42 PM</p>
                                    </div>
                                    <div class="w-20per pull-left"><p class="fw-500 mb0 f-12">13 min</p></div>
                                </div>
                            </div>
                            <div class="overflow-hidden">
                                <div class="pull-left w-5per">
                                    <span class="mloction-circle light bg-green-dark"></span>
                                </div>
                                <div class="w-95per pull-left">
                                    <div class="w-80per pull-left">
                                        <p class="fw-500 f-13 location-ellipsis mb-sm w-90per">
                                            <a href="javascript:; " onclick="LocationOnMap('18846 80154, 23 Street, Umm Al Sheif Dubai, United Arab Emirates', '25.1265449523926', '55.203125')">18846 80154, 23 Street, Umm Al Sheif Dubai, United Arab Emirates</a>
                                        </p>
                                        <p class="f-12 fw-500 mb0 text-muted">08/20/2022 12:55 PM</p>
                                    </div>
                                    <div class="w-20per pull-left"><p class="fw-500 mb0 f-12">2.06799999999976 Kms</p></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-panel report-card-panel">
                        <div class="card-panel-body pb-sm pt">
                            <h4 class="f-15 fw-500 mb0 mt0 text-primary">BAB 4048210 <span class="f-12 pull-right text-muted">08/20/2022</span></h4>
                            <p class="f-12 fw-500 mb0 mt-sm text-muted"></p>
                        </div>
                        <div class="card-panel-body bt pt-sm pb-sm">
                            <div class="overflow-hidden position-relative mlocation-circle-after pb-lg">
                                <div class="pull-left w-5per">
                                    <span class="mloction-circle  light bg-danger-dark"></span>
                                </div>
                                <div class="w-95per  pull-left">
                                    <div class="w-80per pull-left">
                                        <p class="fw-500 f-13 location-ellipsis mb0 w-90per">
                                            <a href="javascript:; " onclick="LocationOnMap('18846 80154, 23 Street, Umm Al Sheif Dubai, United Arab Emirates', '25.1264572143555', '55.2030792236328')">18846 80154, 23 Street, Umm Al Sheif Dubai, United Arab Emirates</a>
                                        </p>
                                        <p class="f-12 fw-500 mb0 text-muted">08/20/2022 02:40 PM</p>
                                    </div>
                                    <div class="w-20per pull-left"><p class="fw-500 mb0 f-12">24 min</p></div>
                                </div>
                            </div>
                            <div class="overflow-hidden">
                                <div class="pull-left w-5per">
                                    <span class="mloction-circle light bg-green-dark"></span>
                                </div>
                                <div class="w-95per pull-left">
                                    <div class="w-80per pull-left">
                                        <p class="fw-500 f-13 location-ellipsis mb-sm w-90per">
                                            <a href="javascript:; " onclick="LocationOnMap('25386 85773, Al A'amal Street, Business Bay Dubai, United Arab Emirates', '25.1780662536621', '55.2675857543945')">25386 85773, Al A'amal Street, Business Bay Dubai, United Arab Emirates</a>
                                        </p>
                                        <p class="f-12 fw-500 mb0 text-muted">08/20/2022 03:04 PM</p>
                                    </div>
                                    <div class="w-20per pull-left"><p class="fw-500 mb0 f-12">16.8740000000003 Kms</p></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-panel report-card-panel">
                        <div class="card-panel-body pb-sm pt">
                            <h4 class="f-15 fw-500 mb0 mt0 text-primary">BAB 4048210 <span class="f-12 pull-right text-muted">08/20/2022</span></h4>
                            <p class="f-12 fw-500 mb0 mt-sm text-muted"></p>
                        </div>
                        <div class="card-panel-body bt pt-sm pb-sm">
                            <div class="overflow-hidden position-relative mlocation-circle-after pb-lg">
                                <div class="pull-left w-5per">
                                    <span class="mloction-circle  light bg-danger-dark"></span>
                                </div>
                                <div class="w-95per  pull-left">
                                    <div class="w-80per pull-left">
                                        <p class="fw-500 f-13 location-ellipsis mb0 w-90per">
                                            <a href="javascript:; " onclick="LocationOnMap('Al A'amal Street, Business Bay Dubai, United Arab Emirates', '25.1787910461426', '55.2651519775391')">Al A'amal Street, Business Bay Dubai, United Arab Emirates</a>
                                        </p>
                                        <p class="f-12 fw-500 mb0 text-muted">08/20/2022 03:57 PM</p>
                                    </div>
                                    <div class="w-20per pull-left"><p class="fw-500 mb0 f-12">1 hrs, 22 min</p></div>
                                </div>
                            </div>
                            <div class="overflow-hidden">
                                <div class="pull-left w-5per">
                                    <span class="mloction-circle light bg-green-dark"></span>
                                </div>
                                <div class="w-95per pull-left">
                                    <div class="w-80per pull-left">
                                        <p class="fw-500 f-13 location-ellipsis mb-sm w-90per">
                                            <a href="javascript:; " onclick="LocationOnMap('21813 79951, 9A Street, Al Quoz Industrial 4 Dubai, United Arab Emirates', '25.1250915527344', '55.232551574707')">21813 79951, 9A Street, Al Quoz Industrial 4 Dubai, United Arab Emirates</a>
                                        </p>
                                        <p class="f-12 fw-500 mb0 text-muted">08/20/2022 05:19 PM</p>
                                    </div>
                                    <div class="w-20per pull-left"><p class="fw-500 mb0 f-12">48.6360000000004 Kms</p></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-panel report-card-panel">
                        <div class="card-panel-body pb-sm pt">
                            <h4 class="f-15 fw-500 mb0 mt0 text-primary">BAB 4048210 <span class="f-12 pull-right text-muted">08/20/2022</span></h4>
                            <p class="f-12 fw-500 mb0 mt-sm text-muted"></p>
                        </div>
                        <div class="card-panel-body bt pt-sm pb-sm">
                            <div class="overflow-hidden position-relative mlocation-circle-after pb-lg">
                                <div class="pull-left w-5per">
                                    <span class="mloction-circle  light bg-danger-dark"></span>
                                </div>
                                <div class="w-95per  pull-left">
                                    <div class="w-80per pull-left">
                                        <p class="fw-500 f-13 location-ellipsis mb0 w-90per">
                                            <a href="javascript:; " onclick="LocationOnMap('21791 79968, 9A Street, Al Quoz Industrial 4 Dubai, United Arab Emirates', '25.1250476837158', '55.2324333190918')">21791 79968, 9A Street, Al Quoz Industrial 4 Dubai, United Arab Emirates</a>
                                        </p>
                                        <p class="f-12 fw-500 mb0 text-muted">08/20/2022 09:05 PM</p>
                                    </div>
                                    <div class="w-20per pull-left"><p class="fw-500 mb0 f-12">59 min</p></div>
                                </div>
                            </div>
                            <div class="overflow-hidden">
                                <div class="pull-left w-5per">
                                    <span class="mloction-circle light bg-green-dark"></span>
                                </div>
                                <div class="w-95per pull-left">
                                    <div class="w-80per pull-left">
                                        <p class="fw-500 f-13 location-ellipsis mb-sm w-90per">
                                            <a href="javascript:; " onclick="LocationOnMap('Fareed Carpentry, Second Industrial Street, Sharjah, 24522, United Arab Emirates', '25.3201961517334', '55.4145736694336')">Fareed Carpentry, Second Industrial Street, Sharjah, 24522, United Arab Emirates</a>
                                        </p>
                                        <p class="f-12 fw-500 mb0 text-muted">08/20/2022 10:04 PM</p>
                                    </div>
                                    <div class="w-20per pull-left"><p class="fw-500 mb0 f-12">44.2209999999997 Kms</p></div>
                                </div>
                            </div>
                        </div>
                    </div>
            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            </section>
    </div>
</template>