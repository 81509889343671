<script setup>
    import moment from 'moment'
    import store from '../store'
    import {onMounted,ref,computed } from 'vue'
    import Fetch from '../classes/Fetch'
    import Cookie from '../classes/Cookie'
    import DateTime from '../classes/DateTime';
    import momentLang from '../classes/momentLang'
    import Header from "./Header.vue";
    import App from '../classes/App'
    import { useRoute,useRouter } from 'vue-router';


        var token=Cookie.getCookie("token"),devices=ref([]),page="compte",route = useRoute(),router= useRouter(),isMobile=App.isMobile(),infoUser=ref({}),devicesLength=ref(0),devicesByImei=ref({});
       // devicesByImei.value["860294046421853"]={name:"kisko"};

        onMounted(() => {
            // localStorage.kisko="12345";
            if (!token) {
                router.push({
                    name: 'Login',
                    // query: { kisko:2  },
                })
                return
            }
            Fetch.post("/",{token:token}).then((data)=>{ 
                var datas=data.data;
                var userObj=data.data.user;
                userObj.name.entreprise=userObj.en;
                var owner=data.data.owner;
                userObj.name.owner=owner;
                infoUser.value=userObj.name;
                devices.value=datas.devices;
                devices.value.forEach(device => {
                    devicesByImei.value[device.imei]=device;
                });
                devicesLength.value=datas.devices.length;
                $("#MainContent").removeClass("whirl");
                //setDatatable("devices_table");
                setTimeout(() => {
                    setDatatable("devices_table");
                }, 200);
             });

        });

function setDatatable(id) {
    $("#"+id).DataTable({
            "sDom": 'r<"row"<"col-sm-12"<"pull-left"l><"dt-filter-input-custom col-sm-6 col-md-offset-6"f><"clearfix">>>t<"row view-pager"<"col-sm-12"<"text-center"ip>>>',
            "scrollX": true,
            colReorder: true,
            "searching": true,
            "autoWidth": false,
            "bDestroy": true,
            // "ordering": false,
            "order": [[0,'asc']],
            "bPaginate": true,
            "bInfo": false,
            "bStateSave": true,
            // "iDisplayLength": 25,
            "bLengthChange": false,
            "language": {
                "processing": '<div class="sk-three-bounce" style="padding-top: 0px !important;"><div class="sk-child sk-bounce1"></div><div class="sk-child sk-bounce2"></div><div class="sk-child sk-bounce3"></div></div>', //add a loading image,simply putting <img src="loader.gif" /> tag.
                "search": '<i class="icon-magnifier mt position-relative pull-right text-muted1"></i>',
                //"searchPlaceholder": "Search Fleet",
                "url": "https://cdn.datatables.net/plug-ins/1.10.21/i18n/French.json",
                "paginate": {
                    "next": " ",
                    "previous": " "
                }
            },
          
            "drawCallback": function (settings) {
                
                
            }
        }); 
}
    $(document).ready(function() {
         
    });

    function showEditModal(imei) {
        $("#CommonImportSummaryModal").modal("show");
    }
function EditDevice(imei) {
    var device={...devicesByImei.value[imei]};
    delete device.lastDateAccOff;
    delete device.lastDateAccOn;
    delete device.lastDateMouve;
    delete device.lastDateStatic;
    delete device.position;
   console.log(device); 
   Fetch.post("/saveDevice",{token:token,data:device}).then((data)=>{ 
    if (data.success) {
        SuccessToastr("Mise à jour réussi!");
    }else{
        WarningToastr("Something went wrong!!!");
    }
        console.log(data);
    });
}
</script>  
<template>
<div class="compte_container">
<Header :infoUser="infoUser" :page="page"></Header>
 


<section id="MainContent" class="main-section-pt track-minisidebar traditional whirl">


<input id="AddEditStatus" name="AddEditStatus" type="hidden" value="Add">
<input id="TerritorialPostUrl" name="TerritorialPostUrl" type="hidden" value="/Setup/TerritorialFence/Insert">


<div class="normalheader small-header">
    <div class="hpanel">
        <div class="panel-body">
            <h2 class="with-button">
                <em class="icon-user header-icon"></em><span class="master_title">Gestion du compte</span>
            </h2>
            <div class="pull-right">
                <div class="btn-group">
                    <button type="button" data-toggle="dropdown" class="btn dropdown-toggle btn-primary btn-square" aria-expanded="false">
                        <em class="zmdi zmdi-more-vert zmdi-hc-lg"></em>
                    </button>
                    <ul role="menu" class="dropdown-menu dropdown-menu-more pull-right">
                        <li>
                            <a href="javascript:;" id="btnImportExportTerritorial"><em class="zmdi zmdi-format-valign-top"></em>Import xls</a>
                        </li>
                    </ul>
                </div>
                &nbsp;
                <div class="pull-right">
                    <a href="/" class="btn btn-default btn-md btn-square"><em class="zmdi zmdi-long-arrow-return zmdi-hc-flip-vertical zmdi-hc-lg em-icon"></em>Sortir</a>
                </div>
            </div>
        </div>
    </div>
    <div class="hpanel animated fadeIn" style="display:none" id="TerritorialFenceDownloadAndImportDiv">
        <div class="panel-body b-t-n">
            <div class="row">
                <div class="col-md-8">
                    <a class="btn btn-primary btn-square" onclick="download_DemoFile()" href="javascript:;" id="btnDemoFile" title="Click to Download Sample File">
                        <i class="zmdi zmdi-format-valign-bottom em-icon"></i>Sample File
                    </a>
                </div>
                <div class="col-md-4">
                    <div class="input-group">
                        <span class="input-group-btn">
                            <span class="btn btn-default btn-file">
                                Browse…
                                <input type="file" name="TerritorialExcelSheetUpload" id="TerritorialFlUploadcsv" accept=".xlsx" class="form-control">
                            </span>
                        </span>
                        <input type="text" class="form-control" readonly="readonly">

                        <span class="input-group-btn">
                            <button class="btn btn-primary btn-flat" id="btnUploadTerritorial" data-modaltitle="Territorial Import Summary" data-modalwidth="w-60per" title="ImportExcelSheet" onclick="btnImportTerritorial_Click(this)"><i class="zmdi zmdi-format-valign-top"></i></button>
                        </span>
                        <a class="btn btn-default btn-square pull-right" href="javascript:;" id="Close_TerritorialDownloadAndImportDiv" title="Close">
                            <i class="zmdi zmdi-close"></i>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="hpanel">
        <div class="panel-body b-n">
            <div class="POIZone-panel-body">
                <div class="container-fluid">
                    <table id="devices_table" class="display table">
                        <thead>
                            <tr>
                                <th>Imei</th>
                                <th>Prénom</th>
                                <th>Nom</th>
                                <th>Conso</th>
                                <th width="70px;">Bloqué</th>
                                <th>immat</th>
                                <th>type véhicule</th>
                                <th width="60px;">Relay</th>
                                <th width="100px;">Max vitesse</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(device,index) in devicesByImei" :key="index">
                                <td>{{index}}</td>
                                <td><input type="text" v-model="devicesByImei[index].driver.prenom" class="form-control form-control-sm"></td>
                                <td><input type="text" v-model="devicesByImei[index].driver.nom" class="form-control form-control-sm"></td>
                                <td>{{device.consommation}}/100</td>
                                <td> {{device.position?device.position.Blocked==1?"OUI":"NON":"Inconnu"}}</td>
                                <td><input type="text" v-model="devicesByImei[index].immat" class="form-control form-control-sm"></td>
                                <td><input type="text" v-model="devicesByImei[index].modelVehicule" class="form-control form-control-sm"></td>
                                <td>{{device.relay==1?"OUI":"NON"}}</td>
                                <td><input type="text" v-model="devicesByImei[index].overSpeed" class="form-control form-control-sm"></td>
                                <td>
                                    <button @click="EditDevice(index)" class="btn btn-success btn-sm" style="margin-right: 5px;">
                                        <i class="fa fa-pencil" style="color: rgb(255, 255, 255);"></i> Modifier
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>
</section>
<div id="CommonImportSummaryModal" class="modal fade modal-inverse" data-backdrop="static" data-keyboard="true" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog w-98per">
        <div class="modal-content modal-content-custom">
            <div class="modal-header">
                <button type="button" onclick="CloseCommonImportSummaryModal()" class="close">×</button>
                <h4 class="modal-title"></h4>
            </div>
            <div class="modal-body">
                <div id="CommonImportSummaryDiv"></div>
            </div>
            <div class="modal-footer">
                <div class="row">
                    <div class="col-md-6">
                        <button id="btnDownload" onclick="DownloadFile()" type="button" class="btn btn-secondary btn-success waves-effect btn-flat btn-md pull-left CommonImportSummaryModal"><i class="icon-cloud-download">&nbsp;</i>Download Sample File</button>
                        <span class="text-danger" id="MessageSpan"></span>
                    </div>
                    <div class="col-md-4">
                        <div id="ImportSubmitProgressbar" class="progress w-50per pull-right d-none">
                            <div id="importPrgressbar" class="progress-bar progress-bar-striped progress-bar-animated bg-success" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%"></div>
                        </div>
                    </div>
                    <div class="col-md-2">
                        <button id="btnSubmitImportSummary" type="button" data-loading-text="<i class='fa fa-spinner fa-spin'></i> Importing.." class="btn btn-primary">Submit</button>
                        <button onclick="CloseCommonImportSummaryModal()" type="button" class="btn btn-default btn-lg btn-square">Close</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </div>
</div>
</template>    

<style>
    #NearByVehicleList {
        min-height: calc(100vh - 418px);
        height: calc(100vh - 418px);
        overflow-y: hidden;
        margin-right: 10px;
    }

    .daterangepicker.dropup {
        top: calc(100vh - 435px) !important;
    }

    .scrollable2 {
        height: calc(100vh - 385px) !important;
        overflow: auto;
    }

    .footer_mapviewsidebar {
        bottom: 0px !important;
    }

    .nav-tab-inner-widget li {
        width: calc(100% / 5);
    }

    .nav-tab-5 li {
        width: calc(100% / 5);
    }

    .nav-tab-inner-widget li a {
        padding: 5px 6px !important;
        color: #333 !important;
        background-color: #fff !important;
        border: 0 !important;
        border-bottom: 1px solid #ddd;
        text-align: center;
        border-top-right-radius: 3px;
        border-top-left-radius: 3px;
    }

    .nav-tab-inner-widget {
        margin-top: 5px !important;
    }

        .nav-tab-inner-widget > li.active > a,
        .nav-tab-inner-widget > li.active > a:focus,
        .nav-tab-inner-widget > li.active > a:hover {
            background-color: rgba(26, 115, 232, 0.11) !important;
            color: #4193ff !important;
            border-bottom: 2px solid #4193ff !important;
        }

        .nav-tab-inner-widget > li > a:hover,
        .nav-tab-inner-widget > li > a:focus {
            background-color: rgba(26, 115, 232, 0.11) !important;
            color: #4193ff !important;
        }

        #_OffSideBarMapViewListDiv {
        min-height: calc(100vh - 248px);
        height: calc(100vh - 248px);
        overflow-y: hidden;
    }
    .nav-tab-4 li {
        width: calc(100% / 4);
    }

    .nav-tab-inner-widget li a {
        padding: 5px 6px !important;
        color: #333 !important;
        background-color: #fff !important;
        border: 0 !important;
        border-bottom: 1px solid #ddd;
        text-align: center;
        border-top-right-radius: 3px;
        border-top-left-radius: 3px;
    }

    .nav-tab-inner-widget {
        margin-top: 5px !important;
    }

        .nav-tab-inner-widget > li.active > a,
        .nav-tab-inner-widget > li.active > a:focus,
        .nav-tab-inner-widget > li.active > a:hover {
            background-color: rgba(26, 115, 232, 0.11) !important;
            color: #4193ff !important;
            border-bottom: 2px solid #4193ff !important;
        }

        .nav-tab-inner-widget > li > a:hover,
        .nav-tab-inner-widget > li > a:focus {
            background-color: rgba(26, 115, 232, 0.11) !important;
            color: #4193ff !important;
        }
        .no-table-header thead {
        display: none;
    }

    .table-td-padding td {
        padding: 10px 2px !important;
        border: 0px !important;
    }

    .mw-150 {
        max-width: 150px;
    }

    #tblReOrderServerSide_wrapper .dataTables_scrollBody::-webkit-scrollbar-track {
        background-color: #EEEEEE;
    }

    #tblReOrderServerSide_wrapper .dataTables_scrollBody::-webkit-scrollbar {
        width: 6px;
        height: 6px;
        background-color: #EEEEEE;
    }

    #tblReOrderServerSide_wrapper .dataTables_scrollBody::-webkit-scrollbar-thumb {
        background-color: #BDBDBD;
    }

    #tblReOrderServerSide_wrapper .dataTables_scrollBody::-webkit-scrollbar-track {
        background-color: #EEEEEE;
    }

    #tblReOrderServerSide_wrapper .dataTables_scrollBody::-webkit-scrollbar {
        width: 6px;
        height: 6px;
        background-color: #EEEEEE;
    }

    #tblReOrderServerSide_wrapper .dataTables_scrollBody::-webkit-scrollbar-thumb {
        background-color: #BDBDBD;
    }

    tr.active td {
        background-color: rgba(33, 150, 243, 0.18) !important;
    }
    .poiZoneMapViewSize {
        position: relative;
        width: 100%;
        height: calc(100vh - 148px);
    }

    .control-gray-ddl option {
        color: #333;
    }

    .display-block {
        display: block;
    }

    .display-none {
        display: none;
    }

    .collapsedBtnPOI {
        position: absolute;
        top: 10px;
        right: 10px;
    }

    .btn_switch_grp .btn_switch {
        border-radius: 50px;
        border: 1px solid #2196F3;
    }

        .btn_switch_grp .btn_switch.active,
        .btn_switch_grp .btn_switch:hover {
            background-color: #2196F3;
            color: #fff;
        }

    .map-panel-control {
        right: 10px;
        bottom: 63px;
    }

    .mapOptionsDashboard .btn-default {
        width: 41px;
        height: 40px;
    }

    .isFullScreen #rightDivHeight {
        width: 100% !important;
        float: left;
    }

    .isFullScreen #leftDivList {
        width: 0px !important;
        float: left;
        display: none !important;
    }

    .POIZone-panel-body #rightDivHeight {
        width: calc(100% - 370px);
        float: left;
    }


    .POIZone-panel-body #leftDivList {
        width: 370px;
        float: left;
    }
</style>
