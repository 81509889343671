<script setup>
        import 'ol/ol.css';
        import Feature from 'ol/Feature';
        import Map from 'ol/Map';
        import Point from 'ol/geom/Point';
        import Polyline from 'ol/format/Polyline';
        import VectorSource from 'ol/source/Vector';
        import View from 'ol/View';
        import {
        Circle as CircleStyle,
        Fill,
        Icon,
        Stroke,
        Style,
        } from 'ol/style';
        import {Tile as TileLayer, Vector as VectorLayer} from 'ol/layer';
        import {getVectorContext} from 'ol/render';
        import TileImage from 'ol/source/TileImage';
        import { defaults as defaultControls, OverviewMap } from "ol/control";
        import FullScreen from 'ol/control/FullScreen';
        import {createEmpty} from 'ol/extent';
        import Overlay from 'ol/Overlay';
        import {extend} from 'ol/extent';
        import {getTransform,fromLonLat,transform} from 'ol/proj';
        import Text from 'ol/style/Text';
        import * as olTransform from 'ol/transform';
        import LineString from 'ol/geom/LineString';
        import Draw from 'ol/interaction/Draw';
        import Snap from 'ol/interaction/Snap';
        import {Circle,Polygon } from 'ol/geom';
        import Modify from 'ol/interaction/Modify';
        import Collection from 'ol/Collection';
        import Translate from 'ol/interaction/Translate';
        import moment from 'moment'
        import store from '../store'
        import {onMounted,ref,computed } from 'vue'
        import Fetch from '../classes/Fetch'
        import Cookie from '../classes/Cookie'
        import DateTime from '../classes/DateTime';
        import momentLang from '../classes/momentLang'
        import Header from "./Header.vue";
        import rapportActivityTable from "./rapportActivityTable.vue";
        import rapportKiloTable from "./rapportKiloTable.vue";
        import rapportFlotteTable from "./rapportFlotteTable.vue";
        import rapportFuelChart from "./rapportFuelChart.vue";
        import signalGSM from "./signalGSM.vue";
        import print from 'print-js'
        var token=Cookie.getCookie("token"),contentPopup,markerPopup,devices=ref({}),page="rapport",
            datasImeis=[],activeHistory=ref([]),totalDistance=ref(0),vitesseMax=ref(0),showRapportActivity=ref(0),
            devicesById=ref([]),dataTable=null,listPoi=ref([]),listGeo=ref([]),historyData=ref([]),historyDataCopy=[],totalTrajets=ref(0),refStartTime=ref(null),
            map=null,activityToday=ref(0),activity24=ref(0),activity48=ref(0),infoUser=ref({}),alarmesData=ref([]),consommation=ref(0),
            totalStatus=[],totalMouve=ref(0),totalStatic=ref(0),totalOffline=ref(0),totalOffline48=ref(0),socket=null,listCommands=null,
            compteurTrajet=0,coords=null,draw=null, snap=null,createdZone=null,radius=null,dtype=null,zoneCoordonnates=[],
            AllCheckedGeoLines=0,selectedIdGeo=null,numeroPoi=0,numeroGeo=0,modifyGeo=null,geoToEditId=null,user=ref({}),popup=null,
            filename=ref("filename.xls"),imeis=[],
            // computed =======================================================================================================
        //     devices=computed(()=> {
        //         var elements=JSON.parse(JSON.stringify({...devicesById.value}));
        //   return elements != null?elements:[];}),
          
          devicesLength=computed(()=> {
            activityToday.value=0;activity24.value=0,activity48.value=0;
             var statuts={...activeHistory.value};
             statuts=Object.values(statuts);
                statuts.forEach((value,index)=>{
                    if (value==1) { activityToday.value++;   }else if (value==2) { activity24.value++;   }if (value==3) { activity48.value++;   }
                });
               return statuts.length;
            });
           
            function setActivityDuration(duration,status) {
                if (status==1) {
                    totalMouve.value+=duration;
                }else if (status==2) {
                    totalStatic.value+=duration;
                }else{
                    totalOffline.value+=duration;
                }
            }
            momentLang.setLangFr(moment);
            // momentLang.setLangEn(moment);
        var layers = [];
        const props=defineProps({
          kisko:{}
        });
        var map_image_sat = new TileLayer({
          visible: true,
          source: new TileImage({ url: 'https://mt1.google.com/vt/lyrs=y@113&hl=fr&&x={x}&y={y}&z={z}' })
        });
        var map_image_plan = new TileLayer({
          visible: false,
          source: new TileImage({ url: 'https://mt1.google.com/vt/lyrs=m@113&hl=fr&&x={x}&y={y}&z={z}' }),
        });
        const center = [-17.364861,14.771440];
        layers.push(map_image_sat);
        layers.push(map_image_plan);
        var olview = new View({
          center: fromLonLat(center),
          zoom: 6,
          minZoom: 4,
          maxZoom: 20
        });
        
        var vectorSource_poi = new VectorSource({});
        var vectorSource_geo = new VectorSource({});
        var poiLayer = new VectorLayer({
          source: vectorSource_poi
        });
        var vectorLayerGeo = new VectorLayer({
          source: vectorSource_geo
        });
        var extent = createEmpty();
        function changeMap(style,el) {
          if ($(el).hasClass("Select")) { return; }
          $(".MapTypeButton").find(".Active").removeClass("Select");
          if (style == "sat") {
              $(".MapTypeButton").find(".sat").addClass("Select");
              map_image_plan.setVisible(false);
              map_image_sat.setVisible(true);
          } else {
              $(".MapTypeButton").find(".plan").addClass("Select");
              map_image_sat.setVisible(false);
              map_image_plan.setVisible(true);
          }
        }
        
        function extentVector(Layer) {
          extend(extent, Layer.getSource().getExtent());
          map.getView().fit(extent, map.getSize());
          map.getView().setZoom(map.getView().getZoom() - 1);
        }
        //-=================================================
        var vectorSource = new VectorSource({});
        var vectorLayer = new VectorLayer({
          source: vectorSource
        });
        var sourceHistory = new VectorSource({});
        var vectorHistory = new VectorLayer({
          source: sourceHistory
        });
        var idPoi=0;
        var createPoiEnabled=false;
        var iconPoi="images/icons/airplane.png";
        var iconFeature=null,poiCoords;
        
        
        function sortDevicesList(){
          var selfEl = $(".devicesListContainer");
           var sortedList = $('.DeviceListRow', selfEl).sort(function(lhs, rhs){
               return parseInt($(lhs).attr("data-order"),10) - parseInt($(rhs).attr("data-order"),10);
           });
           selfEl.append(sortedList);
        }
        function getAdresse(lat,lng,index,length,callback) {
            fetch("https://server.sunutrack.com:8443/nominatim?lat="+lat+"&lon="+lng).then(function(response) {return response.json()}).then(function(data) {
              callback(data,index,length);
          });
        }
        function getAllAdreses(data) {
            Fetch.post("/getadresses",{data:data}).then((datas)=>{ 
               datas.forEach((data)=>{
                var index=data.index,key=data.key,adresse=data.adresse;
                historyDataCopy[index][key]=adresse;
               });
               historyData.value={...historyDataCopy}; 
             });
        }
        function searchAdress(q,callback) {
          fetch("http://sunutrack.store:8080/search?q="+q+"&format=json&polygon_geojson=1&addressdetails=1").then(function(response) {return response.json()}).then(function(data) {
              callback(data);
          })
        }
        function getFeature(id) {
          return vectorSource.getFeatureById(id);
        }
        var arrayCoords=[];
        function historyRoute(data,oldData,hTrajet) {
        var currentCoords=[data.lng, data.lat];
        var oldCoords =[oldData.lng,oldData.lat];
        var vitesse=data.speed;
          var colorTrace = colorTracer(vitesse);
              // faire le trace
          var coords = [oldCoords,currentCoords];
          arrayCoords.push(currentCoords);
        //   if (hTrajet==1){ arrayCoords=coords;}else{arrayCoords.push(currentCoords)}
          var lineString = new LineString(coords);
          // transform to EPSG:3857
          lineString.transform('EPSG:4326', 'EPSG:3857');
          var lineStyle = new Style({
              stroke: new Stroke({
                  color: colorTrace,
                  width: 4,
                  zIndex: 500
              })
          });
          // create the feature
          var tracer = new Feature({
              geometry: lineString,
              type: 'Htrajet',
              vitesse: vitesse,
              couleur: colorTrace
          });
          tracer.setId("line_"+hTrajet);
          tracer.setStyle(lineStyle);
          sourceHistory.addFeature(tracer);
        }
        function togleIcons(enabled) {
            vectorLayer.setVisible(enabled);
        }
        function Log(params) {
            console.log(params);
        }
        function manageRapportFuel(param) {
            totalMouve.value=0;totalStatic.value=0;totalOffline.value=0;historyData.value=[];
            var datas= param.data;
            var dataLength=datas.length;
            if (dataLength< 2) {$("#MainContent").removeClass("whirl");WarningToastr("Aucune donnée trouvée pour cette période!");return;}
            var lastData=datas[dataLength-1];
            var firstData=datas[0];
            var isStop=false,isStatic=false, isMouve=false,fromMoveToStop=false,
            indexMouv=-1,indexStop=-1,indexStatic=-1,tabHistory=[],tabFuels=[],intervalActivity=[];
            var distance= calculDistance(firstData.Odometer,lastData.Odometer);
            totalDistance.value=distance;
            var debut = firstData.date;
            var fin = lastData.date;

            var overSpeed=0,maxSpeed=0,hdata=[],pointBefore=null;
            var tempConduite=0, tempStop=0,stopLong=0,trajets=0,totalStop=0;
            var postedStartTime = moment(startTime);
            var postedEndTime = moment(endTime);
            var diffStart=moment(debut).diff(postedStartTime,"minutes",true);
            var diffEnd=postedEndTime.diff(moment(fin),"minutes",true);
            if (diffStart>0) {
                if (firstData.ACC==0) {
                    datas[0].date=startTime;
                }else{
                    var dataTemp=firstData;
                    dataTemp.ACC=0;
                    dataTemp.date=startTime;
                    datas.unshift(dataTemp);
                    dataLength++;
                }
            }
            var key=null;
            datas.forEach(function(data,i) {
                if (i==dataLength-1) { return; }
                key=moment(data.date).format("YYYY-MM-DD");
                var nextData=datas[i+1];
                if (!tabHistory[key]) {tabHistory[key]=data;tabHistory[key]["trajets"]=0;tabHistory[key]["mouve"]=0;tabHistory[key]["static"]=0;}
                var fuelP=data.FuelP?data.FuelP:0;
                fuelP=devices.value[selectedImeiHistory].hasSonde?toFixed(data.Fuel/10,0):fuelP;
                var unix=moment.utc(data.date).unix()*1000;
                tabFuels.push([unix,fuelP]);
                if (data.ACC==1) {
                    var diff=diffDates(data.date,nextData.date);
                    if (isStop || i==0) {
                        intervalActivity.push({"value":unix,"color":"#ff9600"});
                    }
                    if (data.speed>0) {
                        if (!isMouve) {
                            if (!(nextData.ACC==1 && nextData.speed>0)) {
                                // noValid=true;
                                tabHistory[key]["static"]+=diff;
                            }else{
                                isStop=false;isMouve=true;isStatic=false;
                                fromMoveToStop=true;
                                tabHistory[key]["mouve"]+=diff;
                            }
                            
                        }else{
                            tabHistory[key]["mouve"]+=diff;
                        }
                        
                    }else{
                        isStop=false;isMouve=false;isStatic=true;
                        tabHistory[key]["static"]+=diff;
                    }
                    if (!tabHistory[key]["start"]) {
                        tabHistory[key]["start"]=data.date;
                    }else{
                        var nextKey=moment(nextData.date).format("YYYY-MM-DD");
                        tabHistory[key]["odoEnd"]=nextData.Odometer;
                        tabHistory[key]["fin"]=nextData.date;
                        tabHistory[key]["finActivity"]=nextData.date;
                        tabHistory[key]["lng_fin"]=data.lng; 
                        tabHistory[key]["lat_fin"]=data.lat; 
                        if (key !=nextKey ) {
                            tabHistory[key]["odoEnd"]=nextData.Odometer;
                            tabHistory[key]["fin"]=key+" 23:59:59";
                            tabHistory[key]["finActivity"]=key+" 23:59:59";
                            key=nextKey;
                            tabHistory[key]=nextData;
                            tabHistory[key]["trajets"]=0;
                            tabHistory[key]["mouve"]=0;tabHistory[key]["static"]=0;
                            tabHistory[key]["date"]=key+" 00:00:00";
                            tabHistory[key]["lng_fin"]=data.lng; 
                            tabHistory[key]["lat_fin"]=data.lat; 
                        }
                        
                    }
                }else{
                    if (!isStop || i==0) {
                        intervalActivity.push({"value":unix,"color":"#2d8cf0"}); //2d8cf0 ff9600
                    }
                    isStop=true;isMouve=false;isStatic=false;
                    if (fromMoveToStop) {
                        tabHistory[key]["trajets"]++;
                        fromMoveToStop=false;
                    }
                    // key=moment(data.date).format("YYYY-MM-DD");
                    // if (!tabHistory[key]) {tabHistory[key]=data;tabHistory[key]["trajets"]=0; }
                    var nextData=datas[i+1];
                    var nextKey=moment(nextData.date).format("YYYY-MM-DD");
                    tabHistory[key]["fin"]=nextData.date;
                    if (key !=nextKey ) {
                        tabHistory[key]["fin"]=key+" 23:59:59";
                        key=nextKey;
                        tabHistory[key]=nextData;
                        tabHistory[key]["trajets"]=0;
                        tabHistory[key]["mouve"]=0;tabHistory[key]["static"]=0;
                        tabHistory[key]["date"]=key+" 00:00:00";
                    }
                }
            });
            $("#MainContent").removeClass("whirl");
            showRapportActivity.value=3;
            setTimeout(() => {
                stockChart({data:tabFuels,activite:intervalActivity},"fuelRapportChart");
            }, 200);
            if (diffEnd>0) {
                tabHistory[key].fin=endTime;
            }
            var keys=Object.keys(tabHistory);
            for (let i = 0; i < keys.length; i++) {
                const key = keys[i];
                var data=tabHistory[key];
                var distance=calculDistance(data.Odometer,data.odoEnd);
                tabHistory[key].distance=distance;
                // var diff=diffDates(data.date,data.fin);
                var tempsStop=86400-(data.static+data.mouve);
                setActivityDuration(data.mouve,1);
                setActivityDuration(data.static,2);
                setActivityDuration(tempsStop,3);
                // var duree= formatSecondesToTime(diff);
                // tabHistory[key].duree=duree;
                tabHistory[key]["active"]=formatSecondesToTime(data.mouve);
                tabHistory[key]["static"]=formatSecondesToTime(data.static);
                tabHistory[key]["stop"]=formatSecondesToTime(tempsStop);
                historyData.value[i]=tabHistory[key];
            }
            // historyData.value=tabHistory;
            
            totalTrajets.value=trajets;
            console.log(tabHistory);
        }
        function manageRapportKilo(param) {
            totalMouve.value=0;totalStatic.value=0;totalOffline.value=0;historyData.value=[],allDataAdresses=[];
            var datas= param.data;
            var dataLength=datas.length;
            if (dataLength< 2) {$("#MainContent").removeClass("whirl");WarningToastr("Aucune donnée trouvée pour cette période!");return;}
            var lastData=datas[dataLength-1];
            var firstData=datas[0];
            var isStop=false,isStatic=false, isMouve=false,fromMoveToStop=false,
            indexMouv=-1,indexStop=-1,indexStatic=-1,tabHistory=[];
            var distance= calculDistance(firstData.Odometer,lastData.Odometer);
            totalDistance.value=distance;
            var debut = firstData.date;
            var fin = lastData.date;

            var overSpeed=0,maxSpeed=0,hdata=[],pointBefore=null;
            var tempConduite=0, tempStop=0,stopLong=0,trajets=0,totalStop=0;
            var postedStartTime = moment(startTime);
            var postedEndTime = moment(endTime);
            var diffStart=moment(debut).diff(postedStartTime,"minutes",true);
            var diffEnd=postedEndTime.diff(moment(fin),"minutes",true);
            if (diffStart>0) {
                if (firstData.ACC==0) {
                    datas[0].date=startTime;
                }else{
                    var dataTemp=firstData;
                    dataTemp.ACC=0;
                    dataTemp.date=startTime;
                    datas.unshift(dataTemp);
                    dataLength++;
                }
            }
            var key=null;
            datas.forEach(function(data,i) {
                if (i==dataLength-1) { return; }
                key=moment(data.date).format("YYYY-MM-DD");
                var nextData=datas[i+1];
                if (!tabHistory[key]) {
                    tabHistory[key]=data;tabHistory[key]["trajets"]=0;tabHistory[key]["mouve"]=0;tabHistory[key]["static"]=0;tabHistory[key]["maxSpeed"]=data.speed;
                }
                if (data.ACC==1) {
                    var diff=diffDates(data.date,nextData.date);
                    if (data.speed>0) {
                        if (!isMouve) { 
                            if (!(nextData.ACC==1 && nextData.speed>0)) {
                                // noValid=true;
                                tabHistory[key]["static"]+=diff;
                            }else{
                                isStop=false;isMouve=true;isStatic=false;
                                fromMoveToStop=true;
                                tabHistory[key]["mouve"]+=diff;
                            }
                            
                        }else{
                            tabHistory[key]["mouve"]+=diff;
                        }
                        if (!tabHistory[key]["maxSpeed"]) {
                            tabHistory[key]["maxSpeed"]=data.speed;
                        }
                       if(data.speed>tabHistory[key]["maxSpeed"]) {tabHistory[key]["maxSpeed"]=data.speed;}
                    }else{
                        isStop=false;isMouve=false;isStatic=true;
                        tabHistory[key]["static"]+=diff;
                    }
                    if (!tabHistory[key]["start"]) {
                        tabHistory[key]["start"]=data.date;
                    }else{
                        var nextKey=moment(nextData.date).format("YYYY-MM-DD");
                        tabHistory[key]["odoEnd"]=nextData.Odometer;
                        tabHistory[key]["fin"]=nextData.date;
                        tabHistory[key]["finActivity"]=nextData.date;
                        tabHistory[key]["lng_fin"]=data.lng;
                        tabHistory[key]["lat_fin"]=data.lat;
                        tabHistory[key]["adresse_fin"]=data.adresse;
                        if (key !=nextKey ) {
                            tabHistory[key]["odoEnd"]=nextData.Odometer;
                            tabHistory[key]["fin"]=key+" 23:59:59";
                            tabHistory[key]["finActivity"]=key+" 23:59:59";
                            key=nextKey;
                            tabHistory[key]=nextData;
                            tabHistory[key]["trajets"]=0;
                            tabHistory[key]["mouve"]=0;
                            tabHistory[key]["static"]=0;
                            tabHistory[key]["date"]=key+" 00:00:00";
                            tabHistory[key]["lng_fin"]=data.lng; 
                            tabHistory[key]["lat_fin"]=data.lat; 
                            tabHistory[key]["adresse_fin"]=data.adresse;
                        }
                        
                    }
                }else{
                    isStop=true;isMouve=false;isStatic=false;
                    if (fromMoveToStop) {
                        tabHistory[key]["trajets"]++;
                        fromMoveToStop=false;
                    }
                    // key=moment(data.date).format("YYYY-MM-DD");
                    // if (!tabHistory[key]) {tabHistory[key]=data;tabHistory[key]["trajets"]=0; }
                    var nextData=datas[i+1];
                    var nextKey=moment(nextData.date).format("YYYY-MM-DD");
                    tabHistory[key]["fin"]=nextData.date;
                    if (key !=nextKey ) {
                        tabHistory[key]["fin"]=key+" 23:59:59";
                        key=nextKey;
                        tabHistory[key]=nextData;
                        tabHistory[key]["trajets"]=0;
                        tabHistory[key]["mouve"]=0;tabHistory[key]["static"]=0;
                        tabHistory[key]["date"]=key+" 00:00:00";
                    }
                }
            });
            if (diffEnd>0) {
                tabHistory[key].fin=endTime;
            }
            var keys=Object.keys(tabHistory);
            var length=keys.length-1;
            historyDataCopy=[];
            for (let i = 0; i < keys.length; i++) {
                const key = keys[i];
                var data=tabHistory[key];
                var distance=calculDistance(data.Odometer,data.odoEnd);
                tabHistory[key].distance=distance;
                // var diff=diffDates(data.date,data.fin);
                var tempsStop=86400-(data.static+data.mouve);
                setActivityDuration(data.mouve,1);
                setActivityDuration(data.static,2);
                setActivityDuration(tempsStop,3);
                // var duree= formatSecondesToTime(diff);
                // tabHistory[key].duree=duree;
                tabHistory[key]["active"]=formatSecondesToTime(data.mouve);
                tabHistory[key]["static"]=formatSecondesToTime(data.static);
                tabHistory[key]["stop"]=formatSecondesToTime(tempsStop);
                historyDataCopy[i]=tabHistory[key];
                if (!data.adresse) {
                    setTimeout(getAdresse(data.lat,data.lng,i,length,function(address,i,length) { setAdresse(address.display_name,i,length,"adresse"); }), 1000);
                }
                if (!data.adresse_fin) {
                    setTimeout(getAdresse(data.lat_fin,data.lng_fin,i,length,function(address,i,length) { setAdresse(address.display_name,i,length,"adresse_fin"); }), 1000);
                }
            }
            historyData.value={...historyDataCopy};
            // historyData.value=tabHistory;
            $("#MainContent").removeClass("whirl");
            totalTrajets.value=trajets;
            setTimeout(() => {
                setListDatatable();
            }, 200);
            showRapportActivity.value=2;
            console.log(tabHistory);
        }
        function manageRapportFlotte(param) {
            totalMouve.value=0;totalStatic.value=0;totalOffline.value=0;historyData.value=[],allDataAdresses=[];
            var allData=param.data;
            console.log(allData);
            var keys=Object.keys(allData);
            var indexKey=0,tabHistory=[],trajets=0;
            keys.forEach((key)=>{
                var datas= allData[key];
                var dataLength=datas.length;
                if (dataLength< 2) {$("#MainContent").removeClass("whirl");
                // WarningToastr("Aucune donnée trouvée pour cette période!");
                return;}
                var lastData=datas[dataLength-1];
                var firstData=datas[0];
                var isStop=false,isStatic=false, isMouve=false,fromMoveToStop=false,
                indexMouv=-1,indexStop=-1,indexStatic=-1;
                var distance= calculDistance(firstData.Odometer,lastData.Odometer);
                totalDistance.value=distance;
                var debut = firstData.date;
                var fin = lastData.date;

                var overSpeed=0,maxSpeed=0,hdata=[],pointBefore=null;
                var tempConduite=0, tempStop=0,stopLong=0,totalStop=0;
                var postedStartTime = moment(startTime);
                var postedEndTime = moment(endTime);
                var diffStart=moment(debut).diff(postedStartTime,"minutes",true);
                var diffEnd=postedEndTime.diff(moment(fin),"minutes",true);
                var hasActivity=false;
                if (diffStart>0) {
                    if (firstData.ACC==0) {
                        datas[0].date=startTime;
                    }else{
                        var dataTemp=firstData;
                        dataTemp.ACC=0;
                        dataTemp.date=startTime;
                        datas.unshift(dataTemp);
                        dataLength++;
                    }
                }
               
                datas.forEach(function(data,i) {
                    if (i==dataLength-1) { return; }
                    var date=moment(data.date).format("YYYY-MM-DD");
                    var nextData=datas[i+1];
                    if (!tabHistory[key]) {
                        tabHistory[key]=data;tabHistory[key]["trajets"]=0;tabHistory[key]["mouve"]=0;tabHistory[key]["static"]=0;tabHistory[key]["maxSpeed"]=data.speed;
                    }
                    if (data.ACC==1) {
                        hasActivity=true;
                        var diff=diffDates(data.date,nextData.date);
                        if (data.speed>0) {
                            if (!isMouve) { 
                                if (!(nextData.ACC==1 && nextData.speed>0)) {
                                    // noValid=true;
                                    tabHistory[key]["static"]+=diff;
                                }else{
                                    isStop=false;isMouve=true;isStatic=false;
                                    fromMoveToStop=true;
                                    tabHistory[key]["mouve"]+=diff;
                                }
                                
                            }else{
                                tabHistory[key]["mouve"]+=diff;
                            }
                            if (!tabHistory[key]["maxSpeed"]) {
                                tabHistory[key]["maxSpeed"]=data.speed;
                            }
                        if(data.speed>tabHistory[key]["maxSpeed"]) {tabHistory[key]["maxSpeed"]=data.speed;}
                        }else{
                            isStop=false;isMouve=false;isStatic=true;
                            tabHistory[key]["static"]+=diff;
                        }
                        if (!tabHistory[key]["start"]) {
                            tabHistory[key]["start"]=data.date;
                        }else{
                            // var nextKey=keys[indexKey+1];
                            tabHistory[key]["odoEnd"]=nextData.Odometer;
                            tabHistory[key]["fin"]=nextData.date;
                            tabHistory[key]["finActivity"]=nextData.date;
                            tabHistory[key]["lng_fin"]=data.lng;
                            tabHistory[key]["lat_fin"]=data.lat;
                            tabHistory[key]["adresse_fin"]=data.adresse;
                            // if (key !=nextKey ) {
                            //     tabHistory[key]["odoEnd"]=nextData.Odometer;
                            //     tabHistory[key]["fin"]=date+" 23:59:59";
                            //     tabHistory[key]["finActivity"]=date+" 23:59:59";
                            //     key=nextKey;
                            //     tabHistory[key]=nextData;
                            //     tabHistory[key]["trajets"]=0;
                            //     tabHistory[key]["mouve"]=0;
                            //     tabHistory[key]["static"]=0;
                            //     tabHistory[key]["date"]=date+" 00:00:00";
                            //     tabHistory[key]["lng_fin"]=data.lng; 
                            //     tabHistory[key]["lat_fin"]=data.lat; 
                            //     tabHistory[key]["adresse_fin"]=data.adresse;
                            // }
                        }
                    }else{
                        isStop=true;isMouve=false;isStatic=false;
                        if (fromMoveToStop) {
                            tabHistory[key]["trajets"]++;
                            fromMoveToStop=false;
                        }
                        // key=moment(data.date).format("YYYY-MM-DD");
                        // if (!tabHistory[key]) {tabHistory[key]=data;tabHistory[key]["trajets"]=0; }
                        var nextData=datas[i+1];
                        // var nextKey=keys[indexKey+1];;
                        tabHistory[key]["fin"]=nextData.date;
                        // if (key !=nextKey ) {
                        //     tabHistory[key]["fin"]=date+" 23:59:59";
                        //     key=nextKey;
                        //     tabHistory[key]=nextData;
                        //     tabHistory[key]["trajets"]=0;
                        //     tabHistory[key]["mouve"]=0;tabHistory[key]["static"]=0;
                        //     tabHistory[key]["date"]=date+" 00:00:00";
                        // }
                    }
                });
                tabHistory[key]["hasActivity"]=hasActivity;
                if (diffEnd>0) {
                    tabHistory[key].fin=endTime;
                }
                indexKey++;
            });

            var keys=Object.keys(tabHistory);
            var length=keys.length-1;
            historyDataCopy=[];
            console.log(tabHistory);
            for (let i = 0; i < keys.length; i++) {
                const key = keys[i];
                var immat=devices.value[key].immat;
                immat=immat.length>2?immat:devices.value[key].driver.prenom;
                console.log(key+" immat===================: "+immat);
                tabHistory[key]["immat"]=immat;
                var data=tabHistory[key];
                var distance=calculDistance(data.Odometer,data.odoEnd);
                tabHistory[key].distance=distance;
                // var diff=diffDates(data.date,data.fin);
                var tempsStop=diffDates(startTime,endTime)-(data.static+data.mouve);
                setActivityDuration(data.mouve,1);
                setActivityDuration(data.static,2);
                setActivityDuration(tempsStop,3);
                // var duree= formatSecondesToTime(diff);
                // tabHistory[key].duree=duree;
                tabHistory[key]["active"]=formatSecondesToTime(data.mouve);
                tabHistory[key]["static"]=formatSecondesToTime(data.static);
                tabHistory[key]["stop"]=formatSecondesToTime(tempsStop);
                historyDataCopy[i]=tabHistory[key];
                if (!data.adresse) {
                    setTimeout(getAdresse(data.lat,data.lng,i,length,function(address,i,length) { setAdresse(address.display_name,i,length,"adresse"); }), 1000);
                }
                
                if (!data.adresse_fin) {
                    setTimeout(getAdresse(data.lat_fin,data.lng_fin,i,length,function(address,i,length) { setAdresse(address.display_name,i,length,"adresse_fin"); }), 1000);
                }
            }
            historyData.value={...historyDataCopy};
            // historyData.value=tabHistory;
            $("#MainContent").removeClass("whirl");
            totalTrajets.value=trajets;
            setTimeout(() => {
                setListDatatable();
            }, 200);
            showRapportActivity.value=4;
            console.log(tabHistory);
        }
        function manageHistoryModal(param) {
            var datas= param.data;
            var dataLength=datas.length;
            vectorSource.clear();
            sourceHistory.clear();
            if (dataLength< 1) { $("#MainContent").removeClass("whirl");WarningToastr("Aucune donnée trouvée pour cette période!");return;}
            var pointBefore=datas[0];
           
        //   $("#dfRPosition").find(".v1").text(dateFormat(debut));$("#dfRPosition").find(".v2").text(dateFormat(fin));
        datas.forEach(function(data,i) {
            if (i==dataLength-1) {historyRoute(data,pointBefore,i);addMarker([data.lng,data.lat],"/images/history/trip_finish.png",data.speed,0,1); return; }
            if (i==0) {
                pointBefore=data;
                addMarker([data.lng,data.lat],"/images/history/trip_start.png",data.speed,0,1); return;
            } 
            historyRoute(data,pointBefore,i);
            addMarker([data.lng,data.lat],"/images/history/historyArrow.png",0,data.angle,0.6);
            pointBefore=data;
          });
          extentVector(vectorHistory);
            $("#MainContent").removeClass("whirl");
        }
        var allDataAdresses=[];
        function manageHistory(param) {
            totalMouve.value=0;totalStatic.value=0;totalOffline.value=0,allDataAdresses=[];
            var datas= param.data;
            var dataLength=datas.length;
            vectorSource.clear();
            sourceHistory.clear();
            if (dataLength< 2) {$("#MainContent").removeClass("whirl");WarningToastr("Aucune donnée trouvée pour cette période!");return;}
            var lastData=datas[dataLength-1];
            var firstData=datas[0];
            var debut = firstData.date;
            refStartTime.value=debut;
            var fin = lastData.date;
            var isStop=false,isStatic=false, isMouve=false,fromMoveToStop=false,
                index=-1,tabHistory=[],arrayCoords=[];
            //   var tabStop=[],tabStatic=[],tabMouve=[];
            var distance= calculDistance(firstData.Odometer,lastData.Odometer);
            totalDistance.value=distance;
            var overSpeed=0,maxSpeed=0,hdata=[],pointBefore=null;
            var tempConduite=0, tempStop=0,stopLong=0,trajets=0,totalStop=0;
            var postedStartTime = moment(startTime);
            var postedEndTime = moment(endTime);
            var diffStart=moment(debut).diff(postedStartTime,"minutes",true);
            var diffEnd=postedEndTime.diff(moment(fin),"minutes",true);
            if (diffStart>0) {
                if (firstData.ACC==0) {
                    datas[0].date=startTime;
                }else{
                    var dataTemp=firstData;
                    dataTemp.ACC=0;
                    dataTemp.date=startTime;
                    datas.unshift(dataTemp);
                    dataLength++;
                }
            }
        //   $("#dfRPosition").find(".v1").text(dateFormat(debut));$("#dfRPosition").find(".v2").text(dateFormat(fin));
          datas.forEach(function(data,i) {
            //   if (data.Alarm==22) { overSpeed++; }
              if (data.speed>maxSpeed) {maxSpeed=data.speed;}
              if (i==dataLength-1) {
                // historyRoute(data,pointBefore,i);addMarker([data.lng,data.lat],"/images/history/trip_finish.png",data.speed,0,1); 
                return;
             }
            //   if (i==0) {
            //     addMarker([data.lng,data.lat],"/images/history/trip_start.png",data.speed,0,1);
            //   }
              if (data.ACC==0) {
                    var currentDate = data.date;
                    if (!isStop) { 
                        index++;
                        if (fromMoveToStop) { trajets++; }
                        isStop=true;isMouve=false;isStatic=false;fromMoveToStop=false;
                        tabHistory[index]=data;
                        tabHistory[index]["status"]=3;
                        tabHistory[index]["date"]=currentDate;
                        data["status"]=3;
                        tabHistory[index]["trajets"]=trajets;
                        // if (i>0) {
                        //     historyRoute(data,pointBefore,i);
                        //     addMarker([data.lng,data.lat],"/images/history/historyArrow.png",0,data.angle,0.6);
                        // }
                        // hdata.push(data);
                        pointBefore=data;
                    }
                    var nextDate = datas[i+1].date;
                    tabHistory[index]["fin"]=nextDate;
              }else if(data.ACC==1 && data.speed==0){
                  if (!isStatic) {
                     index++;isStop=false;isMouve=false;isStatic=true;
                     tabHistory[index]=data;
                     tabHistory[index]["status"]=2;
                     tabHistory[index]["date"]=data.date;
                     data["status"]=2;
                    //  if (i>0) {
                    //     historyRoute(data,pointBefore,i);
                    //     addMarker([data.lng,data.lat],"/images/history/historyArrow.png",0,data.angle,0.6);
                    // }
                    //  hdata.push(data);
                     pointBefore=data;
                    }
                    tabHistory[index]["fin"]=datas[i+1].date; 
              }else{
                var nextData=datas[i+1];
                var noValid=false;
                if (!isMouve) { 
                    if (!(nextData.ACC==1 && nextData.speed>0)) {
                        noValid=true;
                    }else{
                        index++;
                        isStop=false;isMouve=true;isStatic=false;
                        tabHistory[index]=data;
                        tabHistory[index]["status"]=1;
                        tabHistory[index]["odoStart"]=data.Odometer;
                        tabHistory[index]["date"]=data.date;
                    }
                    
                }
                var nextDate=nextData.date;
                if (!noValid) {
                    data["status"]=1;
                    if (moment(data.date).format("YY-MM-DD") !=  moment(nextDate).format("YY-MM-DD")) {
                        var Cdata={...data};
                        tabHistory[index].fin=moment(data.date).format("YYYY-MM-DD")+' 23:59:59'; 
                        tabHistory[index]["odoEnd"]=nextData.Odometer;
                        tabHistory[index]["lng_fin"]=data.lng; 
                        tabHistory[index]["lat_fin"]=data.lat; 
                        tabHistory[index]["adresse_fin"]=data.adresse; 
                        Cdata.date=moment(Cdata.fin).format("YYYY-MM-DD")+' 00:00:00';
                        Cdata.changed=true;
                        index++;
                        tabHistory[index]=Cdata;
                        tabHistory[index]["odoStart"]=nextData.Odometer;
                        // tabHistory[index]["odoIntermediaire"]=data.Odometer;
                    }
                    // if (moment(tabHistory[index]["fin"]).format("YY-MM-DD") !=  moment(nextDate).format("YY-MM-DD")) {
                    //     tabHistory[index]["odoIntermediaire"]=data.Odometer;
                    // }
                    tabHistory[index]["fin"]=nextDate; 
                    tabHistory[index]["odoEnd"]=nextData.Odometer;
                    tabHistory[index]["lng_fin"]=data.lng; 
                    tabHistory[index]["lat_fin"]=data.lat; 
                    tabHistory[index]["adresse_fin"]=data.adresse; 
                    // if (i>0) {
                    //     historyRoute(data,pointBefore,i);
                    //     addMarker([data.lng,data.lat],"/images/history/historyArrow.png",data.speed,data.angle,0.6);
                    // }
                    if(data.speed>vitesseMax.value){vitesseMax.value=data.speed}
                    // hdata.push(data);
                    pointBefore=data;
                    fromMoveToStop=true;
                }else{
                    if (tabHistory[index]) {
                        tabHistory[index]["fin"]=nextDate;
                    }else{
                        nextData.date=data.date;
                    }
                }
                if (!tabHistory[index]["maxSpeed"]) {
                    tabHistory[index]["maxSpeed"]=data.speed;
                }else if (data.speed>tabHistory[index]["maxSpeed"]) {
                    tabHistory[index]["maxSpeed"]=data.speed;
                }
              }
          });
        //   extentVector(vectorHistory);
            if (diffEnd>0) {
                var dataTemp=tabHistory[index];
                if (dataTemp.status==3) {
                    tabHistory[index].fin=endTime;
                }else{
                    index++;
                    tabHistory[index]=lastData;
                    tabHistory[index].ACC=0;
                    tabHistory[index].status=3;
                    tabHistory[index].fin=endTime;
                    tabHistory[index].trajets=trajets;
                    tabHistory[index].date=dataTemp.fin;
                }        
            }
        // var tabHistory=filterActivity(tabHistory);    
        historyDataCopy=[];
        index=0;
        var length=tabHistory.length-1;
        tabHistory.forEach(function(data,i) {
            historyDataCopy[index]=data;
            data.distance=0;
            if (data.status==1) {
                var distance=calculDistance(data.odoStart,data.odoEnd);
                data.distance=distance;
            }
            if (!data.adresse) {
                allDataAdresses.push({lat:data.lat,lng:data.lng,index:index,key:"adresse"});
            }
            if (data.status==1) {
                if (!data.adresse_fin) {
                    allDataAdresses.push({lat:data.lat_fin,lng:data.lng_fin,index:index,key:"adresse_fin"});
                }
               
            }
            // =======================================================================
            var diff=diffDates(data.date,data.fin);
            setActivityDuration(diff,data.status);
            data.diff=diff;
            var duree= formatSecondesToTime(diff);
            data.duree=duree;
            // ==================================================================
            index++;
            // tabHistory[i].duree=duree;
            // tabHistory[i].distance=calculDistance(data.odoStart,data.odoEnd);
        });
        getAllAdreses(allDataAdresses);
        // console.log(historyDataCopy);
          historyData.value={...historyDataCopy};
          $("#MainContent").removeClass("whirl");
          totalTrajets.value=trajets;
        //   setTimeout(() => {
        //     setListDatatable();
        //   }, 200);
          showRapportActivity.value=1;
        }
        function filterActivity(datas){
            console.log(datas.length,datas);
            var tempData=[];
            for (let i = 0; i < datas.length; i++) {
                const data = datas[i];
                if (data.status==1) {
                    var diff=diffDates(data.date,data.fin);
                    if (diff<45) {
                        if (tempData.length>0) {
                            var indexTemp=tempData.length-1;
                            var nextData=datas[i+1];
                            if (tempData[indexTemp].status==nextData.status) {
                                tempData[indexTemp].fin=nextData.fin;  
                                delete datas[i+1];
                            }else{
                                if (tempData[indexTemp].status==1) {
                                    tempData[indexTemp].fin=data.fin; 
                                }else{
                                    datas[i+1].date=data.date;
                                }
                            } 
                        }else{
                            datas[i+1].date=data.date;  
                        }
                    }   
                }else{
                    tempData.push(data);  
                }
            }
            return tempData;
        }
        function diffDates(start,end) {
            var debut = moment(start);
            var fin = moment(end);
            return fin.diff(debut,"seconds",true);
        }
        function diffFormat(start,end) {
            var seconds=diffDates(start,end);
           return formatSecondesToTime(seconds);
        }
        function formatSecondesToTime2(seconds) {
            const days = Math.floor(seconds / 86400);
            const hours = Math.floor(seconds / 3600) - (days  * 24);
            const minutes = Math.floor(seconds / 60) - ((days  * 24)*60);
           return moment.duration(seconds*1000).asHours();
        //    return moment.utc(seconds * 1000).format("HH:mm:ss");
        }
        function formatSecondesToTime(totalSeconds) {
            var heures=parseInt(totalSeconds/3600);
            var reste=totalSeconds-(heures*3600);
            var minutes=parseInt(reste/60);
            reste=reste-(minutes*60);
            var secondes=reste;
            heures=heures>9?heures:"0"+heures;
            minutes=minutes>9?minutes:"0"+minutes;
            secondes=secondes>9?secondes:"0"+secondes;
            return heures+":"+minutes+":"+secondes;
            // const seconds = Math.floor(totalSeconds % 60);
            // const minutes = Math.floor((totalSeconds % 3600) / 60);
            // const hours = Math.floor((totalSeconds % (3600 * 24)) / 3600);
            // const days = Math.floor(totalSeconds / (3600 * 24));

            // const secondsStr = makeHumanReadable(seconds, '');
            // const minutesStr = makeHumanReadable(minutes, '');
            // const hoursStr = makeHumanReadable(hours, '');
            // const daysStr =days>1?days+'Jrs':days+'Jr';
            // var string ='';
            // if (days>0) { string+=daysStr+" ";  }
            // return string+=hoursStr+":"+minutesStr+":"+secondsStr;
            // //return `${daysStr}${hoursStr}${minutesStr}${secondsStr}`.replace(/,\s*$/, '');
        }

        function makeHumanReadable(number, singular) {
            if (number <10) { number="0"+number;}
            return number;
        }
        var pointStop=null,stopOverlaysIds=[],hdata,trajetHistory=0,plusLongStopData=[],plusLongStopSecondes=0;
        function manageHistory_old(param) {
          var data = param.data;
          trajetHistory=0;
          hdata=[];
          if (data.length<1) { WarningToastr("Aucune donnée pour cette période");return; }
          for (let i = 0; i < data.length; i++) {
              const point = data[i];
              var currentCoords=[point.lng, point.lat];
              var coords=fromLonLat(currentCoords);
              var pointBefore=data[i-1];
              if (i==0) {
                  startEndHistoryOverlay(coords,"historyPointOverlayStart",i);
                  hdata.push(point);
               }else if (i==data.length-1) {
                  startEndHistoryOverlay(coords,"historyPointOverlayEnd","End");
                  var oldCoords =[pointBefore.lng,pointBefore.lat];
                  historyRoute(oldCoords,currentCoords, point,i);
               }else{
                   if (point.ACC==0) {
                      var nextPoint=data[i+1];
                       if (!pointStop) {
                          pointStop=point;
                       }
                       pointStop.endStop=point.date;
                       if (nextPoint.ACC==1) {
                          trajetHistory++;
                          var debut = moment(pointStop.date);
                          var fin = moment(point.date);
                          var days =fin.diff(debut,"days"),hours =fin.diff(debut,"hours"),minutes =fin.diff(debut,"minutes"),seconds =fin.diff(debut,"seconds");
                          
                          var duree=null;
                          if (days>0) {
                              duree=days+"J "+parseInt(hours/24)+"H";
                          }else if (hours>0) {
                              duree=hours+"H "+parseInt(minutes/60)+"M";
                          }else if (minutes>0) {
                              duree=minutes+"M "+parseInt(seconds/60)+"S";
                          }else {
                              duree="0M "+seconds+"S";
                          }
                          stopOverlaysIds.push(i);
                          var time=[duree,debut];
                          if (seconds>plusLongStopSecondes) {
                              plusLongStopSecondes=seconds;
                              plusLongStopData=[i,time];
                          }
                          pointHistoryOverlay(fromLonLat([pointStop.lng,pointStop.lat]),time,i);
                          pointStop=null;
                       }
                   }else{
                      var oldCoords =[pointBefore.lng,pointBefore.lat];
                      historyRoute(oldCoords,currentCoords, point,i);
                   }
               }
          }
          extentVector(vectorHistory);
          if (trajetHistory>1) {
              var overlay=map.getOverlayById("overlay"+plusLongStopData[0]);
              var element= $(".sejourLePlusLong");
              element.find(".Title").text("Stop le plus long "+plusLongStopData[1][0]);
              element.find(".Remark").text(plusLongStopData[1][1].format("YYYY-MM-DD HH:mm:ss"));
              placeHistoryOverlay(overlay.getPosition(),element[0],"s")
              map.removeOverlay(overlay);
              // var html = overlay.element.innerHTML.replace("Immobile","Stop le plus long").replace("#2d8cf0","#a949de").replace("fa-parking","fa-home");
              // overlay.element.innerHTML=html;
          }
          $(".rateAnimation").text("0/"+arrayCoords.length);
          animateMarker();
        }
        function hideOverlay(id) {
          var overlay=map.getOverlayById(id);
          if (!overlay) {return;}
          // if (action==1) {
          //     overlay.setVisible(true);return;
          // }
          // overlay.setVisible(false);
         $(overlay.element).toggle();
        }
        function hideAllOverlays(action,type) {
          if (action==1) {
              map.getOverlays().getArray().forEach(function(overlay) {
                  if (overlay.get("type")==type) {
                      $(overlay.element).show();
                  }
              }); 
          }else{
              map.getOverlays().getArray().forEach(function(overlay) {
                  console.log(overlay.getId(),overlay.get("type"));
                  $(overlay.element).hide();
              });
          }
          // var len=overlays.length;
          // for (let i = 0; i < len; i++) {
          //     const overlay = overlays[0];
          //     map.removeOverlay(overlay);
          // }
        }
        function showHideOverlayHistory() {
          for (let i = 0; i < stopOverlaysIds.length; i++) {
              const element = stopOverlaysIds[i];
              hideOverlay("overlay"+element);
          }
        }
        function pointHistoryOverlay(coords,duree,index) {
          var content = '<div class="amap-marker historyPointOverlay'+index+'"><div class="amap-marker-content" style="opacity: 1;"><div class="DownArrowMarker"><div class="Offset"><div class="Container HDiv" style="background-color:#2d8cf0"><i class="ivu-icon ivu-icon- fas fa-parking"></i></div><div class="Arrow" style="border-top: 15px solid #2d8cf0;"></div><div class="Text VDivL" style="background-color:#2d8cf0"><div class="Title">Immobile '+duree[0]+'</div><div class="Remark" style="color:#ffeb3b;">'+duree[1].format("YYYY-MM-DD HH:mm:ss")+'</div></div></div></div></div></div>';
          $("body").append(content);
          var element =$( ".historyPointOverlay"+index);var el = element[0];
          placeHistoryOverlay(coords,el,index);
        }
        function placeHistoryOverlay(coords,element,index) {
          var id="overlay"+index;
          var overlay = new Overlay({
              id:id,
              element: element,
              positioning: 'top-center',
              stopEvent: false,
              offset: [0.5, 0.5],
              anchorXUnits: 'fraction',
              anchorYUnits: 'fraction',
              scale: 0.5,
              autoPan: true,
              autoPanAnimation: {
                  duration: 0
              }
          });
          overlay.setPosition(coords);
          overlay.set("type","h_overlay");
          map.addOverlay(overlay);
          console.log(overlay.getId());
        }
        function startEndHistoryOverlay(coords,hclass,index) {
          var element =$("."+hclass);
          var el = element[0];
          placeHistoryOverlay(coords,el,index);
        }
        function toggleLines(action) {
          for (let i = 1; i <= hdata.length+2; i++) {
              showHideLine("line_"+i,action);
          }
        }
        function getFeatureHistory(id) {
          return sourceHistory.getFeatureById(id);
        }
        function showHideLine(id,action) {
          var feature = getFeatureHistory(id);
          if (!feature) {return;}
          if (action==1) {
              var vitesse=feature.get("vitesse");
              var colorTrace = colorTracer(vitesse);
              var lineStyle = new Style({
                  stroke: new Stroke({
                      color: colorTrace,
                      width: 4,
                      zIndex: 500
                  })
              });
              feature.setStyle(lineStyle); return;
          }
          feature.setStyle(new Style({}));
        }
        
        function updateBarAnimation(index,routeLength) {
          var percent = ((index)/(routeLength))*100;
          $(".ivu-slider-bar").css("width",percent+"%");
          $(".ivu-slider-button-wrap").css("left",percent+"%");
          $(".rateAnimation").text(index+"/"+routeLength);
        }
        // début de la partie animation du marker ==============================================================================================
        //========================= button animation route =====================================
        function animMarker(){
          route = new LineString(arrayCoords);
          route.transform('EPSG:4326', 'EPSG:3857');
            const startMarker = new Feature({
              type: 'icon',
              geometry: new Point(route.getFirstCoordinate()),
            });
            const endMarker = new Feature({
              type: 'icon',
              geometry: new Point(route.getLastCoordinate()),
            });
            const position = startMarker.getGeometry().clone();
            const geoMarker = new Feature({
              type: 'geoMarker',
              geometry: position,
            });

            

          
           sourceHistory.addFeature(geoMarker);
            geoMarker.setStyle(getStyle(0,hdata[0].angle));
            const speedInput = document.getElementById('speed');
            const startButton = document.getElementById('animateMaker');
            let animating = false;
            let distance = 0;
            let lastTime;


            var start = new Date().getTime();
            var length = route.getLength();
            var length_shown=0;
            function moveFeature(event) {
              const speed = Number(speedInput.value);
              const time = event.frameState.time;
              const elapsedTime = time - lastTime;
              distance = (distance + (speed * elapsedTime) / 1e6) % 2;
              lastTime = time;
              var index=distance > 1 ? 2 - distance : distance;
              const currentCoordinate = route.getCoordinateAt(
                index
              );


              var elapsed = new Date().getTime() - start;
              var toAdd = length*elapsed/speed - length_shown;
              var aindex=Math.min(elapsed/speed, 1);
              length_shown += toAdd;
              console.log("elapsed: ",elapsed);
              console.log("toAdd: ",toAdd);
              console.log("aindex : ",aindex);
              var finalIndex=Math.round(distance * 1000);
              console.log("event: ",Math.round(speed * elapsed / 1000));
              var point =hdata[2];
              position.setCoordinates(currentCoordinate);
              const vectorContext = getVectorContext(event);
              vectorContext.setStyle(getStyle(0,point.angle));
              vectorContext.drawGeometry(position);
              // tell OpenLayers to continue the postrender animation
              map.render();
            }

            function startAnimation() {
              animating = true;
              lastTime = Date.now();
              startButton.textContent = 'Stop';
              vectorHistory.on('postrender', moveFeature);
              // hide geoMarker and trigger map render through change event
              geoMarker.setGeometry(null);
            }

            function stopAnimation() {
              animating = false;
              startButton.textContent = 'Start';
              // Keep marker at current animation position
              geoMarker.setGeometry(position);
              vectorHistory.un('postrender', moveFeature);
            }

            startButton.addEventListener('click', function () {
              if (animating) {
                stopAnimation();
              } else {
                startAnimation();
              }
            });
        }
        function getStyle(acc,angle) {
          return new Style({
              image: new Icon({
                  anchor: [0.5, 0.5],
                  anchorOrigin: 'top-left',
                  anchorXUnits: 'fraction',
                  anchorYUnits: 'fraction',
                  opacity: 1,
                  src:"images/rs"+acc+".png",
                  fill: "#009688",
                  scale:0.3,
                  rotation: 0.017453292 * parseFloat(angle),
              })
          });
        }
        //========================= end animation button ===============================
        function animateMarker() {
          animMarker(); return;
        route = new LineString(arrayCoords);
          route.transform('EPSG:4326', 'EPSG:3857');
         routeCoords = route.getCoordinates();
         routeLength = routeCoords.length;
         geoMarker = new Feature({
          type: 'geoMarker',
          geometry: new Point(routeCoords[0])
        });
        geoMarker.setStyle(getStyle(hdata[0].ACC,hdata[0].angle));
        sourceHistory.addFeature(geoMarker);
        }
        
        var route ,routeCoords, routeLength , geoMarker ;
        var animating = false, speed, now,speed_index,paused = false, pause_coords = [], pause_time,pause_point,barIndex=0,barIndexCopy=0;
        var moveFeature = function(event) {
          if (animating) {
              if (speed !== speedInput.value) {
                  startAnimation(speed_index);
                  barIndex=barIndexCopy;
              }
              var vectorContext = event.vectorContext;
              var frameState = event.frameState;
              var elapsedTime = frameState.time - now;
              pause_time = elapsedTime;
              // here the trick to increase speed is to jump some indexes
              // on lineString coordinates
              var index = Math.round(speed * elapsedTime / 1000);
              speed_index = index;
              if (index >= routeCoords.length) { stopAnimation(true);  return;}
              barIndexCopy=index+barIndex;
              var currentPoint = new Point(routeCoords[index]);
              pause_coords = currentPoint.getCoordinates();
              var feature = new Feature(currentPoint);
              map.getView().setCenter(pause_coords);
              var point =hdata[barIndexCopy];
              updateHistoryMarker(pause_coords,"driver",point.ACC,12,point.speed,point.date,"adresse",12,44);
              pause_point=point;
              vectorContext.drawFeature(feature, getStyle(point.ACC,point.angle));
              updateBarAnimation(barIndexCopy,routeLength);
          }
          // tell OpenLayers to continue the postcompose animation
          map.render();
        };
        function startAnimation(param) {
          // if (animating) {
          //     pauseAnimation();
          // } else {
              for (let i = 0; i < param; i++) {
                  routeCoords.shift();
              }
            animating = true;
            now = new Date().getTime();
            speed = speedInput.value;
            startButton.style.display = "none";
            pause_button.style.display = "inline";
            continue_button.style.display = "none";
            // hide geoMarker
             geoMarker.setStyle(null);
            // just in case you pan somewhere else
            //map.getView().setCenter(center);
            map.on('postcompose', moveFeature);
            map.render();
          // }
        }
        
        
        
        function stopAnimation() {
          routeCoords=route.getCoordinates();
          speedInput.disabled = false;
          animating = false;
          paused = false;
          barIndex=0;
          // if animation cancelled set the marker at the beginning
          startButton.style.display = "";
          pause_button.style.display = "none";
          continue_button.style.display = "none";
          //infogeoMark.style.display = "none";
          var coord= routeCoords[0];
          map.getView().setCenter(coord);
          geoMarker.setStyle(getStyle(pause_point.ACC,pause_point.angle));
          updateHistoryMarker(coord,"driver",pause_point.ACC,12,pause_point.speed,pause_point.date,"adresse",12,44);
          //var coord = ended ? routeCoords[routeLength - 1] : routeCoords[0];
          /** @type {Point} */
          (geoMarker.getGeometry()).setCoordinates(coord);
          updateBarAnimation(0,routeLength)
          //remove listener
          map.un('postcompose', moveFeature);
        }
          function continueAnimation() {
              if (paused) {
                  animating = true;
                  paused = false;
                  stopButton.style.display = "inline";
                  continue_button.style.display = "none";
                  startButton.style.display = "none";
                  pause_button.style.display = "inline";
                  now = new Date().getTime() - pause_time;
                  speed = speedInput.value;
                  // hide geoMarker
                  geoMarker.setStyle(null);
                  // just in case you pan somewhere else
                  map.on('postcompose', moveFeature);
                  map.render();
              }
          }
        function pauseAnimation() {
          if (animating && paused === false) {
              map.getView().setCenter(pause_coords);
              animating = false;
              continue_button.style.display = "inline";
              pause_button.style.display = "none";
              paused = true;
              geoMarker.setStyle(null);
              //I hold elapsed time globally
              geoMarker.setStyle(getStyle(pause_point.ACC,pause_point.angle));
              (geoMarker.getGeometry()).setCoordinates(pause_coords);
              map.un('postcompose', moveFeature);
          }
        
        }
        // fin de la partie animation du marker ==============================================================================================
        var namesAlarms=ref([]);
        function getAlarmes(){
          Fetch.post("/alarmes",{token:token}).then(function(data){
              namesAlarms.value= data['names'];
              data["data"].forEach(device => {
                  //var prenom= device["driver"]["prenom"], nom= device["driver"]["nom"],immat= device["immat"],type= device["driver"]["type"];
                  var fdata=device["data"];
                  var imei=device["imei"];
                  for (let i = fdata.length-1; i >=0;  i--) {
                      let alarme = fdata[i];
                      alarme.imei=imei;
                      alarmesData.value.push(alarme);
                    //   manageAlarmes(alarme,device["imei"]);
                  }
                 
              });
            //   activeDataTableAlarme();
            //   var chartAlarmes=[],drillChartAlarms=[];
            //   alarmDataChart.forEach(chartAlarm => {
            //       chartAlarmes.push(chartAlarm);
            //       var id=chartAlarm.drilldown;
            //       var drill={name:chartAlarm.name,id:id,data:[]}
            //       var keys=Object.keys(drillAlarms[id]);
            //       keys.forEach(key => {
            //           drill.data.push(drillAlarms[id][key]);
            //       });
            //       drillChartAlarms.push(drill);
            //   });
              //pieChart("alarmesDash",chartAlarmes,drillChartAlarms,"Alarme");
              //console.log(chartAlarmes);
              //console.log(drillChartAlarms);
          });
        }
        function activeDataTableAlarme() {
          if($.fn.dataTable.isDataTable('#alarmesTab')){
              alarmesDataTable.destroy();
          } 
          alarmesDataTable= $('#alarmesTab').DataTable( { "searching": false, "bPaginate": false,"info": false,"scrollY": 333 });
        }
        var alarmDataChart=[],drillAlarms=[],diff=null;
        function manageAlarmes(alarme,imei) {
          var device=devices[imei];
          var codeAlarm= alarme.Alarm;
          var nomAlarm=namesAlarms[codeAlarm];
          var date= moment(alarme.date).format("DD-MM-YYYY HH:mm:ss");
          var prenom= device["driver"]["prenom"], nom= device["driver"]["nom"],immat= device["immat"],type= device["driver"]["type"];
          var tbody = document.getElementById("alarmesTbody");
          var tr = tbody.insertRow();
          var td1 = tr.insertCell(0), td2 = tr.insertCell(1), td3 = tr.insertCell(2);
          td1.innerHTML=prenom+" "+nom;td2.innerHTML=date;td3.innerHTML=nomAlarm;
          if (!alarmDataChart[codeAlarm]) { drillAlarms[codeAlarm]={};drillAlarms[codeAlarm][imei]=[prenom,1]; alarmDataChart[codeAlarm]={name: nomAlarm, y: 1,drilldown: codeAlarm };
          }else{alarmDataChart[codeAlarm].y++;if (drillAlarms[codeAlarm][imei]) {drillAlarms[codeAlarm][imei][1]++;}else{drillAlarms[codeAlarm][imei]=[prenom,1];}}
          
        }
        function sortArrayObjectAsc(array) {
            return array.sort((objA, objB) => {
                var date1=Number(new Date(objA.date)), date2=Number(new Date(objB.date));
                return date1 - date2; },
            );
        }
        function findDashData(){
          Fetch.post("/getDashData",{token:token}).then(function(data){
              manageDashData(data)
          });
        }
        var dataDistances=[],dataVitesses=[],dataFuelLevel=[],dataFuelConso=[],dataFuelLabels=[],allOdoStart=[],maxSpeedFlotte=0,
        rtDistancesBackup=[],rtMaxSpeedsBackup=[];
        function manageDashData(datas) {
            var distances=[],vitesses=[],rtDistances=[],rtMaxSpeeds=[];
            for (let j = 0; j < datas.length; j++) {
                const data = datas[j];
                var positions=data.data;
                // positions=sortArrayObjectAsc(positions);
                var prenom=data.driver.prenom,nom=data.driver.nom,imei=data.imei;
                var marker=getFeature(imei);
                var distance=0,vitesseMax=0;
                if (positions.length>0) {
                    vitesses[imei]=[];
                    positions.forEach(dt => {
                        vitesses[imei].push(dt.speed);
                    });
                    var conso=checkAddFuel(positions,imei);
                    var sousData=positions,sousDataLenght=sousData.length;
                    if (sousDataLenght>0) {
                        allOdoStart[imei]=sousData[0].Odometer;
                        distances[imei]={nom:nom,name:prenom,y:calculDistance(sousData[0].Odometer, sousData[sousDataLenght-1].Odometer)};
                        var vitesse={nom:nom,name:prenom,y:Math.max(...(vitesses[imei]))};
                    }else{
                        distances[imei]={nom:nom,name:prenom,y:0};
                        var vitesse={nom:nom,name:prenom,y:0};
                    }
                    dataFuelLabels.push(prenom+" "+nom);
                    dataFuelLevel.push(sousData[sousDataLenght-1].Fuel/10);
                    dataFuelConso.push(conso);
                    dataDistances.push(distances[imei]);
                    dataVitesses.push(vitesse);
                    distance=distances[imei].y;
                    vitesseMax=vitesse.y;
                    totalDistance.value+=distance;
                    if (vitesseMax>maxSpeedFlotte) {
                        maxSpeedFlotte=vitesseMax;
                        vitesseMaxFlotte.value=vitesseMax;
                    }
                    rtDistances.push(distance),rtMaxSpeeds.push(vitesseMax);
                    rtDistancesBackup[imei]=distance,rtMaxSpeedsBackup[imei]=vitesseMax;
                    marker.set('distance', distance);
                    marker.set('maxVitesse', vitesse.y);
                    marker.set('fuelConso', conso);
                    // $(".distance_"+imei).text(distance);
                }else{
                    marker.set('distance', 0);
                    marker.set('maxVitesse', 0);
                    marker.set('fuelConso', 0);
                }
                devicesById.value[imei].distance=distance;
            }
            var chartFuelData=[{name: 'Niveau réservoire',color:"#00c0ef",data: dataFuelLevel},{name: 'Consommation',color:"#f7a35c",data: dataFuelConso}];
            // ==================================================
            var distanceVitesseChart=[{name: 'Distance',color:"#00c0ef",data: rtDistances},{name: 'Max vitesse',color:"#f7a35c",data: rtMaxSpeeds}];

                    //console.log(chartFuelData);
          //chartMultiBar("fuelChart",chartFuelData,dataFuelLabels);
          chartBarRt("distanceChart",distanceVitesseChart,dataFuelLabels);
          //chartBar("vitesseMaxChart",dataVitesses,"Vitesse Max","Km/h");
            var rightScroll = $(".right-Scroll").height();
            $('.right-Scroll').slimScroll({
                height: rightScroll + 'px',
                alwaysVisible: true
            });
        }
        var tabConso=[];
        function checkAddFuel(levels,imei) {
          if (levels.length<1) {return;}
          var maxIndex=levels.length-1;
          var niveauDepart=levels[0].Fuel;
          var niveauActuel=0;
          tabConso[imei]=[];
          for (let i = 0; i < levels.length; i++) {
              const level = levels[i];
              var nextIndex=i+2;
              if (nextIndex<maxIndex) {
                  var nextLevel=levels[nextIndex].Fuel,currentLevel=level.Fuel;
                  if ((nextLevel-currentLevel)>4) {
                      tabConso[imei].push([niveauDepart,niveauActuel]);
                      if (levels[nextIndex+1].Fuel >nextLevel) {
                          while (levels[nextIndex+1].Fuel >nextLevel) {
                              nextLevel=levels[nextIndex+1].Fuel;nextIndex++;
                          }
                          
                      }
                      i=nextIndex;
                      niveauDepart=nextLevel;
                  } else {
                      niveauActuel=currentLevel;
                      if (!(niveauDepart>0)) {niveauDepart=niveauActuel;}
                  }
              }
         
          }
          var consoLenght=tabConso[imei].length;
          if (tabConso[imei][consoLenght-1] && (tabConso[imei][consoLenght-1][1] != niveauActuel)) {
              tabConso[imei].push([niveauDepart,niveauActuel]);
          }
        var conso=0;
        tabConso[imei].forEach(nv => {
              var cNv=nv[0]-nv[1]; if (cNv>0) {conso+=cNv};
          });
          return conso;
        }
        //==============================================
        function hideSowProfile(){
            $(".profileDropdown").toggle(); 
        }
        function ToggleAboutPopup(){
            $(".aboutPopup").toggle(); 
        }
        function ToggleFournisseurPopup(){
            $(".fournisseurPopup").toggle(); 
        }
        function getNameInput() {
           return  $("#poiname").val();    
        }
       
        var hStart=null,hEnd=null;
        
          function formatHDate(date){
              var fD=date.split("/");
              var time=fD[2].split(" ")[1]+":00";
              return fD[2].split(" ")[0]+"-"+fD[1]+"-"+fD[0]+" "+time;
          }
          // jQuery('.timepicker').datetimepicker({
          //         datepicker:false,
          //         format:'H:i',
          //         onChangeTime(dp,$input){
          //                 console.log($input.val())
          //         }
          // });
        function showListDevices() {
                if ($('.listDevices:hover').length == 0) {
                  $(".listDevices").toggle();  
                }
        }
        function hideGeoModal() {
          $('.addGeoMoadal').hide(); 
          if(draw){
              map.removeInteraction(draw);
              map.removeInteraction(snap);
              if(createdZone){vectorSource_geo.removeFeature(createdZone);}
          }   
          map.removeInteraction(modifyGeo);
        }
        function showGeoModal(indice) {
          $('.addGeoMoadal').show(); 
          map.setTarget("mapGeo");
          map.updateSize();  
          if(indice==2){createGeoZone("Polygon");indice=3;}
          changeGeoModalValues("","",indice);
        }
        function changeZoneGeoType(type,element) {
          $(element).addClass("ivu-select-item-selected");
          if (type=="Circle") {
                  $(".polygoneType").removeClass("ivu-select-item-selected");
              $(".type_zone").find(".selecredTypeZone").removeClass("fa-hexagon").addClass("fa-circle");     
          }else{
                $(".type_zone").find(".selecredTypeZone").removeClass("fa-circle").addClass("fa-hexagon"); 
                $(".circleType").removeClass("ivu-select-item-selected");
          }
          $(".zoneTypeListe").toggle();
          createGeoZone(type);
        }
        jQuery.datetimepicker.setLocale('fr');
        
        
        var selectedImei=null;
        function sowListPopup() {
            if(!selectedImei){WarningToastr("Vous devez sélectionné un appareil");return;}
          var element=$("#popupButton"+selectedImei);
          var offset = element.offset();
          var popupEl=$(".listMoreInfosPopup");
          var topPopup=offset.top - popupEl.innerHeight();
          topPopup=topPopup<5?5:topPopup;
          popupEl.fadeIn().css({
              left: Math.min(offset.left+element.innerWidth(), $(window).innerWidth()-popupEl.outerWidth()),
              top: topPopup
              });
          }
        function hideShowUserInfo(){
          $(".UserInfoEdit").toggle();
        }
        function hideShowLangPopup(){
          $(".ListRowIconNoMargin").toggle();
        }
        function sortByClass(a, b) {
          return a.className < b.className;
        }
        function showHideProfile(){
               $(".profileDropdown").toggle();   
          }
          function filterStatut(statut,element){
                  $(".statutBarCountainer").find(".ivu-tabs-tab").removeClass("ivu-tabs-tab-active");
                  $(element).addClass("ivu-tabs-tab-active");
                  if(statut==0){
                      showHideAllDevicesList(0);return;
                  }
                  showHideAllDevicesList(1);
                  if(statut==1){
                      $(".listStatut1").show();
                      $(".listStatut2").show();
                  }else{
                      $(".listStatut3").show();
                      $(".listStatut4").show();
                  }
          }
          var statutBarPositon=0;
          function nextStatutBar(){
             statutBarPositon -= 40;    
             statutBarPositon= statutBarPositon<-90?-90:statutBarPositon;
             $(".statutBarCountainer").css("transform","translateX("+statutBarPositon+"px)");
          }
          function prevStatutBar(){
             statutBarPositon += 40;    
             statutBarPositon= statutBarPositon>0?0:statutBarPositon;
             $(".statutBarCountainer").css("transform","translateX("+statutBarPositon+"px)");
          }
          
          function hideShowCmdList(){
              hideAllPanels();
              listCommands.toggle();
          }
        function hideAllPanels(){
          var elements =$(".leftPanelManager");
          elements.find(".ivu-drawer").removeClass("move-right-enter-active").addClass("move-right-leave-active move-right-leave-to");
          elements.addClass("ivu-drawer-hidden");
          elements.find(".ivu-drawer").hide();
         
          map.setTarget("gMapView");
          map.updateSize(); 
          createPoiEnabled=false;   
        } 
        
        function logout(){
          $("#logoutBtn").click();
        } 
        var listDeviceRow=$(".DeviceList").find(".DeviceListRow");
        listDeviceRow.click(function(ev){
          listDeviceRow.removeClass("Select");
          $(ev.currentTarget).addClass("Select");
        }) 
        function showHideAllDevicesList(index){
          if(index==1){
              listDeviceRow.hide();return;
          }
          listDeviceRow.show();
        }
        
        //=================================
        function dashFlotteStatistiques(mouve,statique,offline,offline48){
        // $(".onlineCount").text(mouve+static+offline);
        $(".mouveCount").text(mouve);
        $(".staticCount").text(statique);
        $(".hlCount").text(offline);
        $(".hl24Count").text(offline48);
        }
        function generatePie(mouve,statique,offline,offline48){
        Highcharts.chart('chart-fleet', {
        chart: {
          plotBackgroundColor: null,
          plotBorderWidth: null,
          plotShadow: false,
          type: 'pie'
        },
        title: {
          text: null
        },
        tooltip: {
            headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
            pointFormat: '{point.name}: <b style="color:{point.color}">{point.percentage:.1f}%</b>'
        },
        accessibility: {
          point: {
              valueSuffix: '%'
          }
        },
        plotOptions: {
          pie: {
              //allowPointSelect: true,
              cursor: 'pointer',
              //size:"25%",
              dataLabels: {
                  enabled: false,
                  format: '<b>{point.name}</b>: {point.percentage:.1f} %'
              }
          }
        },
        series: [{
          name: 'Status',
          colorByPoint: false,
          data: [{
              name: 'Mouvement',
              y: mouve,
              color:"#378124"
          }, {
              name: 'Statique',
              y: statique,
              color:"#ff9600"
          }, {
              name: 'H-ligne -48',
              y: offline,
              color:"#e14c4c"
          }, {
              name: 'H-ligne +48',
              y: offline48,
              color:"#706f6f"
          }]
        }],
        exporting:{
          enabled:false
        }
        });
        dashFlotteStatistiques(mouve,statique,offline,offline48);
        }
        //=======================================================
        function toggleProgessBar(){
        $(".progressBarMoadal").toggle();
        }
        function filter(element) {
          var value = $(element).val().toLowerCase();
          $(".listdevicesHistorique li").each(function() {
              if ($(this).text().toLowerCase().search(value) > -1) {
                  $(this).show();
              }
              else {
                  $(this).hide();
              }
          });
        }
        var selectedImeiHistory=null;
        function selectedDeviceHistory(imei,el){
          selectedImeiHistory=imei;
          listItemToggle(el);
        }
        var selectedImeiRapport=null;
        function selectedDeviceRapport(imei){
          selectedImeiRapport=imei;
        }
    function selectUser(id) {
        $("#"+id).prop('checked', true);
    }
    function ChangeAutoDate(value,unite, obj) {
        var now=new Date();
        $(".btn-day").removeClass("btn-background-cyan");
        if (unite=="H") { 
            var dateEnd= moment(now).format("YYYY-MM-DD HH:mm")+":59";
            var localeEnd=moment(now).format("DD-MM-YYYY HH:mm");
            var start=DateTime.addHours(value,now);
            $("#btnHistoryHour").addClass("btn-background-cyan");
            $("#HistoryHourSpan").html(obj);
        
        var dateStart= moment(start).format("YYYY-MM-DD HH:mm:ss");
            var localeStart=moment(start).format("DD-MM-YYYY HH:mm");
        }else{ 
            var dateEnd= moment(now).format("YYYY-MM-DD")+" 23:59:59";
            var localeEnd=moment(now).format("DD-MM-YYYY")+" 23:59";
            var start=DateTime.addDays(value,now); 
            $(obj.target).addClass("btn-background-cyan");
            var dateStart= moment(start).format("YYYY-MM-DD")+" 00:00:00";
            var localeStart=moment(start).format("DD-MM-YYYY")+" 00:00";
        }

        // $("#TrackingHistoryFromDate").val(dateStart);
        // $("#TrackingHistoryToDate").val(dateEnd);
        startTime=dateStart;endTime=dateEnd;
        //$("#FromDate").val(data.fromDate);
        //$("#ToDate").val(data.toDate);
        $('#daterange-history span').html(localeStart + ' - ' + localeEnd);
    }
    var startTime=moment().format("YYYY-MM-DD")+" 00:00:00",endTime=moment().format("YYYY-MM-DD")+" 23:59:59";
    var ReportTypeName="Activité",selectedImeiHistory=null;
    function getHistory(){
        $("#MainContent").addClass("whirl");
        selectedImeiHistory= $("#ReportVehicleID").val();
        // startTime=$("#TrackingHistoryFromDate").val();endTime=$("#TrackingHistoryToDate").val();
        // if(dS.length>2){startTime=formatHDate(dS);}if(dE.length>2){endTime=formatHDate(dE);}
        var ReportTypeID=$("#ReportTypeID").val();
        if (ReportTypeID==4) {
            selectedImeiHistory=imeis.toString();
            // selectedImeiHistory="866551038776188";
        }else{
            consommation.value=devices.value[selectedImeiHistory].consommation;
        }
        if(!selectedImeiHistory){$("#MainContent").removeClass("whirl");WarningToastr("Vous devez sélectionner un conducteur");return;}
        var data ={imei:selectedImeiHistory,start:startTime,end:endTime,token:token,rapport:ReportTypeID}
        if (dataTable) {
            dataTable.destroy();
        }
    
        showRapportActivity.value=0;
        Fetch.post("/history",data).then(function(data){
            if (ReportTypeID==1) {
                manageHistory(data);
                ReportTypeName="Activité";
            }else if (ReportTypeID==2) {
                manageRapportKilo(data);
                ReportTypeName="Kilométrique";
            }else if (ReportTypeID==3) {
                manageRapportFuel(data);
                ReportTypeName="Carburant";
            }else if (ReportTypeID==4) {
                manageRapportFlotte(data);
                ReportTypeName="Flotte";
            }
        });
    }
    function getHistoryModal(startTime,endTime){
        $("#MainContent").addClass("whirl");
        var selectedImeiHistory= $("#ReportVehicleID").val();
        if(!selectedImeiHistory){WarningToastr("Vous devez sélectionner un conducteur");return;}
        var data ={imei:selectedImeiHistory,start:startTime,end:endTime,token:token}
        Fetch.post("/history",data).then(function(data){
            manageHistoryModal(data);
        });
    }
    function getRapport(){
          if(!selectedImeiRapport){WarningToastr("Vous devez sélectionner un conducteur");return;}
          var dS=$("#rapportPositionDebut").val();var dE=$("#rapportPositionFin").val();
          if(dS.length>2){hStart=formatHDate(dS);}if(dE.length>2){hEnd=formatHDate(dE);}
          var data ={imei:selectedImeiRapport,start:hStart,end:hEnd}
          fetch("/history",{method:"POST",body: JSON.stringify(data), headers: {"Content-type": "application/json; charset=UTF-8"}}).then(function(response){return response.json()})
          .then(function(data){
              console.log(data);
              manageRapportPosition(data);
          });
        }
        function toFixed(num, fixed) {
          fixed = fixed || 0;
          fixed = Math.pow(10, fixed);
          return Math.floor(num * fixed) / fixed;
        }
        function calculDistance(odoStart, odoEnd) {
          return Math.abs(toFixed((Math.abs(odoEnd)-Math.abs(odoStart))/1000,1));
        }
        function manageRapportPosition(param) {
          var datas= param.data;
          var dataLength=datas.length;
          if (dataLength< 2) {$("#MainContent").removeClass("whirl");WarningToastr("Aucune donnée trouvée pour cette période!");return;}
          var lastData=datas[dataLength-1];
          var firstData=datas[0];
          var debut = firstData.date;
          var fin = lastData.date;
          var isStop=firstData.ACC==1,index=0;
          var tabStop=[],tabMouve=[];
          var distance= calculDistance(firstData.Odometer,lastData.Odometer);
          var overSpeed=0,maxSpeed=0;
          $("#dfRPosition").find(".v1").text(dateFormat(debut));$("#dfRPosition").find(".v2").text(dateFormat(fin));
          datas.forEach(function(data,i) {
              if (data.Alarm==22) { overSpeed++; }
              if (data.speed>maxSpeed) {maxSpeed=data.speed;}
              if (i==dataLength-1) { return; }
              if (data.ACC==0) {
                  if (!isStop) { index++;isStop=true;tabStop[index]=[];tabStop[index]["date"]=data.date;}
                  tabStop[index]["fin"]=datas[i+1].date;
              }else{
                  if (isStop) { index++;isStop=false;tabMouve[index]=[];tabMouve[index]["date"]=data.date;}
                  tabMouve[index]["fin"]=datas[i+1].date; 
              }
          });
          window.conduiteLong=0;
          var tempConduite=0, tempStop=0,stopLong=0,trajets=tabMouve.length,totalStop=tabStop.length;
          tabMouve.forEach(function(data,i) {
              var start = moment(data.date),end=moment(data.fin);
              var diff= end.diff(start);tempConduite+=diff;
              if (diff>window.conduiteLong) {window.conduiteLong=diff;}
          });
          tabStop.forEach(function(data,i) {
              var start = moment(data.date),end=moment(data.fin);
              var diff= end.diff(start);
              tempStop+=diff;
              if (diff>stopLong) {stopLong=diff;}
          });
          var conso=checkAddFuel(datas,selectedImeiRapport);
          var conduitEl=$("#rPTConduite"),stoptEl=$("#rPTStop"),speedEl=$("#rPTVitesse"),fuelEl=$("#rPTFuel");
          conduitEl.find(".v1").text(distance+"KM");conduitEl.find(".v2").text(millisFormat(tempConduite));conduitEl.find(".v3").text(millisFormat(conduiteLong));
          stoptEl.find(".v1").text(totalStop);stoptEl.find(".v2").text(millisFormat(tempStop));stoptEl.find(".v3").text(millisFormat(stopLong));
          speedEl.find(".v1").text(overSpeed);speedEl.find(".v2").text(maxSpeed+"KM/H");speedEl.find(".v3").text(0);
          fuelEl.find(".v1").text(conso+"L");fuelEl.find(".v2").text(0);
          console.log(tabStop);
          console.log(tabMouve);
        }
        function millisFormat(millis) {
         return moment.utc(millis).format('HH@ mm|').replace("@","H").replace("|","M");
        }
        function dateFormat(date) {
        return moment(date).format("DD MM YYYY HH:mm");  
        }
        
        [ 'Abdoulay','Bouba','DG Ba','Mohamed', 'Ibra','Samb','Maodo','Abdoul','Barry', 'Diallo', 'DG Ndiaye','Ibou' ]
        var data=[
                  { name: "Abdoulay", y: 62.74, drilldown: "Chrome"},
                  { name: "Bouba",y: 10.57,},
                  { name: "DG Ba", y: 7.23, drilldown: "Explorer" },
                  { name: "Safari", y: 5.58,drilldown: "Safari"},
                  { name: "Mohamed", y: 4.02, drilldown: "Edge" },
                  { name: "Ibra",  y: 1.92, drilldown: "Opera" },
                  {  name: "Samb", y: 17.62, drilldown: null },
                  {  name: "Maodo", y:22.62, drilldown: null },
                  {  name: "Abdoul", y: 48.62, drilldown: null },
                  {  name: "Barry", y: 28.62, drilldown: null },
                  {  name: "Diallo", y: 47.62, drilldown: null },
                  {  name: "DG Ndiaye", y: 17.62, drilldown: null },
                  {  name: "Ibou", y: 55.62, drilldown: null },
                  //{  name: "Other6", y: 33.62, drilldown: null },
                  //{  name: "Other7", y: 44.62, drilldown: null },
                  //{  name: "Other8", y: 12.62, drilldown: null },
                  //{  name: "Other9", y: 34.62, drilldown: null },
                  //{  name: "Other11", y: 54.62, drilldown: null },
                  //{  name: "Other12", y: 22.62, drilldown: null },
                  //{  name: "Other13", y: 47.62, drilldown: null },
                  //{  name: "Other14", y: 22.62, drilldown: null },
              ];
              function getUnite(name) {
                console.log(name);
              }
        function chartBarRt(id,data,categories){
            Highcharts.chart(id, {
            chart: {
                type: 'bar'
            },
            title: {
                text: null
            },
            
            xAxis: {
                categories: categories,crosshair: true,
                title: {
                    text: null
                }
            },
            yAxis: {
                min: 0,
                title: {
                    text: null,
                    align: 'high'
                },
                labels: {
                    overflow: 'justify'
                }
            },
            tooltip: {
                // valueSuffix: ' millions',
                headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
                // pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}:</td>' +
                //     '<td style="padding:0"><b>{point.y:.0f} getUnite("{point.name}")</b></td></tr>',
                footerFormat: '</table>',
                // shared: true,
                   formatter: function () {
                    console.log(this.point);
                        var name=this.point.series.name;
                        var unite=name=="Distance"?"Km":"Km/h";
                        return '<span style="font-size:10px">'+this.point.category+'</span><table><tr><td style="color:'+this.point.series.color+';padding:0">'+name+':</td><td style="padding:0"><b>'+this.point.y+' '+unite+'</b></td></tr></table>';
                    },
                useHTML: true
                    }
                    ,
            plotOptions: {
                bar: {
                    dataLabels: {
                        enabled: true
                    },
                // pointWidth:12,
                maxPointWidth:25,
                groupPadding: 0.4,
                //  stacking: 'normal',
                },
            },
            legend: {
                layout: 'horizontal',
                borderWidth: 1,
                backgroundColor:
                    Highcharts.defaultOptions.legend.backgroundColor || '#FFFFFF',
                shadow: true
            },
            credits: {
                enabled: false
            },
            series: data,
            exporting:{
            enabled:false
            }
        });

        
    }
    function chartBar(id,data,name,unite){
        // Create the chart
        var container=$("#"+id);
        if(container.width()<600){if(data.length>18){$("#"+id).css("width","600px")}}
        Highcharts.chart(id, {
        chart: {
          type: 'column'
        },
        title: {
          text:null
        },
        subtitle: {
          text: null
        },
        accessibility: {
          announceNewData: {
              enabled: true
          }
        },
        xAxis: {
          type: 'category'
        },
        yAxis: {
          title: {
              text: null
          }
        
        },
        legend: {
          enabled: false
        },
        plotOptions: {
          series: {
              borderWidth: 0,
              dataLabels: {
                  enabled: true,
                  format: '{point.y:.0f}'
              }
          }
        },
        
        tooltip: {
          headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
          pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y:.0f}'+unite+'</b><br/>'
        },
        
        series: [
          {
              name: name,
              colorByPoint: true,
              data: data
          }
        ],
        drilldown: {
          series: [
              {
                  name: "Chrome",
                  id: "Chrome",
                  data: [["v65.0", 0.1],["v64.0",1.3],["v63.0",53.02],["v62.0",1.4],["v61.0",0.88],["v60.0",0.56],["v59.0",0.45],["v58.0",0.49],["v57.0",0.32],["v56.0",0.29],["v55.0",0.79],["v54.0",0.18],["v51.0",0.13],["v49.0",2.16],["v48.0",0.13],["v47.0",0.11],["v43.0",0.17],["v29.0",0.26]
                  ]
              },
              {
                  name: "Firefox",
                  id: "Firefox",
                  data: [["v58.0",1.02],["v57.0",7.36],["v56.0",0.35],["v55.0",0.11],["v54.0",0.1],["v52.0",0.95],["v51.0",0.15],["v50.0",0.1],["v48.0",0.31],["v47.0",0.12]
                  ]
              },
              {
                  name: "Internet Explorer",
                  id: "Internet Explorer",
                  data: [["v11.0",6.2],["v10.0",0.29],["v9.0",0.27],["v8.0",0.47]
                  ]
              },
              {
                  name: "Safari",
                  id: "Safari",
                  data: [["v11.0",3.39],["v10.1",0.96],["v10.0",0.36],["v9.1",0.54],["v9.0",0.13],["v5.1",0.2]
                  ]
              },
              {
                  name: "Edge",
                  id: "Edge",
                  data: [["v16",2.6],["v15",0.92],["v14",0.4],["v13",0.1]
                  ]
              },
              {
                  name: "Opera",
                  id: "Opera",
                  data: [["v50.0",0.96],["v49.0",0.82],["v12.1",0.14]
                  ]
              }
          ]
        }
        });
        container.css("width","100%").css("overflow-x","auto");
        }
        
       
        
        function stockChart(datas,id){
        var data=datas.data;
        var activite=datas.activite;
        Highcharts.stockChart(id, {
        //     xAxis: {
        //     plotBands:plotBands,
        //     //  [
        //     //     plotBands
        //     //     { // mark the weekend
        //     //         color: '#FCFFC5',
        //     //         from: 1616377700000,
        //     //         to: 1616405888000,
        //     //         label: {
        //     //             text: 'Light air',
        //     //             style: {
        //     //                 color: '#606060'
        //     //             }
        //     //         }
        //     //     },{ // mark the weekend
        //     //         color: '#FCFFC5',
        //     //         from: 1616406337000,
        //     //         to: 1616406488000,
        //     //         label: {
        //     //             text: 'Light air',
        //     //             style: {
        //     //                 color: '#606060'
        //     //             }
        //     //         }
        //     //     }
        
        //     // ]
         
        // },
          plotOptions: {
              series: {
              zoneAxis: 'x',
                  zones: activite,
              }
          },
          rangeSelector: {
              selected: 1
          },
          // title: {
          //     text: 'Courbe de consommation du carburant'
          // },
          series: [{
              name: 'Carburant',
              type:"area",
              data: data,
              tooltip: {
                  //valueDecimals: 2,
                  headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
                pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
                    '<td style="padding:0"><b>{point.y:.0f} '+getFuelUnit(selectedImeiHistory)+'</b></td></tr>',
                footerFormat: '</table>',
                shared: true,
                useHTML: true
              },
              
          }]
        });
        }
        var categories= [ 'Abdoulay','Bouba','DG Ba','Mohamed', 'Ibra','Samb','Maodo','Abdoul','Barry', 'Diallo', 'DG Ndiaye','Ibou' ];
        var dataConso=[{name: 'Niveau Carburant',color:"#00c0ef",data: [49.9, 71.5, 106.4, 129.2, 144.0, 176.0, 135.6, 148.5, 216.4, 194.1, 95.6, 54.4]},  
          {name: 'Consommation',color:"#ff9600",data: [42.4, 33.2, 34.5, 39.7, 52.6, 75.5, 57.4, 60.4, 47.6, 39.1, 46.8, 51.1]}];
        
        function chartMultiBar(id,data,categories){
        Highcharts.chart(id, {
        chart: { type: 'column'},
        title: { text: null },
        subtitle: { text: null },
        xAxis: { categories:categories, crosshair: true},
        yAxis: { min: 0, title: { text: null }},
        tooltip: {
          headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
          pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
              '<td style="padding:0"><b>{point.y:.0f} L</b></td></tr>',
          footerFormat: '</table>',
          shared: true,
          useHTML: true
        },
        plotOptions: {
          column: {
              pointPadding: 0.2,
              borderWidth: 0
          }
        },
        series: data
        });
        }
        var pieData= [{name: "Excès vitesse",y: 32.74,drilldown: "chrome"},{name: "virage brusque",y: 10.57,drilldown: null},{name: "Vol carburant",y: 17.23,drilldown: "Safari"},{name: "impact",y: 15.58,drilldown: "impact"},{name: "Niveau Réservoir faible",y: 4.02,drilldown: "Edge"},{name: "Brouillage GSM",y: 11.92,drilldown: "Opera"},{name: "freinage Brusque",y: 7.62,drilldown: null}]
        var pieDrilldown=[
              {
                  name: "Excès vitesse",
                  id: "Chrome",
                  data: [[ "v65.0", 0.1],["v64.0",1.3],["v63.0",53.02],["v62.0",1.4],["v61.0",0.88],["v60.0",0.56],["v59.0",0.45],["v58.0",0.49],["v57.0",0.32],["v56.0",0.29],["v55.0",0.79],["v54.0",0.18],["v51.0",0.13],["v49.0",2.16],["v48.0",0.13],["v47.0",0.11],["v43.0",0.17],["v29.0",0.26]]
              },
              {
                  name: "virage brusque",
                  id: "Firefox",
                  data: [[ "v65.0", 0.1],["v64.0",1.3],["v63.0",53.02],["v62.0",1.4],["v51.0",0.13],["v49.0",2.16],["v48.0",0.13],["v47.0",0.11],["v43.0",0.17],["v29.0",0.26]]
              },
              {
                  name: "impact",
                  id: "impact",
                  data: [[ "v65.0", 0.1],["v64.0",1.3],["v63.0",53.02],["v62.0",1.4],["v48.0",0.13],["v47.0",0.11],["v43.0",0.17],["v29.0",0.26]]
              },
              {
                  name: "Vol carburant",
                  id: "Safari",
                  data: [["v58.0",0.49],["v57.0",0.32],["v56.0",0.29],["v55.0",0.79],["v54.0",0.18],["v51.0",0.13],["v49.0",2.16],["v48.0",0.13],["v47.0",0.11],["v43.0",0.17],["v29.0",0.26]]
              },
              {
                  name: "Niveau Réservoir faible",
                  id: "Edge",
                  data: [[ "v65.0", 0.1],["v64.0",1.3]]
              },
              {
                  name: "Brouillage GSM",
                  id: "Opera",
                  data: [["v58.0",0.49],["v57.0",0.32],["v56.0",0.29],["v55.0",0.79],["v54.0",0.18],["v29.0",0.26]]
              }
          ];
          function getFuelUnit(imei){
            return devices.value[imei]&&devices.value[imei].hasSonde?"L":"%";
        }
        function pieChart(id,data,drilldown,name){
        // Create the chart
        Highcharts.chart(id, {
        chart: {
          type: 'pie'
        },
        title: {
          text: null
        },
        subtitle: {
          text: null
        },
        
        accessibility: {
          announceNewData: {
              enabled: true
          },
          point: {
              valueSuffix: ''
          }
        },
        
        plotOptions: {
          series: {
              dataLabels: {
                  enabled: true,
                  format: '{point.name}: {point.y:.0f}'
              }
          }
        },
        
        tooltip: {
          headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
          pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y:.0f}</b> <br/>'
        },
        
        series: [
          {
              name: name,
              colorByPoint: true,
              data: data
          }
        ],
        drilldown: {
          series: drilldown
        }
        });
        }
        var currentCommande=null,currentCommandeTitle=null,cmd=null;
        function sendCommandeAlert(commande,title) {
          if (!selectedImei) {
            WarningToastr("Vous devez sélectionné un véhicule avant de lancer cette commande!");return;
          }
          currentCommande=commande;currentCommandeTitle=title;
          cmd=commandes[commande];
          if (cmd) {
              $(".actionInfoCommandesTitle").text(title);
              $(".popupConfirmCommandes").show();
              if (cmd.isVal) {
                  $("#alerteCMDText").text(cmd.text);
                  $(".AlertCommandesInfos").show();
                  $(".actionInfoCommandes").html('<input type="number" require placeholder="Entrez la valeur" class="InputBorderGray">');
              }else{
                  $(".AlertCommandesInfos").hide();
                  $(".actionInfoCommandes").text(cmd.text);
              }
          }else{
            WarningToastr("Commande non disponible!");return;  
          }
        }
        function cancelCommande() {
          $(".popupConfirmCommandes").hide();
          $(".AlertCommandesInfos").hide();
          $(".popupCommandes").hide();
          clearInterval(countDown);countDown=null;
        }
        function valideCommande() {
          var selectedMarker=getFeature(selectedImei)
          var driver=selectedMarker.get("driver");
          var el=$(".popupCommandes");
          countDownCommandes();
          el.find(".Title").text(driver+" | "+currentCommandeTitle);
          el.show();
          $(".popupConfirmCommandes").hide();
          sendCommande();
        }
        function sendCommande() {
          var body = {imei:selectedImei,code:currentCommande};
          if (cmd.isVal) {body["val"]=parseInt($(".InputBorderGray").val());if (!body["val"]) {WarningToastr("Vous devez entrer une valeure valide.")}}
          fetch("sendCommande",{method:"POST",body: JSON.stringify(body), headers: {"Content-type": "application/json; charset=UTF-8"}}).then(function(response) { return response.text() }).then(function(data) {
             console.log(data);
          }); 
        }
        var commandes={     
                  9001:{text:"Attention cette commande entrainera l'arrét immédiat du véhicule "},
                  9000:{text:"Attention cette commande débloquera le véhicule "},
                  123:{text:"Vous êtes sur le point de modifier la vitesse maximale autorisée. Si cette vitesse est dépassée vous recevrez une alarme. ",isVal:true},
                  128:{text:"Cette commande vous permet de modifier la valeur d'accélération brusque, la valeur par defaut est de 450,lorsqu'il est à 0, la fonction est  annulée. Lorsque l'accélération dépasse l'accélération défini, une alarme d'accélération brutale est générée. ",isVal:true},
                  129:{text:"Cette commande vous permet de modifier la valeur de décélération brusque, la valeur par defaut est de 800,lorsqu'il est à 0, la fonction est  annulée. Lorsque la décélération dépasse la décélération défini, une alarme de décélération brutale est générée. ",isVal:true},
                  130:{text:"Cette commande vous permet de modifier valeur d'alarme de virage sévère, plus la valeur est petite, plus la valeur est sensible, la plage de valeurs est [0,32], la valeur par défaut est 19 ; lorsqu'il est à 0, la fonction est annulée.Lorsque le virage serré dépasse la valeur prédéfinie, une alarme de virage serré est générée.",isVal:true},
                  131:{text:"Cette commande vous permet de modifier la sensibilité aux chocs, la plage de valeurs est de 0 à 10, plus la valeur est petite, plus elle est sensible, 0 correspond à la fonction d'annulation et la valeur par défaut est 5 ",isVal:true},
                  132:{text:"Cette commande vous permet de modifier le delai de détection du remorquage, Lorsque le temps de vibration atteint la valeur définie, une alarme sera générée. La plage de valeurs est de 0 à 255, l'unité est la seconde ;si la valeur est 0, la fonction sera désactivée, la valeur par défaut est 0 ",isVal:true},
                  133:{text:"Cette commande vous permet de modifier le temps autorisé de stop avec acc, la plage de valeurs est de 0 à 255, l'unité est la minute ;si la valeur est 0 ,la fonction sera désactivée, la valeur par défaut est 0 ",isVal:true},
                  151:{text:"Cette commande vous permet de modifier la sensibilité de détection des vibrations. La plage de valeurs est [3,255], plus la valeur est petite, plus elle est sensible. La valeur par défaut est 3. ",isVal:true},
                  1521:{text:"Cette commande permet d'armer le véhicule. Dans l'état d'armement, le déclencheur d'entrée (porte ouverte, allumage, etc.), le remorquage, les interférences GSM généreront une alarme de vol de voiture. ",isVal:false},
                  1520:{text:"Cette commande permet de désarmement le véhicule. Dans l'état de désarmement, le déclencheur d'entrée (porte ouverte, allumage, etc.), le remorquage, les interférences GSM ne généreront pas d'alarme de vol de voiture. ",isVal:false},
                  153:{text:"Cette commande permet de définir la durée de perturbation réseau pour l'alarme de brouillage GSM.la valeur est [0,255] secondes, si la valeur est 0 la fonction d'alarme de détection de brouillage GSM est annuler, la valeur par défaut est 0.Lorsque le GSM est perturbé pendant plus longtemps que la durée définie, une alarme de brouillage est générée. ",isVal:true},
          }
        var countDown; 
        function countDownCommandes() {
          var count=20;
          var countDownEl=$("#commandeCountDown");
          var statutCmd=$("#statutCommandes");
          countDownEl.text(count);
          countDownEl.css("color","#0076FF");
          statutCmd.show();
          var notifTimeOut=document.getElementById('ID_CMD_OUT');
          if (!countDown) {
              countDown = setInterval(function() {
                  count--;
                  countDownEl.text(count);
                  if (count==0) {
                      notifTimeOut.muted = false;
                      clearInterval(countDown);countDown=null;
                      countDownEl.css("color","#ff3824");
                      statutCmd.hide();
                      countDownEl.text("Aucune réponse de la balise vérifier que le véhicule est bien en ligne.");
                      notifTimeOut.play();
                  }
              }, 1000); 
          }
        }
        function viewDeviceEdit(imei) {
          var device=devices[imei];
          $(".imeiDevice").val(imei);$("#driverDevice").val(device.driver.prenom +" "+device.driver.nom);$("#deviceImmat").val(device.immat);$("#consoDvice").val(device.consommation);$("#simDevice").val(device.sim);
          $("#modelVehicule").val(device.modelVehicule);$("#noteDevice").val(device.note);$("#iconeSizeDevice").val(device.iconSize);$("#visibilityDevice").val(device.visibility);$("#deviceRelay").val(device.relay);
          $("#createdDevice").val(device.created);$("#typeVehicleDevice").val(device.typeVehicule);$("#typeBaliseDevice").val(device.type);
          $(".modalEditDevice").show();
        }
        function hideDeviceEditModal() {
          $(".modalEditDevice").hide();
        }
        function editDevice() {
          var data=getFormData("editDeviceForm");
          fetch("updateDevice",{method:"POST",body: JSON.stringify(data), headers: {"Content-type": "application/json; charset=UTF-8"}}).then(function(response) { return response.text() }).then(function(data) {
            SuccessToastr("Mise à jour réussi!")
              console.log(data);
           });
        }
        function getFormData(id) {
         var formArray= $("#"+id).serializeArray()
          var returnArray = {};
          for (var i = 0; i < formArray.length; i++){
              returnArray[formArray[i]['name']] = formArray[i]['value'];
          }
          return returnArray;
        }

        // ================================================================
        function addMarker(coords,icon,speed,angle,size) {
            var coordonnees = fromLonLat(coords), geometry = new Point(coordonnees),marker = new Feature();
            sourceHistory.addFeature(marker);
            marker.setGeometry(geometry);
            marker.setStyle(iconStyle(icon,angle,size));
            marker.set('speed', speed);
        }
        
        function setAdresse(address,index,length,key) {
            historyDataCopy[index][key]=address;
            if (length==index) {
                setTimeout(() => {
                    setDefineAdresses();
                }, 1000);
                // dans le cas d'une longue duré sélectionné la dernière adresse peut se terminer alorqu'il reste des adresse en chargement d'ou la répétition du settimeout
                setTimeout(() => {
                    setDefineAdresses(); 
                }, 8000);

                setTimeout(() => {
                    setDefineAdresses(); 
                }, 12000);

                setTimeout(() => {
                    setDefineAdresses(); 
                }, 20000);
            }
        }
        function setDefineAdresses() {
            historyData.value={...historyDataCopy};  
        }
        function defineLineStatut (status,imei,isUpdate,oldStatus) {
            totalStatus[status]++;
            $("#listRow"+imei).attr("data-order",status);
            var statutText="H-ligne +48h"; if (status==1) { statutText="Mouvement"; }else if (status==2) { statutText="Statique"; }else if (status==3) { statutText="H-ligne -48h"; }
                $(".statut_" + imei).html('<font class="status" style="color:'+getStatutColor(status)+';">'+statutText+'</font>');
            $("#listRow"+imei).attr("class","DeviceListRow SBorderB HDiv listStatut"+status);
            if(isUpdate){
                totalStatus[oldStatus]--;sortDevicesList();
                if (totalStatus[1] != totalMouve.value || totalStatus[2] !=totalStatic.value || totalStatus[3] !=totalOffline.value || totalStatus[4] !=totalOffline48.value) {
                    generatePie(totalStatus[1],totalStatus[2],totalStatus[3],totalStatus[4]);
                }
            }
            totalMouve.value=totalStatus[1],totalStatic.value=totalStatus[2],totalOffline.value=totalStatus[3],totalOffline48.value=totalStatus[4];
            $(".onlineCount").text(totalStatus[1]+totalStatus[2]);
            $(".offlineCount").text(totalStatus[3]+totalStatus[4]);
        }
        function dateDiff (date1, date2) {
          var diff = {};var tmp = date2 - date1;
          tmp = Math.floor(tmp / 1000); // Nombre de secondes entre les 2 dates
          diff.sec = tmp % 60; // Extraction du nombre de secondes
        
          tmp = Math.floor((tmp - diff.sec) / 60); // Nombre de minutes (partie enti?)
          diff.min = tmp % 60; // Extraction du nombre de minutes
        
          tmp = Math.floor((tmp - diff.min) / 60); // Nombre d'heures (enti?s)
          diff.hour = tmp % 24; // Extraction du nombre d'heures
          tmp = Math.floor((tmp - diff.hour) / 24); // Nombre de jours restants
          diff.day = tmp;
        
          return diff;
        }
        
        function convertDate (date) {
          var myRegEx = new RegExp("-", "gm");
          date = date.replace(myRegEx, "-");
          date = date.replace(/ /, "T");
          date = new Date(date);
          return date;
        }
        function getStatus(localtimeUser, localTimeNow, vitesse, acc,imei) {
          var status = 3;
          diff = dateDiff(convertDate(localtimeUser), convertDate(localTimeNow));
              if (diff.day < 1) {
                  if (acc == 0) {
                      status = 3;
                  }
                  if (diff.hour < 1) {
                      if (diff.min <= 3) {
        
                          if (vitesse <1) {
                              status = 2;
                              if (acc == 0) {
                                  status = 3;
                              }
                          } else {
                              if (acc == 1) {
                                  status = 1;
                              }
                          }
        
                      } else {
                          status = 3;
                      }
        
                  } else {
                      if (acc == 0) {
                          status = 3;
                      }
                      status = 3;
                  }
              } else {
                  status = 3;
                  if (diff.day >= 2) {
                      status = 4;
                  }
              }
              if (status<3) {
                activeHistory.value[imei]=1;
              } else if (status==3) {
                if (moment(localtimeUser).format("DD")==moment(localTimeNow).format("DD")) {
                    activeHistory.value[imei]=1;
                }else{
                    activeHistory.value[imei]=2;
                }
              }else{
                activeHistory.value[imei]=3;
              } 
              
          return status;
        }
        function iconStyle(icon,angle,size) { 
          // ================================
         var iconStyle = new Style({
              image: new Icon({
                  anchor: [0.5, 0.5],
                  anchorOrigin: 'top-left',
                  anchorXUnits: 'fraction',
                  anchorYUnits: 'fraction',
                  opacity: 1,
                  src:icon,
                  fill: "#009688",
                  scale:size,
                  rotation: 0.017453292 * angle,
              }),
              
          });
         
        //   iconStyle.setZIndex(zIndexLine(status));
          return iconStyle;
        }
        function zIndexLine (status) {
          var z = 0;
          if (status == 1) z = 4000;
          if (status == 2) z = 3000;
          if (status == 3) z = 2000;
          if (status == 4) z = 1000;
          return z;
        }
        function colorTracer (vitesse) {
          // Couleur tracé
          var colorTrace = "#2d8cf0";
          if (vitesse >= 20 && vitesse < 40) {
              colorTrace = "#5cadff";
          }
          if (vitesse >= 40 && vitesse < 60) {
              colorTrace = "#19be6b";
          }
          if (vitesse >= 60 && vitesse < 80) {
              colorTrace = "#9c3";
          }
          if (vitesse >= 80 && vitesse < 100) {
              colorTrace = "#f90";
          }
          if (vitesse >= 100 && vitesse < 120) {
              colorTrace = "#ed4014";
          }
          if (vitesse >= 120) {
              colorTrace = "#903";
          }
          return colorTrace;
        }
        function traceRoute (lng, lat, marker, vitesse, driver) {
              compteurTrajet++;
              var oldVitesse=marker.get("speed");
              if (vitesse == 0) vitesse = oldVitesse;
              if (oldVitesse == 0) oldVitesse = vitesse;
              var colorTrace = colorTracer(vitesse);
                  // faire le trace
              var coord = transform(marker.getGeometry().getCoordinates(), 'EPSG:3857', 'EPSG:4326');
              var coords = [
                  [coord[0], coord[1]],
                  [lng, lat]
              ];
              var lineString = new LineString(coords);
              // transform to EPSG:3857
              lineString.transform('EPSG:4326', 'EPSG:3857');
        
              
              var lineStyle = new Style({
                  stroke: new Stroke({
                      color: colorTrace,
                      width: 4,
                      zIndex: 500
                  })
              });
              // create the feature
              var tracer = new Feature({
                  geometry: lineString,
                  name: driver,
                  type: 'trajet',
                  vitesse: vitesse,
                  couleur: colorTrace
              });
              tracer.setId(marker.getId()+"_"+compteurTrajet);
              tracer.setStyle(lineStyle);
              vectorSource.addFeature(tracer);
        }
        
        function updateHistoryMarker(coordinates,driver,acc,distance,speed,date,adresse,gps,gsm) {
          $(popup.element).show();
          contentPopup.innerHTML = getPopupHistoryContent(driver,acc,distance,speed,date,adresse,gps,gsm);
          popup.setPosition(coordinates);
          $(".closerPopup").on("click",function(){
              popup.set('imei', null);
              $(popup.element).hide();
          })
        }
        function getStatutColor (statut) {
          if (statut==1) {return "#05870a";}else if (statut==2) { return "#ffc107";}else if (statut==3) {return "#db325a";}else if (statut==4) {return "#766c6c";}
        }

        function setPopupContent(feature) {
          var statut=feature.get("statut"); var statutColor=getStatutColor(statut); var accText="OFF"; var statutText="H-ligne +48h"; if (statut==1) { statutText="Mouvement";accText="ON"; }else if (statut==2) { statutText="Statique";accText="ON"; }else if (statut==3) { statutText="H-ligne -48h";accText="OFF"; }
          popup_status.value=statut;
          popup_status_color.value=statutColor,popup_signalGsm.value=feature.get("signalGsm"),popup_precision.value=feature.get("precision"),popup_immat.value=feature.get("immat"),popup_driver.value=feature.get("driver"),popup_statut.value=statutText,popup_blocked.value=feature.get("blocked"),
        popup_last_updated.value=(moment(feature.get("utc")).fromNow()),popup_utc.value=(moment(feature.get("utc")).format("DD-MM-YYYY HH:mm:ss")),popup_adresse.value=feature.get("adresse"),popup_speed.value=feature.get("speed"),popup_fuel.value=feature.get("fuel"),popup_distance.value=feature.get("distance"),popup_battery_ext.value=feature.get("battery_ext");
          $(".MoveStateLeft").html('<font style="color:'+statutColor+';font-size: 13px;" class="MoveState">'+statutText+'</font>');
          $(".leftTime").text((moment(feature.get("utc")).format("DD-MM-YYYY HH:mm:ss")));
          $(".SpeedValue").text(feature.get("speed"));$(".MileageValue").text(feature.get("distance"));
          $(".longitudeLeft").text(feature.get("lng"));$(".latitudeLeft").text(feature.get("lat"));
          return '<div class="Content popup-content" style="height: 100%;"><table><tbody><tr> <td class="TimeValue" colspan="3" style="font-size:14px">'+feature.get("driver")+'</td><td class="SportValue"><div class="MoveState" style="background-color:'+statutColor+';">'+statutText+'</div></td><td><button class="closerPopup"></button></td></tr><tr> <th class="NetTitle">Acc:</th> <td class="Icon"><img style="display:'+(statut<3?"block":"none")+';width:1.1em;display:inline;" src="images/Link_Online.png"><span class="Text" style="color:'+statutColor+';">'+accText+'</span></td> <th class="GSMTitle">Signal:</th><td class="GSMValue"> <span class="Icon"><img src="images/Signal.png?v=1"><span style="padding-left:6px;">59</span></span> <span class="Icon"><img src="images/Satellite.png"><span style="padding-left:6px;">24</span></span> </td></tr><tr> <th class="PowerTitle">Date:</th><td class="PowerValue">'+(moment(feature.get("utc")).format("DD-MM-YYYY"))+'</td><th class="PosModeTitle">UTC:</th><td class="Text">'+(moment(feature.get("utc")).format("HH:mm:ss"))+'</td></tr><tr><th class="SpeedTitle">Vitesse:</th><td class="SpeedValue">'+feature.get("speed")+' km/h</td><th class="MileageTitle">Distance: </th><td class="MileageValue">'+feature.get("distance")+' Km</td> </tr><tr><th class="AlarmTitle">Adresse:</th><td class="AlarmValue" colspan="3" style="white-space:pre-wrap;">'+feature.get("adresse")+'</td></tr></tbody></table></div>';
        }
        function getPopupHistoryContent(driver,acc,distance,speed,date,adresse,gps,gsm) {
          var statut=acc==1?1:3; var statutColor=getStatutColor(statut); var accText="OFF";if (statut==1){ accText="ON"; }
         return '<div class="Content popup-content" style="height: 100%;"><table><tbody><tr> <td class="TimeValue" colspan="3" style="font-size:14px">'+driver+'</td><td class="SportValue"><div class="MoveState" style="background-color:'+statutColor+';display:none">statut</div></td><td><button class="closerPopup"></button></td></tr><tr> <th class="NetTitle">Acc:</th> <td class="Icon"><img style="display:'+(statut<3?"block":"none")+';width:1.1em;display:inline;" src="images/Link_Online.png"><span class="Text" style="color:'+statutColor+';">'+accText+'</span></td> <th class="GSMTitle">Signal:</th><td class="GSMValue"> <span class="Icon"><img src="images/Signal.png?v=1"><span style="padding-left:6px;">59</span></span> <span class="Icon"><img src="images/Satellite.png"><span style="padding-left:6px;">24</span></span> </td></tr><tr> <th class="PowerTitle">Date:</th><td class="PowerValue">'+(moment(date).format("DD-MM-YYYY"))+'</td><th class="PosModeTitle">UTC:</th><td class="Text">'+(moment(date).format("HH:mm:ss"))+'</td></tr><tr><th class="SpeedTitle">Vitesse:</th><td class="SpeedValue">'+speed+' km/h</td><th class="MileageTitle">Distance: </th><td class="MileageValue">'+distance+' Km</td> </tr><tr><th class="AlarmTitle">Adresse:</th><td class="AlarmValue" colspan="3" style="white-space:pre-wrap;">'+adresse+'</td></tr></tbody></table></div>';
        }
        function closer() {
          popup.set('imei', null);
          $(popup.element).hide();
        }
        
        function getFeatureGeo (id) {
         return vectorSource_geo.getFeatureById(id);
        }
        function togleAlarmPanel () {
          var element=$("#ID_ALARM_MANAGE").find(".ivu-drawer-wrap");
          hideShowPanel(element,0);
        }
        function toglePoiPanel () {
          var element=$(".POIManage").find(".ivu-drawer-wrap");
          hideShowPanel(element,1);
        }
        function togleJournalPanel () {
          var element=$(".JournalManager").find(".ivu-drawer-wrap");
          hideShowPanel(element,0);
        }
        function hideShowPanel (element,origine) {
          hideAllPanels(origine);
          if (element.is(":hidden")) {
              element.removeClass("ivu-drawer-hidden");
              element.find(".ivu-drawer").show();
              element.find(".ivu-drawer").removeClass("move-right-leave-active move-right-leave-to").addClass("move-right-enter-active");
                if (origine==1) {
                  map.setTarget("mapPoi");
                  map.updateSize();   
                  createPoiEnabled=true;
                }
          }else{
              element.find(".ivu-drawer").removeClass("move-right-enter-active").addClass("move-right-leave-active move-right-leave-to");
              element.addClass("ivu-drawer-hidden");
              element.find(".ivu-drawer").hide();
              if (origine==1) {
                  map.setTarget("gMapView");
                  map.updateSize(); 
                  createPoiEnabled=false;    
                }
          }
        }
        function desactivateTab (index,el) {
          var element=el.target;
          hideAllPanels();
          vectorLayer.setVisible(true);
          vectorHistory.setVisible(false);
          hideAllOverlays(0,null);
          if (index==1) {
              vectorLayer.setVisible(false);
              vectorHistory.setVisible(true);
              hideAllOverlays(1,"h_overlay");
              map.setTarget("MapHistorique");
              map.updateSize(); 
          }else if(index==0) {
              map.setTarget("gMapView");
              map.updateSize(); 
          }
          var arr= ["0%","-100%","-200%","-300%","-400%","-500%"];
          $(".topNavTabs").attr("class","ivu-tabs-tab topNavTabs");
          $(".navTopTabPane").css("visibility","hidden");
          $(element).attr("class","ivu-tabs-tab topNavTabs ivu-tabs-tab-active ivu-tabs-tab-focused");
          $(".navTopTabPane").eq(index).css("visibility","visible");
          $("#tabContainer").css("transform","translateX("+arr[index]+") translateZ(0px);");
          document.getElementById("tabContainer").style="transform: translateX("+arr[index]+") translateZ(0px);"
        }
        // global so we can remove them later
        function createGeoZone(type) {
          geoToEditId=null;zoneCoordonnates=[];
          map.removeInteraction(draw);
          map.removeInteraction(snap);
          draw = new Draw({
              source: vectorSource_geo,
              type: type
          });
          map.addInteraction(draw);
          snap = new Snap({source: vectorSource_geo});
          map.addInteraction(snap);
          draw.on('drawend', function(evt){
              createdZone = evt.feature;
              coords = createdZone.getGeometry().getCoordinates()[0];
              coords.forEach(function(data,index){
                  var lngLat=transform(data, 'EPSG:3857', 'EPSG:4326');
                   zoneCoordonnates.push(lngLat); });
              dtype=createdZone.getGeometry().getType();
              radius=0;
              if (dtype=="Circle") {
                  radius=createdZone.getGeometry().getRadius();
              }
              map.removeInteraction(draw);
              map.removeInteraction(snap);
              //displayGeoOverlay(coords);
        
          });
        }
        function afficheZoneGEO (coords,id,type,radius,active,visibility) {
          if (!coords) {return;}
          var geofence = new Feature();
          geofence.setId(id);
          if (type=="Circle") {
              geofence = new Feature(new Circle(coords, radius));
          }else{
              var polygonGEO = new Polygon([coords]);
              geofence.setGeometry(polygonGEO);
          }
          if (visibility == 0) {
                var geoStyle = new Style({
                    stroke: new Stroke({
                        color: 'rgba(127, 127, 127,0.8)'
                    })
                });
                geofence.setStyle(geoStyle);
            } else {
                var geoStyle = new Style({
                    stroke: new Stroke({
                        color: '#00AAFF',
                        width: 2
                    }),
                    fill: new Fill({
                        color: 'rgba(255, 255, 255,0.2)'
                    })
                });
                geofence.setStyle(geoStyle);
            }
          vectorSource_geo.addFeature(geofence);
        }
        function saveZone () {
          var vitesse=$("#createdGeoLimitVitesse").val().trim();
          var name=$("#createdGeoName").val().trim(),limitVitess=vitesse!=""?parseInt(vitesse):0;
          if (!name) { WarningToastr("Vous devez entrer un nom pour la zone");return; }
          var body = {coords:coords,type:dtype,radius:radius,name:name,speed:limitVitess,idZone:geoToEditId,coordinates:zoneCoordonnates};
          console.log(body);
          fetch("saveGeo",{method:"POST",body: JSON.stringify(body), headers: {"Content-type": "application/json; charset=UTF-8"}}).then(function(response) { return response.text() }).then(function(data) {
              var idSavedZone=data.replaceAll("\"","").trim();
              afficheZoneGEO(coords,idSavedZone,dtype,radius);
              if (!geoToEditId) { numeroGeo++; addGeoLine(idSavedZone,name,dtype,limitVitess);SuccessToastr("Zone Ajoutée avec succès!"); }else{
                  $("#name"+geoToEditId).text(name);SuccessToastr("Zone Modifiée avec succès!");}
              console.log(idSavedZone);
              hideGeoModal();
          }); 
        }
        function getPoi() {
          Fetch.post("/getPoi",{token:token}).then(function(datas) {
              numeroPoi=0;
              listPoi.value=datas;
              datas.forEach(function(data) {
                  var coords=data.coords,icon=data.icon,name=data.name,id=data._id;
                  placePoi(coords,icon,name,id);
              });
          }); 
        }
        function getGeo() {
            Fetch.post("/getGeo",{token:token}).then(function(datas) {
            listGeo.value=datas;
              numeroGeo=0;
              datas.forEach(function(data) {
                  numeroGeo++;
                  var coords=data.coords,type=data.type,name=data.name,id=data._id,radius=data.radius,speed=data.speed;
                  afficheZoneGEO(coords,id,type,radius);
                  addGeoLine(id,name,type,speed);
              });
             // $('.geofencesTabs').DataTable( { "searching": false, "bPaginate": false,"info": false,"scrollY": 'auto' });
          }); 
        }
        function unSelectAllDevicesGeo () {
          $(".listeGeoDevices").find(".devicelineCheckbox").removeClass("ivu-checkbox-checked");
          $(".listeGeoDevices").find("input").prop('checked', false);
        }
        function getGeoById (id) {
          var body = {id:id};
          fetch("getGeoById",{method:"POST",body: JSON.stringify(body), headers: {"Content-type": "application/json; charset=UTF-8"}}).then(function(response) { return response.json() }).then(function(datas) {
              unSelectAllDevicesGeo();
             var data =datas[0];
             var imeis=data.imeis;
             if (!imeis) {return;}
             var keys=Object.keys(imeis);
             keys.forEach(function(imei){
                 var code =imeis[imei].code;
                 var el=$("#deviceGeoRow"+imei);
                 var inputIn = el.find(".inCheckBox");
                 var inputOut =el.find(".outCheckBox");
                 if (code==3) { inputIn.prop('checked', true);inputOut.prop('checked', true);el.find(".devicelineCheckbox").addClass("ivu-checkbox-checked") }else if (code==1) {
                  inputIn.prop('checked', true); el.find(".inCheckBoxSelect").addClass("ivu-checkbox-checked"); }else if (code==2) { 
                      inputOut.prop('checked', true);el.find(".outCheckBoxSelect").addClass("ivu-checkbox-checked");}
             })
          }); 
        }
        function placePoi (coords,icon,name,id) {
          numeroPoi++;
          var geometry=new Point(coords);
          var iconStyle1 = new Style({
              image: new Icon( /** @type {olx.style.IconOptions} */ ({
                  anchor: [0.5, 46],
                  anchorXUnits: 'fraction',
                  anchorYUnits: 'pixels',
                  src: icon,
                  scale:0.7
              }))
          });
          var feature = new Feature({
              name: 'poi'
          });
          feature.setId(id);
          feature.setGeometry(geometry);
          feature.setStyle(iconStyle1);
          vectorSource_poi.addFeature(feature);
          addPoiLine(id,name,icon);
        }
        function addPoiLine(id,name,icon) {
            $('.poiTbody').append('<tr style="cursor:pointer;" class="ivu-table-row trPoi"><td class="ivu-table-column-MHPhEn ivu-table-column-center"><div class="ivu-table-cell"><span>'+numeroPoi+'</span> </div></td> <td class="ivu-table-column-mycBEc"><div class="ivu-table-cell"> <img style="width: 30px;" src="'+icon+'" alt="'+name+'"> </div></td><td class="ivu-table-column-gXCgE6"><div class="ivu-table-cell"><span>'+name+'</span> </div></td> <td class="ivu-table-column-O0F2R9 ivu-table-column-center"><div class="ivu-table-cell"><div><button type="button" class="ivu-btn ivu-btn-warning ivu-btn-small removePoi" style="margin-right: 5px;">Supprimer</button><input class="poiId" type="hidden" value="'+id+'"></div></div></td></tr>');
            $(".poiRtTbody").append('<tr style="cursor:pointer;" class="ivu-table-row trPoi"><td class="ivu-table-column-C3b7OI ivu-table-column-center"><input class="poiId" type="hidden" value="'+id+'"><div class="ivu-table-cell"><span>'+numeroPoi+'</span> </div></td><td class="ivu-table-column-AymgBY"><div class="ivu-table-cell"><img style="width: 20px;" src="'+icon+'" alt="'+name+'"></div></td><td class="ivu-table-column-8OxHmT"><div class="ivu-table-cell"><span>'+name+'</span></div></td></tr>');
        }
        function addGeoLine (id,name,type,limitVitesse) {
        $(".listeGeofences").append('<tr onclick="setSelectedIdGeo(\''+id+'\')" id="geo'+id+'" class="ivu-table-row"><td class="ivu-table-column-3VZfks ivu-table-column-center"><input class="geoLimitSpeed" type="hidden" value="'+limitVitesse+'"><div class="ivu-table-cell"><input class="geoId" type="hidden" value="'+id+'"><span>'+numeroGeo+'</span> </div></td><td class="ivu-table-column-7cNesm"><div class="ivu-table-cell ivu-table-cell-with-selection"> <label class="ivu-checkbox-wrapper ivu-checkbox-wrapper-checked ivu-checkbox-default"><span class="ivu-checkbox checkBoxSpan" onclick="selectGeoLine(this)"><span class="ivu-checkbox-inner"></span> <input type="checkbox" class="ivu-checkbox-input geolineCheckbox"></span> </label> </div></td><td class="ivu-table-column-cEU5MP"><div class="ivu-table-cell"> <span id="name'+id+'">'+name+'</span> </div></td><td class="ivu-table-column-vUXTXK"><div class="ivu-table-cell"> <span>'+type+'</span> </div></td><td class="ivu-table-column-Gf1gJu ivu-table-column-center"><div class="ivu-table-cell" style="min-width:180px"> <div><button type="button" class="ivu-btn ivu-btn-success ivu-btn-small" onclick="showGeoModal(1);" style="margin-right: 5px;"> <span><i class="ivu-icon ivu-icon- fas fa-file" style="color: rgb(255, 255, 255);"></i>Afficher</span></button><button type="button" class="ivu-btn ivu-btn-primary ivu-btn-small" style="margin-right: 5px;" onclick="editGeoZone(\''+id+'\',\''+name+'\',\''+limitVitesse+'\')"> <span><i class="ivu-icon ivu-icon- fas fa-file" style="color: rgb(255, 255, 255);"></i> Modifier</span></button></div> </div></td></tr>');
        }
        
        function setSelectedIdGeo (id) {
          selectedIdGeo=id;
          $(".listeGeofences").find("td").css("background","none");
          $("#geo"+id).find("td").css("background-color","#c9eafc");
          getGeoById(id);
        }
        
        function selectGeoLine (el) {
         var input= $(el).toggleClass("ivu-checkbox-checked").find(".geolineCheckbox");
         var checked=input.is(':checked');
         console.log(countSelectedGeoLine()+" "+numeroGeo);
         if(checked){ if (countSelectedGeoLine()==numeroGeo) {var input=$(".selectAllGeoLineLable").addClass("ivu-checkbox-checked").find("input");input.prop('checked', true);} }else{
             var input=$(".selectAllGeoLineLable").removeClass("ivu-checkbox-checked").find("input");input.prop('checked', false);}
        }
        function selectDevicesLines (el) {
          var input= $(el).toggleClass("ivu-checkbox-checked").find(".devicelineCheckbox");
          // var checked=input.is(':checked');
          // console.log(countSelectedGeoLine()+" "+numeroGeo);
          // if(!checked){ var input=$(".selectAllGeoLineLable").removeClass("ivu-checkbox-checked").find("input");input.prop('checked', false);}
        }
        function assocGeoDevice (imei) {
          if (!selectedIdGeo) {WarningToastr("Vous devez sélectionner une zone !"); return;}
            var el= $("#deviceGeoRow"+imei);
            var inChecked=el.find('.inCheckBox').is(':checked');
            var outChecked=el.find('.outCheckBox').is(':checked');
            var geoLimitSpeed=el.find('.geoLimitSpeed').val();
            var code=0;
            if (inChecked && outChecked) {code=3}else if (inChecked) { code=1; }else if (outChecked) {code=2}
            imeiGeoAssoc(selectedIdGeo,imei,code,geoLimitSpeed,1);
            console.log("code:"+code);
        }
        function toggleAssocGeoAllDevice (code) {
          if (!selectedIdGeo) {WarningToastr("Vous devez sélectionner une zone !"); return;}
          var el1= $("#geo"+selectedIdGeo);
          var geoLimitSpeed=el1.find('.geoLimitSpeed').val();
          Object.keys(devices).forEach(function(imei){
              imeiGeoAssoc(selectedIdGeo,imei,code,geoLimitSpeed,0);
              var el= $("#deviceGeoRow"+imei);
              if (code==3) {
                  var input= el.find(".devicelineCheckbox").addClass("ivu-checkbox-checked").find(".geoCheckBox");
                  input.prop('checked', true);
              }else{
                  var input= el.find(".devicelineCheckbox").removeClass("ivu-checkbox-checked").find(".geoCheckBox");
                  input.prop('checked', false); 
              }
          });
          SuccessToastr("Opération lancé avec succès.");
        }
        function selectDeviceLine (el) {
          var input= $(el).toggleClass("ivu-checkbox-checked").find(".geoCheckBox");
          var checked=input.is(':checked');
          if (checked) { input.prop('checked', false); }else{input.prop('checked', true);}
        }
        function countSelectedGeoLine () {
          var trs=$(".listeGeofences").find("tr");
          var count=0;
          trs.each(function(index,element){
              var checked= $(element).find(".geolineCheckbox").is(':checked');
              if (checked) { count++; }
          });
          return count;
        }
        function selectAllGeoLine () {
          var input=$(".selectAllGeoLineLable").toggleClass("ivu-checkbox-checked").find("input");
          var trs=$(".listeGeofences").find("tr")
          if (input.is(':checked')) {
              trs.each(function(index,element){
                  var trInput=$(element).find(".geolineCheckbox");
                  if (!trInput.is(':checked')) {
                      trInput.prop('checked', true);
                      $(element).find(".checkBoxSpan").addClass("ivu-checkbox-checked"); 
                  }
                  
              });
          }else{
              trs.each(function(index,element){
                  var trInput=$(element).find(".geolineCheckbox");
                  if (trInput.is(':checked')) {
                      trInput.prop('checked', false);
                      $(element).find(".checkBoxSpan").removeClass("ivu-checkbox-checked");
                  }
                 
              });
          }
        }
        function followPoi(id) {
          var feature = vectorSource_poi.getFeatureById(id);
          if (feature) {
              var coordinates = feature.getGeometry().getCoordinates();
              map.getView().setCenter(coordinates); map.getView().setZoom(17);   
          }
          $("#POIVehicleList_"+id).toggleClass("d-none");
        }
        function removePoi (id) {
          var data = {id:id};
          fetch("removePoi",{method:"POST",body: JSON.stringify(data), headers: {"Content-type": "application/json; charset=UTF-8"}}).then(function(response) { return response.text() }).then(function(data) {
            SuccessToastr("Le Poi a bien été supprimé!");
              
          });
        }
        function imeiGeoAssoc (id,imei,code,speed,origin) {
          var data = {id:id,imei:imei,code:code,speed:speed};
          fetch("assocGeo",{method:"POST",body: JSON.stringify(data), headers: {"Content-type": "application/json; charset=UTF-8"}}).then(function(response) { return response.text() }).then(function(data) {
              console.log(data);
              if (origin==1) {
                SuccessToastr("Opération réussi!");
              }
          });
        }
        function removeGeo () {
          var trs=$(".listeGeofences").find("tr");
          var deleted=false;
          trs.each(function(index,element){
              var checked= $(element).find(".geolineCheckbox").is(':checked');
              if (checked) {
                  var id = $(element).find(".geoId").val();
                  var data = {id:id};
                  fetch("removeGeo",{method:"POST",body: JSON.stringify(data), headers: {"Content-type": "application/json; charset=UTF-8"}}).then(function(response) { return response.text() }).then(function(data) {
                      console.log(data);
                      $(element).remove();
                      deleted=true;
                  });
               }
          });
         
        }
        function changeGeoModalValues(name,limitVitesse,indice) {
          $("#createdGeoName").val(name);
          $("#createdGeoLimitVitesse").val(limitVitesse);
         if (indice==1) {
             $(".geoModalFooter").hide();
         } else if (indice==2){ $(".geoModalType").hide(); $("#saveZoneBtn").text("Modifier") }else{
          $(".geoModalType").show();$(".geoModalFooter").show();
         }
        }
        function editGeoZone(id,name,limitVitesse) {
          showGeoModal(0);
          geoToEditId=id;
          changeGeoModalValues(name,limitVitesse,2);
          modifyGeo= new Modify({source: vectorSource_geo});
          map.addInteraction(modifyGeo);
          zoomSelectedGeo(id);
          modifyGeo.on("modifyend",function(evt) {
              var feature =getFeatureGeo(id);
              coords=feature.getGeometry().getCoordinates()[0];
              //map.removeInteraction(modifyGeo);
            //  evt.features.forEach(function(feature,index){coords=feature.getGeometry().getCoordinates();
          })
        }
        var popup_immat=ref(""),popup_driver=ref(""),popup_statut=ref(""),
        popup_last_updated=ref(""),popup_utc=ref(""),popup_adresse=ref(""),popup_speed=ref(0),popup_fuel=ref(0),popup_distance=ref(0),popup_battery_ext=ref(0),popup_precision=ref(0),popup_signalGsm=ref(0),popup_status=ref(0),popup_status_color=ref(null),popup_blocked=ref(0);
        function followMarker(imei) {
            selectedImei=imei;
            $(popup.element).find(".speed-no").text(232);
            var feature = getFeature(imei);
            $(".DeviceName").text(feature.get("driver"));
            // if (!map.getOverlayById("popup")) { map.addOverlay(popup); }
            popup.set('imei', feature.getId());
            // markerPopup.style.display = "block";
            setPopupContent(feature);
            var coordinates = feature.getGeometry().getCoordinates();
            popup.setPosition(coordinates);
            $(popup.element).show();
            map.getView().setCenter(coordinates);
               
        }
        function followGeo(id) {
            zoomSelectedGeo(id);
            $("#TerritoryVehicleList_"+id).toggleClass("d-none");
        }
        function zoomSelectedGeo(id) {
          map.getView().fit(getFeatureGeo(id).getGeometry().getExtent(), map.getSize());
        }
        function savePoi () {
          var coordsPoi=iconFeature.getGeometry().getCoordinates(),name=getNameInput();
          if (!coordsPoi||!name.trim()) { WarningToastr("Vous devez créer un point avant de l'enregistré!"); return; }
          var data = {coords:coordsPoi,icon:iconPoi,name:name};
          $("#poiname").val("");
          fetch("savePoi",{method:"POST",body: JSON.stringify(data), headers: {"Content-type": "application/json; charset=UTF-8"}}).then(function(response) { return response.text() }).then(function(data) {
            SuccessToastr("Le Poi a bien été enregistrer!");
              numeroPoi++;
              addPoiLine(data,name,iconPoi);
          });
        }
        // =====================================================================================
        
          
        
        function createMap(target) {
            var mapDynamic = new Map({controls: defaultControls({ zoom : false }).extend([
                    new FullScreen()]),
                    layers: layers,
                    // Improve user experience by loading tiles while dragging/zooming. Will make
                    // zooming choppy on mobile or slow devices.
                    loadTilesWhileInteracting: true,
                    target:target,
                    view: olview
            }); 
            setTimeout(() => {
                mapDynamic.updateSize();
            }, 700);
            return mapDynamic;   
        }
        var startButton = document.getElementById('animateMaker');
        var stopButton = document.getElementById('stop_animateMaker');
        var pause_button = document.getElementById('pauseAniamtion');
        var continue_button = document.getElementById('continueAniamtion');
        // startButton.addEventListener('click', ()=> {
        // startAnimation(0);
        // }, false);
        // continue_button.addEventListener('click', continueAnimation);
        // pause_button.addEventListener('click', pauseAnimation);
        
        var speedInput = document.getElementById('speed');
        $(".Fast").click(function(ev) {
          var speed = speedInput.value;
          speed++;
          if (speed>10) {speed=10;}
          speedInput.value=speed;
        });
        $(".SLow").click(function(ev) {
          var speed = speedInput.value;
          speed--;
          if (speed<1) {speed=1;}
          speedInput.value=speed;
        });
        
    function initMap() {
        map = createMap("gMapView");
        map.addLayer(vectorHistory);
        map.addLayer(vectorLayerGeo);
        map.addLayer(poiLayer);
        map.addLayer(vectorLayer);
        map.addOverlay(popup);
        popup.set("type","popup");
        $(popup.element).hide();
        map.on('click', function(evt) {
          var pixel = map.getEventPixel(evt.originalEvent);
          var feature = map.forEachFeatureAtPixel(pixel,
              function(feature) {
                  return feature;
              });
          if (feature) {
              if (feature.get('type') == 'marker') {
                  followMarker(feature.getId());
              }else if (feature.get('type') == 'poi') {
                 
              }
              console.log(feature.get('type')); 
          }else{
              if (createPoiEnabled == true) {
                  if (!getNameInput().trim()) {
                    WarningToastr("Vous devez entré un nom pour le POI");return;
                  }
                  var geometry=new Point(evt.coordinate);
                  var iconStyle1 = new Style({
                         image: new Icon( /** @type {olx.style.IconOptions} */ ({
                             anchor: [0.5, 46],
                             anchorXUnits: 'fraction',
                             anchorYUnits: 'pixels',
                             src: iconPoi,
                             scale:0.7
                         }))
                     });
                  idPoi++;
                  if (!iconFeature) {
                      iconFeature = new Feature({
                         id: idPoi,
                         name: 'newPoint'
                     });
                     iconFeature.setGeometry(geometry);
                  
                     iconFeature.setStyle(iconStyle1);
                     vectorSource_poi.addFeature(iconFeature);
                     /**/
                     var collection = new Collection([iconFeature]);
                     var drag = new Translate({
                         features: collection
                     });
                     map.addInteraction(drag);
                     iconFeature.on('change', function() {
                     }, iconFeature);
                     /**/
                  }else{
                      iconFeature.setGeometry(geometry);
                      iconFeature.setStyle(iconStyle1);
                  }
                 
                  /**/
              }
          }
        
        });
        }
        function showModalTrajet(data) {
            $("#CommonImportSummaryModal").modal("show");
            if (data[2]>0) {
            }
            getHistoryModal(moment(data[0]).format("YYYY-MM-DD HH:mm:ss"),moment(data[1]).format("YYYY-MM-DD HH:mm:ss"));
            map.updateSize();
            console.log(data);
        }
          //     var mapPoi=createMap("mapPoi");
       
        //   pieChart("alarmesDash",pieData,pieDrilldown,"Alarme")
        getPoi();
        getGeo();
        $(".togglePoiRtModal").click(function(ev){
        $(".POIListDropdown").toggle();
        });
        $("#hideShowPoi").click(function(ev){
        var el=$(ev.currentTarget);
        el.toggleClass("ivu-checkbox-checked");
        if(el.hasClass("ivu-checkbox-checked")){poiLayer.setVisible(false);}else{poiLayer.setVisible(true);}
        });
        $(".tabCreateGeoBtn").click(function(ev){
        console.log("tabCreateGeo clicked");
        $(".tabSpeedGeo").css("visibility","hidden");
        $(".tabCreateGeo").css("visibility","visible");
        $(".createGeoTab").css("transform"," translateX(0%) translateZ(0px)");
        $(".geoCreateGeoTabs").toggleClass("ivu-tabs-tab-active ivu-tabs-tab-focused");
        });
        $(".tabSpeedGeoBtn").click(function(ev){
        console.log("tabSpeedGeo clicked");
        $(".tabCreateGeo").css("visibility","hidden");
        $(".tabSpeedGeo").css("visibility","visible");
        $(".createGeoTab").css("transform"," translateX(-100%) translateZ(0px)");
        $(".geoCreateGeoTabs").toggleClass("ivu-tabs-tab-active ivu-tabs-tab-focused");
        });
        
        //       stockChart(fuelData,"rtFuelChart");
        // chartBar("distanceChart",data,"Distance","Km");
        // chartBar("vitesseMaxChart",data,"Vitesse Max","Km/h");
        $("#pieChart").css("height",($(".UserCard").height()));
        $("#pieChart").css("width",($(".UserCard").height()));
        $(".cicleChartTotal").css("width",($(".UserCard").height()));
        $(".cicleChartTotal").show();
        
      
        function setListDatatable() {
            if($.fn.dataTable.isDataTable('#rapportActivityTable')){
                dataTable.destroy();
            }
        //     $('#rapportActivityTable').DataTable({
        //     "language": {
        //         "processing": '<div class="sk-three-bounce" style="padding-top: 0px !important;"><div class="sk-child sk-bounce1"></div><div class="sk-child sk-bounce2"></div><div class="sk-child sk-bounce3"></div></div>', //add a loading image,simply putting <img src="loader.gif" /> tag.
        //         "search": '<i class="icon-magnifier mt position-relative pull-right text-muted1"></i>',
        //         //"searchPlaceholder": "Search Fleet",
        //         "url": "https://cdn.datatables.net/plug-ins/1.10.21/i18n/French.json",
        //         "paginate": {
        //             "next": " ",
        //             "previous": " "
        //         }
        //     },
        // });
            dataTable = $("#rapportActivityTable").DataTable({
                "sDom": 'r<"row"<"col-sm-12"<"pull-left"l><"dt-filter-input-custom col-sm-6 col-md-offset-6"f><"clearfix">>>t<"row view-pager"<"col-sm-12"<"text-center"ip>>>',
                "scrollX": true,
                colReorder: true,
                "searching": true,
                "autoWidth": false,
                "bDestroy": true,
                "ordering": false,
                "order": [[0,'asc']],
                "bPaginate": false,
                "bInfo": false,
                "bStateSave": true,
                // "iDisplayLength": 25,
                "bLengthChange": false,
                "language": {
                    "processing": '<div class="sk-three-bounce" style="padding-top: 0px !important;"><div class="sk-child sk-bounce1"></div><div class="sk-child sk-bounce2"></div><div class="sk-child sk-bounce3"></div></div>', //add a loading image,simply putting <img src="loader.gif" /> tag.
                    "search": '<i class="icon-magnifier mt position-relative pull-right text-muted1"></i>',
                    //"searchPlaceholder": "Search Fleet",
                    "url": "//cdn.datatables.net/plug-ins/1.10.7/i18n/French.json",
                    "paginate": {
                        "next": " ",
                        "previous": " "
                    }
                },
                // processing: true, // Here first time only processing Loader is enabled.
                // serverSide: true,
                // ajax: {
                //     type: "POST",
                //     url: _reportUrl,
                //     dataType: "JSON"
                // },
                // columns: _reportColumns,
                "drawCallback": function (settings) {
                    // dataTable.ajax.url(_reportUrl);

                    // $(".dataTables_paginate").addClass('dataTables_paginate_custom');
                    // $('.dataTables_scrollBody').css('max-height', bodyHeight);

                    // // UpdateTabCountDetail(JSON.parse(settings.json.customData));

                    // //remove count tab loader 
                    // $("#Dashboard_VehicleDetail_Vehicle_Tab_Div").find("#DashboardVehiclePanel").removeClass('tab-count-opacity');
                    // $("#Dashboard_VehicleDetail_Vehicle_Tab_Div").find(".tab-count-bounce").hide();
                    
                }
            });  
    }
        //  start Mounted func ===========================================================================
        onMounted(() => {
            // localStorage.kisko="12345";
            if (!token) {
                setTimeout(() => {
                    $("#SessionExpire").modal("show");
                }, 500);
                return
            }
            // // store.commit('increment')
            // // console.log(store.state.count)
            // markerPopup=document.getElementById('popup');
            // contentPopup = document.getElementById('popup-content');
            // document.getElementById('btnLiveTrackingZoomOut').onclick = function () {
            //   const view = map.getView();
            //   const zoom = view.getZoom();
            //   view.setZoom(zoom - 1);
            // };
            // document.getElementById('btnLiveTrackingZoomIn').onclick = function () {
            //   const view = map.getView();
            //   const zoom = view.getZoom();
            //   view.setZoom(zoom + 1);
            // };
            //  popup = new Overlay({
            //     id:"popup",
            //     element: markerPopup,
            //     positioning: 'top-center',
            //     stopEvent: true,
            //     offset: [-36, -20],
            //     anchorXUnits: 'fraction',
            //     anchorYUnits: 'fraction',
            //     scale: 0.5,
            //     autoPan: true,
            //     autoPanAnimation: {
            //         duration: 0
            //     }
            //   });
          
            // initMap();
            Fetch.post("/",{token:token}).then((data)=>{ 
                user.value=data.data;
                var userObj=data.data.user;
                userObj.name.entreprise=userObj.en;
                var owner=data.data.owner;
                userObj.name.owner=owner;
                infoUser.value=userObj.name;
                var allDevices={};
                user.value.devices.forEach((device)=>{
                    imeis.push(device.imei);
                    allDevices[device.imei]=device;
                })
                devices.value=allDevices;
                
             });
            console.log("onMounted "+new Date().getSeconds());

            // listCommands=$(".CmdList");
            // listCommands.find(".ivu-collapse-header").click(function(ev){
            //     var parent=$(ev.currentTarget).parent(".ivu-collapse-item");
            //     var parentIsActive=parent.hasClass("ivu-collapse-item-active");
            //     listCommands.find(".ivu-collapse-item").removeClass("ivu-collapse-item-active");
            //     listCommands.find(".ivu-collapse-content").hide();
            //     if(!parentIsActive){
            //       parent.addClass("ivu-collapse-item-active");
            //         parent.find(".ivu-collapse-content").toggle();
            //     }
            // });

            map = createMap("CommonImportSummaryDiv");
            map.addLayer(vectorHistory);
            // fin onMonted ========================================================================================================================
        })

        // var devicesLength=computed(()=> {
        //   return user.value.devices? user.value.devices.length:0;} );
          function listItemToggle(el){
             $('.selected_device').val($(el).text());
              $(".listDevices").toggle(); 
            // $(".device_item").click(function() {
               
            // });
          }
        $(document).ready(function() {
          $(".PCLoading" ).hide();
          $('.dropdown-menu.dropdown-filter').on('click', function (e) {
                e.stopPropagation();
            });
            $("#ReportTypeID").change(function () {
                var ReportTypeID=$("#ReportTypeID").val();
                if (ReportTypeID==4) {
                    $("#ReportVehicleID").attr("disabled","true");
                }else{
                    $("#ReportVehicleID").removeAttr("disabled");
                }
            });
            $("#ReportransportAlertTypeID").change(function () {
            if ($("#ReportTypeID").val() == '100') {
                $("#ReportVehicleID").select2("val", "");
                $("#ReportSchoolID").select2("val", "");
                $("#ReportParentID").select2("val", "");
                $("#ReportStudentID").select2("val", "");

                var val = parseInt($("#ReportransportAlertTypeID").val());
                $("#ReportVehicleDiv").hide();
                $("#ReportSchoolDiv").hide();
                $("#ReportParentDiv").hide();
                $("#ReportStudentDiv").hide();

                switch (val) {
                    case 1: $("#ReportVehicleDiv").show(); break;
                    case 2:
                        $("#ReportSchoolDiv").show()
                        GetReportSchoolList();
                        break;
                    case 3: $("#ReportParentDiv").show(); break;
                    case 4:
                        $("#ReportStudentDiv").show();
                        GetReportStudentList();
                        break;
                    default:
                }
            }
        });

        $(".btn-switch").click(function () {
            $(".btn-switch").removeClass('active');
            $(this).addClass('active');
            var tabId = $(this).attr('data-tab');

            $(".tab-custom").removeClass('display-block');
            $(".tab-custom").addClass('display-none');

            $("#" + tabId).removeClass('display-none');
            $("#" + tabId).addClass('display-block');

            if (tabId == "graphTab") {
                $("#ReportLoadType").val(2);
                GetReportDetails();
                GetReportTitle();
            } else {
                $("#ReportLoadType").val(1);
                GetReportDetails();
                GetReportTitle();
            }
        });
        localeOptions.format = 'MM/DD/YYYY HH:mm';
        $("#daterange-picker-report").daterangepicker({
                timePicker24Hour: true,
                timePicker: true,
                //timePickerSeconds: true,
                timePickerIncrement: 1,
                // opens: "left",
                // drops: "up",
                locale: localeOptions,
                ranges: {
                    'Today': [moment().startOf('day'), moment().endOf('day')],
                    'Yesterday': [moment().subtract(1, 'days').startOf('day'), moment().subtract(1, 'days').endOf('day')],
                },
                startDate: new Date($('#TrackingHistoryFromDate').val()),
                endDate: new Date($('#TrackingHistoryToDate').val())
            }, function (start, end) {
                startTime=start.format('YYYY-MM-DD HH:mm:ss');endTime=end.format('YYYY-MM-DD HH:mm')+":59";
                $('#daterange-picker-report span').html(start.format('DD/MM/YYYY HH:mm') + ' - ' + end.format('DD/MM/YYYY HH:mm'));
            });
        $(".select2").select2({
            theme: "bootstrap",
            allowClear: true,
        });
        $("#CommonImportSummaryDiv").height($("body").height()-100);
        $(".CommonImportSummaryDivFooter").hide();
            // history custum end===============================================================================================
        });
//  rapport FUNCS ============================================================================================================
     
    function ManageReportControls() {
        $('#FromRangeValueDiv').hide();
        $('#ToRangeValueDiv').hide();
        $("#TxtFilterNumberDiv").hide();
        $("#ReportAlertTypeDiv").hide();
        $("#ReportSchoolDiv").hide();
        $("#ReportStudentDiv").hide();
        $("#ReportTripDiv").hide();
        $("#ReportVsTimeDiv").hide();
        $("#ReportTripOnDiv").hide();
        $("#ReportDailyActivityDiv").hide();
        $("#ReportTransAlertTypeDiv").hide();
        $("#ReportClassDiv").hide();
        $("#ReportTransportAlertTypeDiv").hide();
        $("#ReportParentDiv").hide();
        $("#ReportIncidentTypeDiv").hide();
        $("#ReportTripTypeDiv").hide();
        $("#TempratureSensorReport").hide();
        $("#ReportVehicleTypeDiv").hide();

        $("#ReportDriverDiv").show();
        $("#ReportGroupDiv").show();     
        $("#ReportDateRangeDiv").show();
        $("#ReportVehicleDiv").show();
        $("#TxtFilterNumber").val("");
        if ($("#ReportTypeID").val() == '30') {
            $("#ReportAlertTypeDiv").show();
        }
        else if ($("#ReportTypeID").val() == '19' || $("#ReportTypeID").val() == '25' || $("#ReportTypeID").val() == '26') {
            $("#TxtFilterNumberDiv").show();
            $("#TxtFilterNumber").attr("placeholder", "Duration (default : 1 min)");
        }
        else if ($("#ReportTypeID").val() == '42') {
            $("#TxtFilterNumberDiv").show();
            $("#TxtFilterNumber").attr("placeholder", "Refuel (default : 1 lit)");
        }
        else if ($("#ReportTypeID").val() == '39') { // Reports Vs Time Report
            $("#ReportVsTimeDiv").show();
        }
        else if ($("#ReportTypeID").val() == '55') {
            $("#ReportSchoolDiv").show();
            $("#ReportDriverDiv").hide();
            $("#ReportDateRangeDiv").hide();
            $("#ReportVehicleDiv").hide();
        }
        else if ($("#ReportTypeID").val() == '45') {//StudentSwipeReport
            $("#ReportSchoolDiv").show();
            $("#ReportStudentDiv").show();
            $("#ReportClassDiv").show();

            $("#ReportDriverDiv").hide();
            $("#ReportVehicleDiv").hide();
        }
        else if ($("#ReportTypeID").val() == '46') { //StudentTripReport
            //GetVehiclesByTripOn($("#ReportTripOn").val());
            $("#ReportTripOnDiv").show();
            $("#ReportTripDiv").show();
            $("#ReportVehicleDiv").show();
            $("#ReportDateRangeDiv").hide();
            $("#ReportDriverDiv").hide();
        }
        else if ($("#ReportTypeID").val() == '47') { //BusUtilizationReport
            $("#ReportDateRangeDiv").hide();
            $("#ReportDriverDiv").hide();
            $("#ReportVehicleDiv").hide();
        }
        else if ($("#ReportTypeID").val() == '49') { //StudentAbsentReport
            $("#ReportDateRangeDiv").show();
            $("#ReportSchoolDiv").show();
            $("#ReportDriverDiv").hide();
            $("#ReportVehicleDiv").hide();
        }
        else if ($("#ReportTypeID").val() == '50') {
            $("#ReportDateRangeDiv").show();
            $("#ReportDriverDiv").show();
            $("#ReportVehicleDiv").show();
        }
        else if ($("#ReportTypeID").val() == '97') { // Daily Activity Report
            $("#ReportDailyActivityDiv").show();
        }
        else if ($("#ReportTypeID").val() == '99') {
            $("#ReportDateRangeDiv").show();
            $("#ReportVehicleDiv").show();
            $("#ReportIncidentTypeDiv").show();
        }
        else if ($("#ReportTypeID").val() == '100') {
            $("#ReportVehicleDiv").hide();
            $("#ReportTransAlertTypeDiv").show();
            $("#ReportDriverDiv").hide();

            $("#ReportVehicleID").select2("val", "");
            $("#ReportTransportAlertTypeDiv").show();
            $("#ReportransportAlertTypeID").val(0);
        }
        else if ($("#ReportTypeID").val() == '110') {
            $("#ReportGroupDiv").hide();
            $("#ReportDateRangeDiv").hide();
            $("#ReportDriverDiv").hide();
            $("#ReportTripTypeDiv").show();
        }
        else if ($("#ReportTypeID").val() == '111') {
            $("#ReportGroupDiv").hide();
            $("#ReportSchoolDiv").show();
            $("#ReportDateRangeDiv").hide();
            $("#ReportDriverDiv").hide();
            $("#ReportVehicleDiv").hide();
        } else if ($("#ReportTypeID").val() == '112') {
            $("#ReportGroupDiv").hide();
            $("#ReportStudentDiv").show();
            $("#ReportViolationTypeDiv").show();
            $("#ReportSchoolDiv").show();
            $("#ReportDateRangeDiv").show();
            $("#ReportDriverDiv").hide();
            $("#ReportVehicleDiv").hide();
        } else if ($('#ReportTypeID').val() == '114')
        {
            $("#ReportGroupDiv").hide();
            $('#FromRangeValueDiv').show();
            $('#ToRangeValueDiv').show();
            $('#TxtFilterNumberDiv').show();
            $("#TempratureSensorReport").show();
        }else if ($("#ReportTypeID").val() == '34') {
            $("#ReportVehicleDiv").hide();
        }else if ($("#ReportTypeID").val() == '33') {
            $("#ReportVehicleDiv").hide();
        }else if ($("#ReportTypeID").val() == '229') {
            $("#ReportVehicleDiv").hide();
        }
        
        var reportType = $("#ReportTypeID").val();
        if (reportType == '19' ||
            reportType == '20' ||
            reportType == '21' ||
            reportType == '22' ||
            reportType == '25' ||
            reportType == '26' ||
            reportType == '28' ||
            reportType == '29' ||
            reportType == '30' ||
            reportType == '31' ||
            reportType == '109') {
            $("#ReportVehicleTypeDiv").show();
        }
    }
    function printAsPdf(){
        if($.fn.dataTable.isDataTable('#rapportActivityTable')){
            dataTable.destroy();
        }
        var device=devices.value[selectedImeiHistory];
        document.title = 'rapport_'+ReportTypeName+'_'+device.immat+'_du_'+startTime.replaceAll(" ","_")+'_au_'+endTime.replaceAll(" ","_");
        printJS({ printable: 'ReportDetailDiv',scanStyles: false,onPrintDialogClose: closedWindow, type: 'html',header: infoUser.value["entreprise"]=="gts"?"GTS AFRIQUE RAPPORT":"SUNUTRACK RAPPORT",css:["/css/rapportActiviteStyle.css","/css/bootstrap.css"]})
    }
    function closedWindow() {
        setListDatatable();
    }
    var json_data=ref([]);
            var kilo_fields= {
                  "Date": "date",
                  "Debut": "start",
                  "Fin": "finActivity",
                  "Mouvement": "active",
                  "Statique": "static",
                  "Inactive": "stop",
                  "Km": "distance",
                  "Vit.Max": "maxSpeed",
                  "Vit.Moy": "vitMoy",
                  "Adresse Départ": "adresse",
                  "Adresse arrivée": "adresse_fin",
                  };
            var flotte_fields= {
                  "Immat": "immat",
                  "Debut": "start",
                  "Fin": "finActivity",
                  "Mouvement": "active",
                  "Statique": "static",
                  "Inactive": "stop",
                  "Km": "distance",
                  "Vit.Max": "maxSpeed",
                  "Vit.Moy": "vitMoy",
                  "Adresse Départ": "adresse",
                  "Adresse arrivée": "adresse_fin",
                  };
         var activity_fields= {
                  "Etat": "status",
                  "Date": "date",
                  "Debut": "debut",
                  "Fin": "fin",
                  "Durée": "duree",
                  "Adresse Départ": "adresse",
                  "Adresse arrivée": "adresse_fin",
                  "Km": "distance",
                  "Vit.Max": "maxSpeed",
                  "Vit.Moy": "vitMoy",
                  };
          var json_fields=ref({});
function startDownload() {
    var json_data_copy=[];
    var ReportTypeID=$("#ReportTypeID").val();
    console.log(ReportTypeID,historyDataCopy.length);
    var periode=startTime.replaceAll(" ","_")+"_au_"+endTime.replaceAll(" ","_");
    var device=devices.value[selectedImeiHistory];
    if (ReportTypeID==1) {
        historyDataCopy.forEach((point)=>{
            var status=point.status==1?"Mouvement":point.status==2?"Statique":"Inactive";
            var date=moment(point.date).format("DD/MM/YYYY");
            var debut=moment(point.date).format("HH:mm:ss");
            var fin=moment(point.fin).format("HH:mm:ss");
            var duree=point.duree;
            var adresse=point.adresse.substring(0,60);
            var adresse_fin=point.adresse_fin?point.adresse_fin.substring(0,60):"---";
            var distance=point.distance;
            var maxSpeed=point.status==1?point.maxSpeed:0;
            var vitMoy=point.status==1?parseInt(point.distance/(point.diff/3600)):0;
            var data={status:status,date:date,start:debut,fin:fin,duree:duree,adresse:adresse,adresse_fin:adresse_fin,distance:distance,maxSpeed:maxSpeed,vitMoy:vitMoy};
            json_data_copy.push(data);
        });
        json_data.value=json_data_copy;
        json_fields.value=activity_fields;
        filename.value="Rapport_activite_"+device["immat"]+"_du_"+periode+".xls";
    }else if (ReportTypeID==2) {
        historyDataCopy.forEach((point)=>{
            var date=moment(point.date).format("DD/MM/YYYY");
            var start=moment(point.start).format("HH:mm:ss");
            var finActivity=moment(point.finActivity).format("HH:mm:ss");
            var active=point.active;
            var statique=point.static;
            var stop=point.stop;
            var adresse=point.adresse.substring(0,60);
            var adresse_fin=point.adresse_fin.substring(0,60);
            var distance=point.distance;
            var maxSpeed=point.maxSpeed;
            var vitMoy=parseInt(point.distance/(point.mouve/3600));
            var data={date:date,start:start,finActivity:finActivity,active:active,static:statique,stop:stop,adresse:adresse,adresse_fin:adresse_fin,distance:distance,maxSpeed:maxSpeed,vitMoy:vitMoy};
            json_data_copy.push(data);
        });
        json_data.value=json_data_copy;
        json_fields.value=kilo_fields;
        filename.value="Rapport_kilometrique_"+device["immat"]+"_du_"+periode+".xls";
    }else if (ReportTypeID==4) {
        historyDataCopy.forEach((point)=>{
            var immat=point.immat;
            var start=moment(point.start).format("HH:mm:ss");
            var finActivity=moment(point.finActivity).format("HH:mm:ss");
            var active=point.active;
            var statique=point.static;
            var stop=point.stop;
            var adresse=point.adresse.substring(0,60);
            var adresse_fin=point.hasActivity?point.adresse_fin.substring(0,60):"";
            var distance=point.hasActivity?point.distance:0;
            var maxSpeed=point.hasActivity?point.maxSpeed:0;
            var vitMoy=point.hasActivity?parseInt(point.distance/(point.mouve/3600)):0;
            var data={immat:immat,start:start,finActivity:finActivity,active:active,static:statique,stop:stop,adresse:adresse,adresse_fin:adresse_fin,distance:distance,maxSpeed:maxSpeed,vitMoy:vitMoy};
            json_data_copy.push(data);
        });
        json_data.value=json_data_copy;
        json_fields.value=flotte_fields;
        filename.value="Rapport_flotte_du_"+periode+".xls";
    }
}


</script>  
<template>
<div class="history-container">
<Header :infoUser="infoUser" :page="page" />
<section id="MainContent" class="main-section-pt track-minisidebar traditional">

<input id="ReportFromDate" name="ReportFromDate" type="hidden" value="08/19/2022 00:00">
<input id="ReportToDate" name="ReportToDate" type="hidden" value="08/19/2022 23:59">
<input data-val="true" data-val-number="The field Int32 must be a number." data-val-required="The Int32 field is required." id="ReportVsTimeType" name="ReportVsTimeType" type="hidden" value="0">
<input id="ReportLoadType" name="ReportLoadType" type="hidden" value="1">
<input id="UserSubClientID" name="UserSubClientID" type="hidden" value="00000000-0000-0000-0000-000000000000">

<div class="normalheader small-header">
    <div class="hpanel printHide">
        <div class="panel-body b-b-n">
            <h2 class="with-button">
                <em class="icon-book-open header-icon"></em><span id="ReportTitleSpan">Rapports</span>
            </h2>
            <div class="pull-right">
                <download-excel
  class="btn btn-default"
  :before-generate="startDownload"
  :data="json_data"
  :fields="json_fields"
  worksheet="My Worksheet"
  :name="filename"
>
<img src="/images/icon-excel.png" data-toggle="tooltip" data-placement="bottom" title="" alt="img" data-original-title="Export to xls">
</download-excel>
                <!-- <a href="javascript:;" @click="downloadExcel()" id="btnExportReport"><img src="/images/icon-excel.png" data-toggle="tooltip" data-placement="bottom" title="" alt="img" data-original-title="Export to xls"></a>
                &nbsp;
                |
                &nbsp; -->
                <a href="javascript:;" @click="printAsPdf()" id="btnPrintReport"><img src="/images/icon-print.png" data-toggle="tooltip" data-placement="bottom" title="" alt="img" data-original-title="Print"></a>
                &nbsp;
                &nbsp; &nbsp;

                    <div class="btn-group" id="TempratureSensorReport" style="display:none">
                        <button type="button" data-toggle="dropdown" class="btn dropdown-toggle btn-primary btn-square">
                            <em class="zmdi zmdi-more-vert zmdi-hc-lg"></em>
                        </button>
                        <ul role="menu" class="dropdown-menu dropdown-menu-more pull-right">
                            <li>
                                <a href="javascript:;" id="btnExportAll"><em class="zmdi zmdi-format-valign-bottom"></em>Export All</a>
                            </li>
                        </ul>
                        &nbsp; &nbsp;
                    </div>

                <a href="#/Reports/Report" class="btn btn-default btn-md btn-square"><em class="zmdi zmdi-long-arrow-return zmdi-hc-flip-vertical zmdi-hc-lg em-icon"></em>Back</a>

                
            </div>
        </div>
    </div>
    <div class="hpanel">
        <div class="panel-body b-b-n b-color printHide">
            <div class="row">
                <div class="col-md-3">
                    <div class="form-group mb-0">
                        <div class="select2-material bg-transparent">
                            <select  class="form-control select2 select2-hidden-accessible" id="ReportTypeID">
                                <option value="1" selected>Rapport Activité</option>
                                <option value="2">Rapport Kilométrique</option>
                                <option value="3">Rapport Carburant</option>
                                <option value="4">Rapport Flotte</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="col-md-8 pl0">
                    <div class="row">
                        <div class="col-md-4 pr0 white-space-nowrap" id="ReportDateRangeDiv">
                            <div class="form-group mb-0">
                                <input id="TrackingHistoryFromDate" type="hidden" :value="moment().format('YYYY-MM-DD')+' 00:00:00'">
                                <input id="TrackingHistoryToDate" type="hidden" :value="moment().format('YYYY-MM-DD')+' 23:59:59'">
                                <button type="button" class="btn btn-default btn-dtrange btn-dtrange-material bg-transparent" id="daterange-picker-report">
                                    <span>{{moment().format('DD/MM/YYYY')+' 00:00'}} - {{moment().format('DD/MM/YYYY')+' 23:59'}}</span>
                                    <i class="fa fa-caret-down"></i>
                                </button>
                            </div>
                        </div>
                       
                        <div class="col-md-5" id="ReportVehicleDiv">
                            <div class="form-group mb-0">
                                <div class="select2-material bg-transparent">
                                    <select class="form-control select2 select2-hidden-accessible" id="ReportVehicleID" data-placeholder="Select Vehicle" tabindex="-1" aria-hidden="true">
                                        <option value=""></option>
                                        <!-- <option value="0">All</option> -->
                                        <option :value="device.imei" v-for="(device, index) in devices" :key="index">{{device.driver.prenom}} {{device.driver.nom}}({{device.immat}})</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-1">
                            <button type="button" id="btnReportSearch" @click="getHistory()" class="btn btn-primary btn-md btn-square">Rechercher</button>
                        </div>
                    </div>
                </div>
                <!-- <div class="col-md-1">
                    <div class="btn-group btn-toggle-custom-parent" style="" id="tabTableGraph">
                        <a href="javascript:;" data-tab="tableTab" class="btn btn-switch btn-toggle-custom  active"><i class="icon-list"></i></a>
                        <a href="javascript:;" data-tab="graphTab" class="btn btn-switch btn-toggle-custom"><i class="icon-graph"></i></a>
                    </div>
                </div> -->
                <div class="col-md-1 pl0 pull-right">
                    <!-- additional filters-->
                    <div class="btn-group">
                        <button type="button" data-toggle="dropdown" class="btn btn-default btn-md btn-square dropdown-toggle" aria-expanded="false">
                            <em class="icon-equalizer">&nbsp;</em>More
                        </button>
                        <div class="dropdown-menu dropdown-filter pull-right">
                            <div class="form-group" id="ReportDriverDiv">
                                <div class="select2-material bg-transparent">
                                    <select class="form-control select2 select2-hidden-accessible" id="ReportDriverID" data-placeholder="Select Driver" tabindex="-1" aria-hidden="true"><option value=""></option><option value="d8a735f6-4776-4ae5-9b0f-5448f1d25f22">Sadoon</option></select>
                                </div>
                            </div>
                            <div class="form-group" id="ReportGroupDiv">
                                <div class="select2-material bg-transparent">
                                    <select class="form-control select2 select2-hidden-accessible" id="ReportGroupID" data-placeholder="Select Group" tabindex="-1" aria-hidden="true"><option value=""></option><option value="TEST">TEST</option></select>
                                </div>
                            </div>
                            <div class="form-group" id="ReportVehicleTypeDiv" style="display: block;">
                                <div class="select2-material bg-transparent">
                                    <select class="form-control select2 select2-hidden-accessible" id="ReportVehicleTypeID" data-placeholder="Vehicle Type" tabindex="-1" aria-hidden="true"><option value=""></option><option value="None">None</option><option value="Bus">Bus</option><option value="Car">Car</option><option value="Truck">Truck</option><option value="Bike">Bike</option><option value="Trailer">Trailer</option><option value="Pickup">Pickup</option><option value="Equipment">Equipment</option><option value="Stationary">Stationary</option><option value="Forklift">Forklift</option><option value="BOAT">BOAT</option><option value="Scooter">Scooter</option><option value="SmallTruck">SmallTruck</option><option value="VAN">VAN</option></select>
                                </div>
                            </div>
                            <div class="form-group" id="ReportIncidentTypeDiv" style="display: none;">
                                <div class="select2-material bg-transparent">
                                    <select class="form-control select2 select2-hidden-accessible" id="ReportIncidentTypeID" data-placeholder="Select Incident Type" tabindex="-1" aria-hidden="true"><option value=""></option><option value="1">Ignition On</option><option value="2">Ignition OFF</option><option value="3">Idling</option><option value="4">Stopped</option><option value="5">Moved</option><option value="6">RFID</option><option value="7">Poi Enter</option><option value="8">Poi Exit</option></select>
                                </div>
                            </div>
                            <div class="form-group" id="ReportClassDiv" style="display: none;">
                                <div class="select2-material bg-transparent">
                                    <select class="form-control select2 select2-hidden-accessible" id="ReportClassID" data-placeholder="Select class" tabindex="-1" aria-hidden="true"><option value=""></option></select>
                                </div>
                            </div>
                            <div class="form-group" id="ReportTransAlertTypeDiv" style="display: none;">
                                <div class="select2-material bg-transparent">
                                    <select class="form-control select2 select2-hidden-accessible" id="ReportTransAlertTypeID" data-placeholder="Select Transport Alert Type" tabindex="-1" aria-hidden="true"><option value=""></option><option value="0">Student Swipe the Card</option><option value="4">Wrong on boarding</option><option value="5">Wrong off boarding</option><option value="6">Bus on the way for pick up</option><option value="7">Bus on the way for drop off</option><option value="8">Bus approaching pickup point</option><option value="9">Bus approaching drop off point</option><option value="10">Student picked up</option><option value="11">Student dropped off</option></select>
                                </div>
                            </div>
                            <div class="form-group" id="ReportTripTypeDiv" style="display: none;">
                                <div class="select2-material bg-transparent">
                                    <select class="form-control select2 select2-hidden-accessible" id="ReportTripTypeID" data-placeholder="Select Trip Type" tabindex="-1" aria-hidden="true"><option value=""></option><option value="1">Pickup</option><option value="2">Dropoff</option><option value="3">Both</option></select>
                                </div>
                            </div>
                            <div class="form-group" id="TxtFilterNumberDiv" style="display: block;">
                                <div class="form-group" style="margin-bottom: 0 !important;">
                                    <input type="text" id="TxtFilterNumber" class="form-control control-material" placeholder="Duration (default : 1 min)" onkeypress="return onlyNos(event,this);">
                                </div>
                            </div>
                            <div class="form-group" id="FromRangeValueDiv" style="display: none;">
                                <div class="form-group mb-0">
                                    <input id="FromRangeValue" type="number" class="form-control control-material" onclick="select();" placeholder="Enter from value" value="0">
                                </div>
                            </div>
                            <div class="form-group" id="ToRangeValueDiv" style="display: none;">
                                <div class="form-group mb-0">
                                    <input id="ToRangeValue" type="number" class="form-control control-material" onclick="select();" placeholder="Enter to value" value="0">
                                </div>
                            </div>

                            <div class="form-group mb-0 bg-transparent">
                                <div class="select2-material select2-m-material">
                                    <select class="form-control select2 multiple control-material bg-transparent select2-hidden-accessible" data-placeholder="Select days of week" id="DayOfWeekID" multiple="" name="DayOfWeekID" style="width:100%" tabindex="-1" aria-hidden="true"><option value="-1">All</option>
                                        <option value="0">Sunday</option>
                                        <option value="1">Monday</option>
                                        <option value="2">Tuesday</option>
                                        <option value="3">Wednesday</option>
                                        <option value="4">Thursday</option>
                                        <option value="5">Friday</option>
                                        <option value="6">Saturday</option>
                                    </select>
                                </div>
                                <label class="label-control" for="DayOfWeekID"></label>
                            </div>


                            <button type="button" id="btnReportSearchFilter" class="btn btn-primary2 btn-sm btn-square mt-sm">View</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="panel-body b-b-n report-body">
            <div id="ReportDetailDiv">
                <div class="row">
                    <div class="col-lg-3 col-md-3">
                        <div class="card card-count h-73px">
                            <div class="p-lg">
                                <div class="row">
                                    <div class="col-md-6 pr0">
                                        <h3 class="fw-600 text-center mt0 mb0 text-info fs-22">{{totalDistance}}</h3>
                                        <p class="mb0 f-12 text-muted text-center mt-minus-sm">Kms</p>
                                    </div>
                                    <div class="col-md-6">
                                        <h6 class="f-14 fw-600 mb0 mt0 text-overflow">Total Distance</h6>
                                        <!-- <p class="mb0 text-overflow" title="169.01 / day | 169.01 / Vehicle"><small class="f-9 fw-600 text-muted0 mt-minus-sm">169.01 / day | 169.01 / Vehicle</small></p> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-3 col-md-3 pl0">
                        <div class="card card-count h-73px">
                            <div class="p-lg">
                                <div class="row">
                                    <div class="col-md-5 pr0">
                                        <h3 class="fw-600 text-center mt0 mb0 text-f-green fs-22">{{formatSecondesToTime(totalMouve)}}</h3>
                                        <p class="mb0 f-12 text-muted text-center">hh:mm:ss</p>
                                    </div>
                                    <div class="col-md-7">
                                        <h6 class="f-14 fw-600 mb0 mt0">Temps Mouvement</h6>
                                        <!-- <p class="mb0"><small title="9 / day | 9 / vehicle" class="f-9 fw-600 text-muted0">9 / day | 9 / Vehicle</small></p> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-3 col-md-3 pl0">
                        <div class="card card-count h-73px">
                            <div class="p-lg">
                                <div class="row">
                                    <div class="col-md-6 pr0">
                                        <h3 class="fw-600 text-center mt0 mb0 text-orange fs-22">{{formatSecondesToTime(totalStatic)}}</h3>
                                        <p class="mb0 f-12 text-muted text-center mt-minus-sm">hh:mm:ss</p>
                                    </div>
                                    <div class="col-md-6">
                                        <h6 class="f-14 fw-600 mb0 mt0 text-overflow">Temps Statique</h6>
                                        <!-- <p class="mb0 text-overflow" title="169.01 / day | 169.01 / Vehicle"><small class="f-9 fw-600 text-muted0 mt-minus-sm">169.01 / day | 169.01 / Vehicle</small></p> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-3 col-md-3 pl0">
                        <div class="card card-count h-73px">
                            <div class="p-lg">

                                <div class="row">
                                    <div class="col-md-6 pr0">
                                        <h3 class="fw-600 text-center mt0 mb0 text-f-pink fs-22">{{formatSecondesToTime(totalOffline)}}</h3>
                                        <p class="mb0 f-12 text-muted text-center">hh:mm:ss</p>
                                    </div>
                                    <div class="col-md-6">
                                        <h6 class="f-14 fw-600 mb0 mt0 text-overflow">Temps Inactive</h6>
                                        <!-- <p class="mb0 text-overflow" title="5:15 / day | 5:15 / Vehicle"><small class="f-9 fw-600 text-muted0">5:15 / day | 5:15 / Vehicle</small></p> -->
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-3 pl0">
                        <div class="card card-count h-73px">
                            <div class="p-lg">

                                <div class="row">
                                    <div class="col-md-6 pr0">
                                        <h3 class="fw-600 text-center mt0 mb0 text-f-pink fs-22">{{parseInt((consommation*totalDistance)/100)}} L</h3>
                                        <!-- <p class="mb0 f-12 text-muted text-center">hh:mm:ss</p> -->
                                    </div>
                                    <div class="col-md-6">
                                        <h6 class="f-14 fw-600 mb0 mt0 text-overflow">Consommation Estimée</h6>
                                        <!-- <p class="mb0 text-overflow" title="5:15 / day | 5:15 / Vehicle"><small class="f-9 fw-600 text-muted0">5:15 / day | 5:15 / Vehicle</small></p> -->
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="row">
                    <hr class="mt0">
                </div> -->
                
                <rapportActivityTable v-if="showRapportActivity==1" :historyData="historyData" @showTrajet="showModalTrajet($event)"/>
                <rapportKiloTable v-if="showRapportActivity==2" :historyData="historyData"/>
                <rapportFlotteTable v-if="showRapportActivity==4" :historyData="historyData"/>
                <!-- <rapportFuelChart v-if="showRapportActivity==3" :historyData="historyData"></rapportFuelChart> -->
                <div id="fuelRapportChart" v-if="showRapportActivity==3" class="container-fluid"></div>
                </div>
            </div>
        </div>
    </div>



</section>
</div>
</template>    
<style>
.htd_1{
    background-color: #53c25796 !important;
}
.htd_2{
    background-color: #b1c25396 !important;
}
.htd_3{
    background-color: #c2625396 !important;
}
.HideControls {
        display: none;
    }
    .card-active {
        background-color: rgba(23, 139, 241, 0.11) !important;
        border-color: rgba(23, 139, 241, 0.34) !important;
    }

    .card {
        border-color: #d7dfe3;
        border-radius: 0px;
        -webkit-box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
        box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
        margin-bottom: 20px;
        position: relative;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-direction: column;
        flex-direction: column;
        min-width: 0;
        word-wrap: break-word;
        background-color: #fff;
        background-clip: border-box;
        border: 1px solid rgba(0,0,0,.125);
    }

        .card .card-header:first-child {
            border-radius: calc(.25rem - 1px) calc(.25rem - 1px) 0 0;
        }

        .card .card-header {
            padding: 11px 20px;
            margin-bottom: 0;
            background-color: #fff;
            border-bottom: 1px solid rgba(0,0,0,.125);
        }

    .p-9-9 {
        padding-top: 10px !important;
        padding-bottom: 9px !important;
    }

    .card .card-body {
        -ms-flex: 1 1 auto;
        flex: 1 1 auto;
        padding: 1.25rem;
    }

    .card .card-header h4 {
        display: inline-block;
        text-transform: uppercase;
        font-weight: 400;
        color: #455a64;
    }

    .card .card-footer:last-child {
        border-radius: 0 0 calc(.25rem - 1px) calc(.25rem - 1px);
    }

    .fs-30 {
        font-size: 30px;
    }

    .mt-minus-sm{
        margin-top: -5px;
    }
    .h-73px {
        height: 73px;
    }
</style>