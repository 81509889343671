<script setup>
    import {onMounted,ref,computed } from 'vue';
    import Fetch from '../classes/Fetch';
    import Cookie from '../classes/Cookie';
    import DateTime from '../classes/DateTime';
    import App from '../classes/App';
    import { useRoute,useRouter } from 'vue-router';
    var route = useRoute(),router= useRouter(),host=ref(location.host),packageName=ref("com.sunutrack.track");
    if (location.host.includes("gts")) {
        document.title="Gts-Afrique Tracking système";
        packageName.value="com.gts.track";
    }else if (location.host.includes("link")) {
        document.title="Geolink Tracking système";
        packageName.value="com.link-geo.track";
    }else{
        document.title="Sunutrack Tracking système";
    }
    onMounted(() => {
        $("#submitBtn").on("click",function(ev) {
            login();
        })
        $(".pass-show").click(function () {
            $(this).toggleClass("fa-eye fa-eye-slash");
            var input = $("#Password");
            if (input.attr("type") === "password") {
                input.attr("type", "text");
            } else {
                input.attr("type", "password");
            }
        });
        // Execute a function when the user presses a key on the keyboard
        document.addEventListener("keypress", function(event) {
            if (event.key === "Enter") {
                login();
            }
        });
    });
    var expireCookie=DateTime.addDays(-2,new Date()).toUTCString();
    Cookie.setCookie('token',null,expireCookie);
    function login() {
        $(".login_container").addClass("whirl");
        var form=$("#frmLogin");
        Fetch.post("/login",convertFormToJSON(form)).then((data)=>{
            if (data.error) {
                $("#message").html("<li>"+data.message+"</li>").show();
            }else{
                var expireCookie=DateTime.addDays(1,new Date()).toUTCString()
                if(data.droit==3){
                    Cookie.setCookie('token_admin',data.token,expireCookie);
                    // router.push({
                    //     name: 'admin_users'
                    // });
                    location.replace("/admin/users") 
                    return;
                }else if(data.droit==2){
                    Cookie.setCookie('token_admin',data.token,expireCookie);
                    // router.push({
                    //     name: 'admin_users'
                    // });
                    location.replace("/admin/clients") 
                    return;
                }
                Cookie.setCookie('token',data.token,expireCookie);
               location.replace("/");
            }
            $(".login_container").removeClass("whirl");
            // console.log(data);
            }).catch((err)=>{console.log(err);})
    }
    function convertFormToJSON(form) {
        return $(form).serializeArray().reduce(function (json, { name, value }) {
        json[name] = value;
        return json;
        }, {});
    }
    var isMobile=App.isMobile();
    
</script>
<template>
<link href="/css/loginstyle.css" rel="stylesheet">
 <div class="login_container" v-if="isMobile">
        <input id="PostUrl" name="PostUrl" type="hidden" value="/Account/Login">
        <!-- START panel-->
        <div class="panel panel-default panel-flat w-90per" style="margin:0 auto !important">
            <div class="panel-heading text-center">
                <h4 class="m0 f-20" _msthash="663299" _msttexthash="137371">Se connecter</h4>
            </div>
            <div class="panel-body bt">
                <div class="row">
                    <div class="col-md-6 col-xs-6">
                        <a href="javascript:;" class="d-block">
                            <img v-if="host.includes('gts')" style="max-width: 95%;" src="/logo/gts.png" class="img-responsive whitelabel_img block-center" alt="img" _mstalt="32942">
                            <img v-else-if="host.includes('link')" style="max-width: 130%;" src="/logo/geolink.png" class="img-responsive whitelabel_img block-center" alt="img">
                            <img v-else style="max-width: 150%;" src="/logo/sunutrack.png" class="img-responsive whitelabel_img block-center" alt="img" _mstalt="32942">
                        </a>
                    </div>
                    <div class="col-md-6 col-xs-6">
                        <div class="w-80per pull-right text-right">
                            <div class="dropdown lang_dropdown">
                                <button id="lang_txt" class="btn dropdown-toggle" type="button" data-toggle="dropdown">Français <span class="caret"></span>
                                </button>
                                <ul class="dropdown-menu dropdown-menu-right" _msthidden="3">
                                        <li _msthidden="1"><a href="javascript:;" class="lang_change" data-value="0" _msthash="2561416" _msttexthash="94341" _msthidden="1">English</a></li>
                                        <li _msthidden="1"><a href="javascript:;" class="lang_change" data-value="1" _msthash="2561598" _msttexthash="95381" _msthidden="1">Français</a></li>
                                </ul>
                            </div>
                            
                            
                        </div>
                    </div>
                </div>
            </div>
            <div class="panel-body" style="padding-top:0 !important">
                <form method="post" role="form" id="frmLogin">
                    <div class="validation-summary-errors text-danger">
                        <ul id="message"></ul>
                    </div>
                    <div class="form-group form-group-icon has-feedback">
                        <input autocomplete="off" class="form-control control-material" data-val="true" data-val-required="The Username field is required." id="Email" name="login" placeholder="Enter Email/Username" required="required" type="text" value="" _mstplaceholder="6611709">
                        <span class="icon icon-envelope form-control-feedback text-muted"></span>
                    </div>
                    <span class="field-validation-valid text-danger" data-valmsg-for="Email" data-valmsg-replace="true"></span>

                    <div class="form-group form-group-icon has-feedback">
                        <input class="form-control control-material" type="password" data-val="true" data-val-required="The Password field is required." id="Password" name="password" placeholder="Password" required="required" _mstplaceholder="388934">
                        <span class="icon icon-lock form-control-feedback text-muted"></span>
                        <span class="pass-show icon-eye form-control-feedback text-muted fa-eye fa-eye-slash"></span>

                    </div>
                    <span class="field-validation-valid text-danger" data-valmsg-for="Password" data-valmsg-replace="true"></span>
                    <div class="clearfix">
                        <div class="remember_pass checkbox c-checkbox pull-left mt0">
                            <label>
                                <input type="checkbox" value="" id="RememberMe" name="remember">
                                <span class="fa fa-check"></span>Se souvenir de moi </label>
                        </div>
                        <div class="pull-right">
                            <a href="#" class="text-muted" _msthash="407368" _msttexthash="106873">Oublier?</a>
                        </div>
                    </div>
                    <button type="button" id="submitBtn" class="btn btn-block btn-primary btn-lg btn-square mt-lg">Se connecter</button>
                    <br>
                </form>
            </div>
        </div>

    </div>
 <section v-else class="login_container">
        <div class="login_page w-100per">
            <div class="row w-100per">
                <div class="col-md-8 col-sm-6 login_page">
                    <div class="row">
                        <div class="col-md-12 slide">
                            <div id="carousel-example-captions" data-ride="carousel" class="login_page_slider carousel slide">
                                
                                <div role="listbox" class="carousel-inner">

                                    <div class="item active">
                                        <img style="visibility: hidden;" src="/img/sunutrackbanner.png" class="img-responsive" alt="First slide image">
                                        <div class="content-details">
                                            <h2 style="visibility: hidden;">
                                                All-in-one tool to optimize logistics and mobility operations
                                            </h2>
                                            <p class="p-details text-center hidden-sm">
                                                Aider les entreprises à optimiser le déplacement des personnes, des biens et des services sur une plateforme adaptée.
                                            </p>
                                        </div>
                                    </div>

                                    
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-md-4 col-sm-6 login_form_shadow login_page bg-white ">
                    <input id="PostUrl" name="PostUrl" type="hidden" value="/Account/Login">
                    <!-- START panel-->
                    <div class="login_header">
                        <div class="col-md-6">
                            <a href="javascript:;" class="d-block">
                                <img v-if="host.includes('gts')" style="max-width: 95%;" src="/logo/gts.png" class="img-responsive whitelabel_img block-center" alt="img">
                                <img v-else-if="host.includes('link')" style="max-width: 130%;" src="/logo/geolink.png" class="img-responsive whitelabel_img block-center" alt="img">
                                <img v-else style="max-width: 78%;" src="/logo/sunutrack.png" class="img-responsive whitelabel_img block-center" alt="img">
                            </a>
                        </div>
                        <div class="col-md-6">
                            <div class="w-80per pull-right text-right">
                                <div class="dropdown lang_dropdown">
                                    <button id="lang_txt" class="btn dropdown-toggle" type="button" data-toggle="dropdown">
                                        Français
                                        <span class="caret"></span>
                                    </button>
                                    <ul class="dropdown-menu dropdown-menu-right">
                                            <li><a href="javascript:;" class="lang_change" data-value="0">English</a></li>
                                            <li><a href="javascript:;" class="lang_change" data-value="1">Français</a></li>
                                    </ul>
                                </div>
                                
                                
                            </div>
                        </div>
                    </div>
                    <div class="w-100per bg-white" style="margin-top: 55px">
                        
                        <div class="panel-body">
                            <div class="panel-title">
                                <h4 class="m0" style="text-align: center;">Se connecter</h4>
                            </div>

                            <form method="post" class="pt-lg" role="form" id="frmLogin">
                                <div class="validation-summary-errors text-danger">
                                    <ul id="message"></ul>
                                </div>
                                <div class="form-group form-group-icon has-feedback">
                                    <input autocomplete="off" class="form-control control-material" data-val="true" data-val-required="The Username field is required." id="Email" name="login" placeholder="Enter Email/Username" required="required" type="text" value="">
                                    <span class="icon icon-envelope form-control-feedback text-muted"></span>
                                </div>
                                <span class="field-validation-valid text-danger" data-valmsg-for="Email" data-valmsg-replace="true"></span>

                                <div class="form-group form-group-icon has-feedback">
                                    <input class="form-control control-material" data-val="true" data-val-required="The Password field is required." id="Password" name="password" placeholder="Enter password" required="required" type="password">
                                    <span class="icon icon-lock form-control-feedback text-muted"></span>
                                    <span class="pass-show icon-eye form-control-feedback text-muted"></span>

                                </div>
                                <span class="field-validation-valid text-danger" data-valmsg-for="Password" data-valmsg-replace="true"></span>
                                <div class="clearfix">
                                    <div class="remember_pass checkbox c-checkbox pull-left mt0">
                                        <label>
                                            <input type="checkbox" value="" id="RememberMe" name="remember">
                                            <span class="fa fa-check"></span>Se souvenir de moi
                                        </label>
                                    </div>
                                    <div class="pull-right">
                                        <a href="#" class="text-muted">Oublier?</a>
                                    </div>
                                </div>
                                <button type="button" id="submitBtn" class="btn btn-block btn-primary btn-lg btn-square mt-lg">Se connecter</button>
                                <br>
                            </form>
                        </div>
                    </div>
                    <div class="mt-lg">
                        
                        <div class="row d-none">

                            <div class="col-md-6">
                                <a href="https://apps.apple.com/us/app/gts/id4343322311?ls=1" target="_blank" class="list-group-item radious-0">
                                    <div class="media-box">
                                        <div class="pull-left">
                                            <img src="/img/app_store.png" class="media-box-object thumb32 app_store" alt="app_store" height="30">
                                        </div>
                                        <div class="media-box-body clearfix mt-sm">
                                            <div class="media-box-heading text-center">
                                               App Store
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            </div>
                            <div class="col-md-6">
                                <a :href="'https://play.google.com/store/apps/details?id='+packageName" target="_blank" class="list-group-item radious-0">
                                    <div class="media-box">
                                        <div class="pull-left">
                                            <img src="/img/play_store.png" class="media-box-object thumb32 play_store" alt="play_store" height="30">
                                        </div>
                                        <div class="media-box-body clearfix mt-sm">
                                            <div class="media-box-heading text-center">
                                              Play Store
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            </div>

                            <div class="col-md-12 mt-lg text-center">
                                <small>Download Mobile App : Tracking On The Go</small>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </section>
</template>
<style>
    .mlogin_container {
        min-height: 100vh;
        background-color: #f5f5f5;
        padding-top: 90px;
    }

        .mlogin_container .panel .panel-body {
            padding: 15px !important;
        }
    </style>